import { $, _ } from "js/vendor";

let splashScreenTimeout;

export function RemoveSplashScreen() {
    clearTimeout(splashScreenTimeout);

    $("#splash").addClass("hide-splash");
}

export function RestoreSplashScreen() {
    clearTimeout(splashScreenTimeout);

    splashScreenTimeout = setTimeout(() => {
        $("#splash").removeClass("hide-splash");
    }, 100);
}

