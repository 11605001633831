import { Icon } from "@material-ui/core";
import classNames from "classnames";
import { browserHistory } from "js/react/history";
import moment from "moment";
import React, { Component } from "react";
import styled from "styled-components";

import { DialogTitle, Link, MenuItem, Select } from "@material-ui/core";

import { CustomerType } from "common/constants";
import sanitizeRelativeUrl from "common/utils/sanitizeRelativeUrl";
import { isPPTAddin } from "js/config";
import Api from "js/core/api";
import { trackActivity } from "js/core/utilities/utilities";
import { app } from "js/namespaces";
import { BeautifulDialog, DialogContent, ShowMessageDialog } from "js/react/components/Dialogs/BaseDialog";
import { Gap10, Gap5 } from "js/react/components/Gap";
import Spinner from "js/react/components/Spinner";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";
import BillingDataService from "js/react/views/UserOptions/dataservices/billingDataService";
import { presentations as presentationsApi } from "apis/callables";

import PaymentForm from "./PaymentForm";
import SuccessfulPurchase from "./SuccessfulPurchase";

import "css/billing.scss";

const TermsofUseLink = styled(Link)`
  &&& {
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 400; 
    line-height: 150%;
    text-decoration-line: underline;
    color: #11A9E2
  }`;

export const TermsOfUseAndRefundPolicy = () => (
    <span>
        <TermsofUseLink target="_blank" href="https://www.beautiful.ai/terms-of-service">Terms of Service</TermsofUseLink> and <TermsofUseLink target="_blank" href="https://support.beautiful.ai/hc/en-us/articles/360030719012-What-is-your-refund-policy">Refund Policy</TermsofUseLink>.
    </span>
);

export class CheckoutDialog extends Component {
    constructor(props) {
        super();
        this.state = {
            billingInterval: props.defaultBillingInterval ?? "year",
            successfulPurchase: false,
            isSubmitting: false,
            availableReferralCredit: 0,
            productDisplayName: "",
            alreadySubscribed: false,
            taxAmount: null
        };
    }

    async componentDidMount() {
        const [trialDetailResponse, subscriptionResponse] = await Promise.all([
            Api.trialDetail.get({ t: new Date().getTime() }),
            Api.subscriptions.get({ customer_type: CustomerType.INDIVIDUAL })
        ]);

        const { currentPlan, hasTakenTrial, analyticsCurrentPlan } = trialDetailResponse;

        // If user has an individual subscription and their plan is not cancelled show dialog to send them back to library.
        if (subscriptionResponse.length > 0 && subscriptionResponse[0].id && !subscriptionResponse[0].cancel_at_period_end) {
            this.setState({ alreadySubscribed: true });
            ShowMessageDialog({
                title: "Are you sure about this?",
                message: "You are already paying for a Pro plan. Let’s take you to your presentation library.",
                onClick: () => {
                    window.location = "/";
                },
                onClose: () => {
                    window.location = "/";
                }
            });
        }

        this.setState({ currentPlan, hasTakenTrial });

        app.user.analyticsPersonalPlan = analyticsCurrentPlan;

        const data = await Api.referrals.get();
        this.setState({ availableReferralCredit: data.totalAvailable });

        const defaultPriceIds = BillingDataService.getDefaultPriceIds("pro");

        // fetch pricing from Stripe
        let prices;
        if (this.props.priceId && this.props.priceId.includes(" ")) {
            const priceIds = this.props.priceId.split(" ");

            const priceData = [
                await Api.prices.get({ id: priceIds[0] }),
                await Api.prices.get({ id: priceIds[1] })
            ];

            const month = priceData.find(p => p.recurring.interval === "month");
            const year = priceData.find(p => p.recurring.interval === "year");

            if (!month || !year) {
                throw new Error("expected monthly and yearly prices'");
            }

            prices = {
                month,
                year,
            };
        } else if (this.props.priceId) {
            let year, month;

            const price = await Api.prices.get({ id: this.props.priceId });
            if (price?.recurring?.interval === "month") {
                month = price;
                year = await Api.prices.get({ id: defaultPriceIds.year });
            } else if (price?.recurring?.interval === "year") {
                year = price;
                month = await Api.prices.get({ id: defaultPriceIds.month });
            } else {
                month = await Api.prices.get({ id: defaultPriceIds.month });
                year = await Api.prices.get({ id: defaultPriceIds.year });
            }

            prices = {
                year,
                month
            };
        } else {
            prices = {
                month: await Api.prices.get({ id: defaultPriceIds.month }),
                year: await Api.prices.get({ id: defaultPriceIds.year })
            };
        }

        this.setState({
            prices,
            productDisplayName: this.props.productDisplayName ?? prices.year.nickname.substring(0, prices.year.nickname.indexOf("-"))
        });

        const params = new URLSearchParams(window.location.search);
        const testId = params.get("ex");

        const props = {
            current_status: currentPlan,
            workspace_id: "personal",
            price_id: this.props.priceId,
            plan_display_name: this.state.productDisplayName,
            test_id: testId,
            user_id: this.props.firebaseUser.uid
        };
        trackActivity("Upgrade", "BillingIntentStart", testId, null, props, { audit: true });
    }

    get showTrial() {
        const { hasTakenTrial } = this.state;
        return !hasTakenTrial;
    }

    get amount() {
        const { billingInterval, promo, prices } = this.state;
        const dollars = prices[billingInterval].unit_amount / 100;
        return BillingDataService.applyCoupon(dollars, promo);
    }

    get trialExpirationDate() {
        return moment()
            .add(14, "days")
            .format("MMMM Do, YYYY");
    }

    refreshApp = () => {
        this.setState({ alreadySubscribed: true });
        const continueUrl = sanitizeRelativeUrl(new URLSearchParams(window.location.search).get("continue") ?? "/");

        // If the continue URL is the same as the current URL, we need to force a reload in order to update the user's subscription status.
        if (continueUrl !== window.location.pathname) {
            window.location.href = continueUrl;
        } else {
            window.location.reload();
        }
    };

    handleOnSuccess = async () => {
        const { billingInterval, currentPlan } = this.state;

        app.user.update({
            didConfirmPlan: true
        });
        await app.user.updatePromise;

        if (trackActivity) {
            const subscriptions = await Api.subscriptions.get({
                customer_type: CustomerType.INDIVIDUAL
            });
            // Expensive, but accurate
            const { slideCount } = await presentationsApi.getSlideCount({ workspaceId: "personal", filter: "all" });
            const params = new URLSearchParams(window.location.search);
            const testId = params.get("ex");
            const eventProps = {
                billing_term: billingInterval,
                current_status: currentPlan,
                amount: this.amount,
                plan: "pro",
                current_slide_count: slideCount,
                subscription_id: subscriptions[0] ? subscriptions[0].id : null,
                workspace_id: "personal",
                promo_code: this.state.promo?.code,
                price_id: this.props.priceId,
                plan_display_name: this.state.productDisplayName,
                test_id: testId,
                user_id: this.props.firebaseUser.uid
            };
            trackActivity("Upgrade", "BillingIntentComplete", testId, null, eventProps, { audit: true });
            await Api.klaviyoTrack.post({
                eventName: "Upgrade:BillingIntentComplete",
                eventProps,
            });

            trackActivity("SignupFlow", "NavForward", null, null, {
                step_number: 8,
                step_name: "Checkout",
                plan_selected: "pro",
                object: "button",
                object_label: "Start free trial",
                action: "clicked",
                workspace_id: "all"
            }, { audit: true });
        }

        this.setState({ successfulPurchase: true, isSubmitting: false });
    };

    handleOnBeforeCharge = () => {
        this.setState({ isSubmitting: true });
    };

    handleOnFailure = () => {
        this.setState({ isSubmitting: false });
    };

    handlePromoChanged = promo => {
        this.setState({ promo });
    };

    handleTaxCalculated = taxAmount => {
        this.setState({ taxAmount });
    };

    getSuccessfulPurchaseDialog(productDisplayName) {
        const { closeDialog } = this.props;
        return (
            <SuccessfulPurchase
                productDisplayName={productDisplayName ?? "Pro"}
                showTrial={this.showTrial}
                trialExpirationDate={this.trialExpirationDate}
                additionalMessage={isPPTAddin ? "The changes will be applied next time you create or edit a slide." : null}
                onCallToActionClick={() => {
                    if (isPPTAddin) {
                        closeDialog(true);
                    } else {
                        this.refreshApp();
                    }
                }}
                callToActionText={isPPTAddin ? "Done" : "Continue"}
            />
        );
    }

    render() {
        const { alreadySubscribed, successfulPurchase, billingInterval, isSubmitting, prices, productDisplayName, taxAmount, availableReferralCredit } = this.state;

        if (!prices || alreadySubscribed) {
            return null;
        }

        const fullPrice = this.amount;
        const price = Math.max(fullPrice - (availableReferralCredit / 100), 0);

        let nextBillingDate;
        if (billingInterval == "year") {
            nextBillingDate = moment()
                .add(1, "year")
                .format("MMMM Do YYYY");
        } else {
            nextBillingDate = moment()
                .add(1, "month")
                .format("MMMM Do YYYY");
        }

        const priceId = prices[billingInterval]?.id;

        const taxString = taxAmount ? " plus tax" : "";

        if (successfulPurchase) {
            return this.getSuccessfulPurchaseDialog(productDisplayName);
        } else {
            return (
                <BeautifulDialog
                    preventClose={isSubmitting}
                    closeDialog={this.props.closeDialog}
                    hideBackdrop={this.props.hideBackdrop}
                    style={this.props.style}
                    PaperProps={{ style: { maxHeight: "100%", margin: 0 } }}
                >
                    <DialogTitle>
                        Upgrade to Beautiful.ai <span color="#11a9e2">{productDisplayName}{this.showTrial ? " Trial" : ""}</span>
                    </DialogTitle>
                    <DialogContent>
                        <div
                            id="checkout_form_container"
                            className={classNames({
                                disabled: isSubmitting
                            })}
                        >
                            {isSubmitting && <Spinner />}

                            {this.showTrial ? (
                                <h3>
                                    Start your 14-day free trial. You can cancel anytime during your trial and you won't
                                    be charged anything.
                                </h3>
                            ) : (
                                <h3>
                                    We're sorry but you are not eligible for a 14-day free trial because you have
                                    already had a trial in the past.
                                </h3>
                            )}

                            <Gap10 />
                            {prices.month && (
                                <>
                                    <Gap5 />
                                    <div className="label">Choose Plan</div>

                                    <Select
                                        SelectDisplayProps={{ style: { textTransform: "capitalize" } }}
                                        style={{ width: "100%" }}
                                        variant="outlined"
                                        value={billingInterval}
                                        inputProps={{ className: "select-input" }}
                                        onChange={event => {
                                            this.setState({
                                                billingInterval: event.target.value
                                            });
                                        }}
                                    >
                                        <MenuItem className="menu-item-with-badge" value="year">
                                            Annual Plan - ${prices.year.unit_amount / 12 / 100}/month
                                            <div className="menu-item-badge">Best Value</div>
                                        </MenuItem>
                                        <MenuItem value="month">Monthly Plan - ${prices.month.unit_amount / 100}/month</MenuItem>
                                    </Select>
                                </>
                            )}

                            {!prices.month && (
                                <b>Annual Plan - ${prices.year.unit_amount / 12 / 100}/month</b>
                            )}

                            <Gap10 />

                            {priceId && <PaymentForm
                                priceId={priceId}
                                customerType="individual"
                                onSuccess={this.handleOnSuccess}
                                onBeforeSubmit={this.handleOnBeforeCharge}
                                onFailure={this.handleOnFailure}
                                onCancel={app.isConstrained && this.props.closeDialog}
                                submitLabel={this.showTrial ? "Start Free Trial" : "Upgrade Now"}
                                submitButtonStyle={{ marginTop: 30 }}
                                hasTakenTrial={!this.showTrial}
                                onPromoChanged={this.handlePromoChanged}
                                onTaxCalculated={this.handleTaxCalculated}
                                showTitle={false}
                            />}

                            <Gap10 />

                            {this.showTrial ? (
                                <h3 style={{ fontSize: 14 }}>
                                    Once your trial ends on <strong>{this.trialExpirationDate}</strong>, you will be charged <strong>${price.toFixed(2)}{taxString}</strong>.
                                    Your Subscription will <strong>automatically renew {billingInterval == "year" ? "annually" : "monthly"}</strong>. Update your billing information or cancel your subscription
                                    at any time from your account management.&nbsp;
                                    {<TermsOfUseAndRefundPolicy />}
                                </h3>
                            ) : (
                                <h3 style={{ fontSize: 14 }}>
                                    You will be charged <strong>${price.toFixed(2)}{taxString}</strong> today. Your next bill will be due on{" "}
                                    <strong>{nextBillingDate}</strong>.&nbsp;
                                    <TermsOfUseAndRefundPolicy />
                                </h3>
                            )}

                            {availableReferralCredit > 0 && (
                                <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                                    <Icon style={{ color: "#fa0", fontSize: "1.25em", verticalAlign: "sub" }}>monetization_on</Icon>{" "}
                                    You saved <b>${Math.min(availableReferralCredit / 100, fullPrice)}</b> from referral credit.
                                </div>
                            )}
                        </div>
                    </DialogContent>
                </BeautifulDialog>
            );
        }
    }
}

export default withFirebaseUser(CheckoutDialog);
