export const PreviewSlide1 = {
    "template_id": "title",
    "layout": {
        "headerPosition": "top",
        "showFooter": "theme",
        "showElementTitle": false,
        "showAttribution": false
    },
    "states": [
        {
            "header": {
                "title": "",
                "body": ""
            },
            "primary": {
                "body": "",
                "showFooter": false,
                "showLabel": false,
                "image": {
                    "position": "top",
                    "content_value": "2c622617f1f74a3f5701eafcdab8fab2394d50fe92c80aea796b7181bf47579b-I",
                    "content_type": "image",
                    "aoiLeft": 0,
                    "aoiRight": 5760,
                    "aoiTop": 327,
                    "aoiBottom": 3513,
                    "scale": 0.2222222222222222,
                    "version": 1,
                    "traySize": 360,
                    "assetWidth": 5760,
                    "assetHeight": 3840
                },
                "backdropPadding": 40,
                "text": {
                    "blocks": [
                        {
                            "id": "eec23793-54a4-4e32-8044-d68d910e989a",
                            "html": "Sample Label",
                            "type": "text",
                            "textStyle": "label",
                            "hyphenation": false,
                            "ligatures": true,
                            "fontScale": 1,
                            "indent": 0,
                            "blockBounds": {
                                "left": 0,
                                "top": -5.140625,
                                "width": 770,
                                "height": 23.1875
                            },
                            "textStyles": {
                                "fontFamily": "montserrat",
                                "baseFontSize": 18.400000000000002,
                                "fontColor": "secondary",
                                "bulletScale": 1,
                                "bulletSpacing": 2.5,
                                "bulletIndent": 50,
                                "fontScaling": 80,
                                "fontWeight": 500,
                                "letterSpacing": "0em",
                                "lineHeight": 1.26,
                                "textTransform": "uppercase",
                                "spaceBelow": 0,
                                "spaceAbove": 0,
                                "blockSpacing": 40,
                                "maxLines": 9999,
                                "evenBreak": false,
                                "listDecorationStyle": "theme",
                                "allowFancyNumberedDecorations": false,
                                "hasEmphasized": false,
                                "blockInset": 0,
                                "isRTL": false,
                                "fontScale": 1,
                                "fontSize": 18.400000000000002,
                                "textAlign": "center",
                                "autoColor": "rgb(90, 90, 76)",
                                "color": "rgb(90, 90, 76)",
                                "emphasizedColor": "rgb(90, 90, 76)",
                                "themeColors": {
                                    "primary_light": "rgb(255, 255, 255)",
                                    "primary_dark": "rgb(42, 41, 33)",
                                    "secondary_light": "rgba(255, 255, 255, 0.9)",
                                    "secondary_dark": "rgb(90, 90, 76)",
                                    "theme": "rgb(98, 168, 187)",
                                    "accent1": "rgb(240, 179, 86)",
                                    "accent2": "rgb(230, 105, 34)",
                                    "accent3": "rgb(20, 85, 140)",
                                    "accent4": "rgb(98, 168, 187)",
                                    "accent5": "rgb(98, 168, 187)",
                                    "accent6": "rgb(98, 168, 187)",
                                    "accent7": "rgb(98, 168, 187)",
                                    "accent8": "rgb(98, 168, 187)",
                                    "background_dark": "rgb(0, 0, 0)",
                                    "background_light": "rgb(255, 255, 255)",
                                    "background_accent": "rgb(245, 245, 245)",
                                    "chart1": "rgb(20, 85, 140)",
                                    "chart2": "rgb(98, 168, 187)",
                                    "chart3": "rgb(152, 166, 77)",
                                    "chart4": "rgb(240, 179, 86)",
                                    "chart5": "rgb(230, 105, 34)",
                                    "chart6": "rgb(170, 89, 134)",
                                    "hyperlink": "rgb(17, 169, 226)"
                                }
                            },
                            "blockMargin": {
                                "top": -5.152000000000002,
                                "bottom": 0,
                                "left": 0,
                                "right": 0
                            },
                            "topSpace": 5.152000000000002,
                            "bottomSpace": 5.152000000000001
                        },
                        {
                            "id": "12085467-3695-4dde-824c-5f49ca9fd14f",
                            "html": "A Sample <font class=\"emphasized\">Title Slide</font>",
                            "type": "text",
                            "textStyle": "heading",
                            "hyphenation": false,
                            "ligatures": true,
                            "fontScale": 1,
                            "indent": 0,
                            "blockBounds": {
                                "left": 0,
                                "top": 31.46875,
                                "width": 770,
                                "height": 96.375
                            },
                            "textStyles": {
                                "fontFamily": "montserrat",
                                "baseFontSize": 76.5,
                                "fontColor": "primary",
                                "bulletScale": 1,
                                "bulletSpacing": 2.5,
                                "bulletIndent": 50,
                                "fontScaling": 85,
                                "fontWeight": 400,
                                "letterSpacing": "0em",
                                "lineHeight": 1.26,
                                "textTransform": "none",
                                "spaceBelow": 0,
                                "spaceAbove": 0,
                                "blockSpacing": 40,
                                "boldStyles": {
                                    "fontColor": "slide"
                                },
                                "maxLines": 9999,
                                "evenBreak": true,
                                "listDecorationStyle": "theme",
                                "allowFancyNumberedDecorations": false,
                                "hasEmphasized": true,
                                "blockInset": 0,
                                "isRTL": false,
                                "fontScale": 1,
                                "fontSize": 76.5,
                                "textAlign": "center",
                                "autoColor": "rgb(42, 41, 33)",
                                "color": "rgb(42, 41, 33)",
                                "emphasizedColor": "rgb(98, 168, 187)",
                                "themeColors": {
                                    "primary_light": "rgb(255, 255, 255)",
                                    "primary_dark": "rgb(42, 41, 33)",
                                    "secondary_light": "rgba(255, 255, 255, 0.9)",
                                    "secondary_dark": "rgb(90, 90, 76)",
                                    "theme": "rgb(98, 168, 187)",
                                    "accent1": "rgb(240, 179, 86)",
                                    "accent2": "rgb(230, 105, 34)",
                                    "accent3": "rgb(20, 85, 140)",
                                    "accent4": "rgb(98, 168, 187)",
                                    "accent5": "rgb(98, 168, 187)",
                                    "accent6": "rgb(98, 168, 187)",
                                    "accent7": "rgb(98, 168, 187)",
                                    "accent8": "rgb(98, 168, 187)",
                                    "background_dark": "rgb(0, 0, 0)",
                                    "background_light": "rgb(255, 255, 255)",
                                    "background_accent": "rgb(245, 245, 245)",
                                    "chart1": "rgb(20, 85, 140)",
                                    "chart2": "rgb(98, 168, 187)",
                                    "chart3": "rgb(152, 166, 77)",
                                    "chart4": "rgb(240, 179, 86)",
                                    "chart5": "rgb(230, 105, 34)",
                                    "chart6": "rgb(170, 89, 134)",
                                    "hyperlink": "rgb(17, 169, 226)"
                                }
                            },
                            "blockMargin": {
                                "top": 13.427999999999997,
                                "bottom": 0,
                                "left": 0,
                                "right": 0
                            },
                            "topSpace": 21.42,
                            "bottomSpace": 21.42
                        },
                        {
                            "id": "dbe1d6c9-8a4d-4e79-abd1-b6e0b967a2de",
                            "html": "Sample body copy. This is a description.",
                            "type": "text",
                            "textStyle": "body",
                            "hyphenation": false,
                            "ligatures": true,
                            "fontScale": 1,
                            "indent": 0,
                            "blockBounds": {
                                "left": 0,
                                "top": 138.859375,
                                "width": 770,
                                "height": 30.21875
                            },
                            "textStyles": {
                                "fontFamily": "montserrat",
                                "baseFontSize": 21.6,
                                "fontColor": "secondary",
                                "bulletScale": 1,
                                "bulletSpacing": 2.5,
                                "bulletIndent": 50,
                                "fontScaling": 80,
                                "fontWeight": 400,
                                "letterSpacing": "0em",
                                "lineHeight": 1.4,
                                "textTransform": "none",
                                "spaceBelow": 0,
                                "spaceAbove": 0,
                                "blockSpacing": 40,
                                "boldStyles": {
                                    "fontColor": "primary"
                                },
                                "maxLines": 9999,
                                "evenBreak": false,
                                "listDecorationStyle": "theme",
                                "allowFancyNumberedDecorations": false,
                                "hasEmphasized": false,
                                "blockInset": 0,
                                "isRTL": false,
                                "fontScale": 1,
                                "fontSize": 21.6,
                                "textAlign": "center",
                                "autoColor": "rgb(90, 90, 76)",
                                "color": "rgb(90, 90, 76)",
                                "emphasizedColor": "rgb(42, 41, 33)",
                                "themeColors": {
                                    "primary_light": "rgb(255, 255, 255)",
                                    "primary_dark": "rgb(42, 41, 33)",
                                    "secondary_light": "rgba(255, 255, 255, 0.9)",
                                    "secondary_dark": "rgb(90, 90, 76)",
                                    "theme": "rgb(98, 168, 187)",
                                    "accent1": "rgb(240, 179, 86)",
                                    "accent2": "rgb(230, 105, 34)",
                                    "accent3": "rgb(20, 85, 140)",
                                    "accent4": "rgb(98, 168, 187)",
                                    "accent5": "rgb(98, 168, 187)",
                                    "accent6": "rgb(98, 168, 187)",
                                    "accent7": "rgb(98, 168, 187)",
                                    "accent8": "rgb(98, 168, 187)",
                                    "background_dark": "rgb(0, 0, 0)",
                                    "background_light": "rgb(255, 255, 255)",
                                    "background_accent": "rgb(245, 245, 245)",
                                    "chart1": "rgb(20, 85, 140)",
                                    "chart2": "rgb(98, 168, 187)",
                                    "chart3": "rgb(152, 166, 77)",
                                    "chart4": "rgb(240, 179, 86)",
                                    "chart5": "rgb(230, 105, 34)",
                                    "chart6": "rgb(170, 89, 134)",
                                    "hyperlink": "rgb(17, 169, 226)"
                                }
                            },
                            "blockMargin": {
                                "top": 11.019999999999996,
                                "bottom": -7.559999999999999,
                                "left": 0,
                                "right": 0
                            },
                            "topSpace": 7.560000000000001,
                            "bottomSpace": 7.559999999999999
                        }
                    ]
                }
            }
        }
    ],
    "version": 10,
    "migrationVersion": 10.01
};

export const PreviewSlide2 = {
    "template_id": "textgrid_vertical_icons",
    "layout": {
        "headerPosition": "top",
        "showFooter": "theme",
        "showElementTitle": false,
        "showAttribution": false,
        "trayLayout": "none",
        "slideColor": "colorful"
    },
    "states": [
        {
            "header": {
                "text": {
                    "blocks": [
                        {
                            "id": "b4469655-f9b2-4c63-a440-db615eda2f48",
                            "html": "Sample Label",
                            "type": "text",
                            "textStyle": "label",
                            "hyphenation": false,
                            "ligatures": true,
                            "fontScale": 1,
                            "indent": 0,
                            "blockBounds": {
                                "left": 0,
                                "top": -5.9375,
                                "width": 1180,
                                "height": 26.765625
                            },
                            "textStyles": {
                                "fontFamily": "montserrat",
                                "baseFontSize": 21.25,
                                "fontColor": "slide",
                                "bulletScale": 1,
                                "bulletSpacing": 2.5,
                                "bulletIndent": 50,
                                "fontScaling": 85,
                                "fontWeight": 400,
                                "letterSpacing": "0em",
                                "lineHeight": 1.26,
                                "textTransform": "none",
                                "spaceBelow": 0,
                                "spaceAbove": 0,
                                "blockSpacing": 30,
                                "maxLines": 9999,
                                "evenBreak": false,
                                "listDecorationStyle": "theme",
                                "allowFancyNumberedDecorations": false,
                                "hasEmphasized": false,
                                "blockInset": 0,
                                "isRTL": false,
                                "fontScale": 1,
                                "fontSize": 21.25,
                                "textAlign": "center",
                                "autoColor": "rgb(98, 168, 187)",
                                "color": "rgb(98, 168, 187)",
                                "emphasizedColor": "rgb(98, 168, 187)",
                                "themeColors": {
                                    "primary_light": "rgb(255, 255, 255)",
                                    "primary_dark": "rgb(42, 41, 33)",
                                    "secondary_light": "rgba(255, 255, 255, 0.9)",
                                    "secondary_dark": "rgb(90, 90, 76)",
                                    "theme": "rgb(98, 168, 187)",
                                    "accent1": "rgb(240, 179, 86)",
                                    "accent2": "rgb(230, 105, 34)",
                                    "accent3": "rgb(20, 85, 140)",
                                    "accent4": "rgb(98, 168, 187)",
                                    "accent5": "rgb(98, 168, 187)",
                                    "accent6": "rgb(98, 168, 187)",
                                    "accent7": "rgb(98, 168, 187)",
                                    "accent8": "rgb(98, 168, 187)",
                                    "background_dark": "rgb(0, 0, 0)",
                                    "background_light": "rgb(255, 255, 255)",
                                    "background_accent": "rgb(245, 245, 245)",
                                    "chart1": "rgb(20, 85, 140)",
                                    "chart2": "rgb(98, 168, 187)",
                                    "chart3": "rgb(152, 166, 77)",
                                    "chart4": "rgb(240, 179, 86)",
                                    "chart5": "rgb(230, 105, 34)",
                                    "chart6": "rgb(170, 89, 134)",
                                    "hyperlink": "rgb(17, 169, 226)"
                                }
                            },
                            "blockMargin": {
                                "top": -5.95,
                                "bottom": 0,
                                "left": 0,
                                "right": 0
                            },
                            "topSpace": 5.95,
                            "bottomSpace": 5.949999999999999
                        },
                        {
                            "id": "c7f299b1-f904-44d1-843a-058bfc953a99",
                            "html": "Sample Header",
                            "type": "text",
                            "textStyle": "heading",
                            "hyphenation": false,
                            "ligatures": true,
                            "fontScale": 1,
                            "indent": 0,
                            "blockBounds": {
                                "left": 0,
                                "top": 32.96875,
                                "width": 1180,
                                "height": 53.546875
                            },
                            "textStyles": {
                                "fontFamily": "montserrat",
                                "baseFontSize": 42.5,
                                "fontColor": "primary",
                                "bulletScale": 1,
                                "bulletSpacing": 2.5,
                                "bulletIndent": 50,
                                "fontScaling": 85,
                                "fontWeight": 400,
                                "letterSpacing": "0em",
                                "lineHeight": 1.26,
                                "textTransform": "none",
                                "spaceBelow": 0,
                                "spaceAbove": 0,
                                "blockSpacing": 30,
                                "boldStyles": {
                                    "fontColor": "slide"
                                },
                                "maxLines": 9999,
                                "evenBreak": true,
                                "listDecorationStyle": "theme",
                                "allowFancyNumberedDecorations": false,
                                "hasEmphasized": false,
                                "blockInset": 0,
                                "isRTL": false,
                                "fontScale": 1,
                                "fontSize": 42.5,
                                "textAlign": "center",
                                "autoColor": "rgb(42, 41, 33)",
                                "color": "rgb(42, 41, 33)",
                                "emphasizedColor": "rgb(240, 179, 86)",
                                "themeColors": {
                                    "primary_light": "rgb(255, 255, 255)",
                                    "primary_dark": "rgb(42, 41, 33)",
                                    "secondary_light": "rgba(255, 255, 255, 0.9)",
                                    "secondary_dark": "rgb(90, 90, 76)",
                                    "theme": "rgb(98, 168, 187)",
                                    "accent1": "rgb(240, 179, 86)",
                                    "accent2": "rgb(230, 105, 34)",
                                    "accent3": "rgb(20, 85, 140)",
                                    "accent4": "rgb(98, 168, 187)",
                                    "accent5": "rgb(98, 168, 187)",
                                    "accent6": "rgb(98, 168, 187)",
                                    "accent7": "rgb(98, 168, 187)",
                                    "accent8": "rgb(98, 168, 187)",
                                    "background_dark": "rgb(0, 0, 0)",
                                    "background_light": "rgb(255, 255, 255)",
                                    "background_accent": "rgb(245, 245, 245)",
                                    "chart1": "rgb(20, 85, 140)",
                                    "chart2": "rgb(98, 168, 187)",
                                    "chart3": "rgb(152, 166, 77)",
                                    "chart4": "rgb(240, 179, 86)",
                                    "chart5": "rgb(230, 105, 34)",
                                    "chart6": "rgb(170, 89, 134)",
                                    "hyperlink": "rgb(17, 169, 226)"
                                }
                            },
                            "blockMargin": {
                                "top": 12.150000000000002,
                                "bottom": -11.899999999999999,
                                "left": 0,
                                "right": 0
                            },
                            "topSpace": 11.9,
                            "bottomSpace": 11.899999999999999
                        }
                    ]
                }
            },
            "primary": {
                "forceSingleColumn": false,
                "items": [
                    {
                        "content_type": "icon",
                        "content_value": "rocket",
                        "id": "225161587586374770",
                        "frameType": "theme",
                        "titleTextStyle": "title",
                        "text": {
                            "blocks": [
                                {
                                    "id": "b8b6941f-9e53-43a6-83e1-435150fee393",
                                    "html": "Sample<font class=\"emphasized\"> Title</font>",
                                    "type": "text",
                                    "textStyle": "title",
                                    "hyphenation": false,
                                    "ligatures": true,
                                    "fontScale": 1,
                                    "indent": 0,
                                    "blockBounds": {
                                        "left": 0,
                                        "top": -5.375,
                                        "width": 337,
                                        "height": 24.1875
                                    },
                                    "textStyles": {
                                        "fontFamily": "montserrat",
                                        "baseFontSize": 19.200000000000003,
                                        "fontColor": "primary",
                                        "bulletScale": 1,
                                        "bulletSpacing": 2.5,
                                        "bulletIndent": 50,
                                        "fontScaling": 80,
                                        "fontWeight": 500,
                                        "letterSpacing": "0em",
                                        "lineHeight": 1.26,
                                        "textTransform": "none",
                                        "spaceBelow": 0,
                                        "spaceAbove": 0,
                                        "blockSpacing": 20,
                                        "boldStyles": {
                                            "fontColor": "slide"
                                        },
                                        "maxLines": 9999,
                                        "evenBreak": false,
                                        "listDecorationStyle": "theme",
                                        "allowFancyNumberedDecorations": false,
                                        "hasEmphasized": true,
                                        "blockInset": 0,
                                        "isRTL": false,
                                        "fontScale": 1,
                                        "fontSize": 19.200000000000003,
                                        "textAlign": "center",
                                        "autoColor": "rgb(42, 41, 33)",
                                        "color": "rgb(42, 41, 33)",
                                        "emphasizedColor": "rgb(98, 168, 187)",
                                        "themeColors": {
                                            "primary_light": "rgb(255, 255, 255)",
                                            "primary_dark": "rgb(42, 41, 33)",
                                            "secondary_light": "rgba(255, 255, 255, 0.9)",
                                            "secondary_dark": "rgb(90, 90, 76)",
                                            "theme": "rgb(98, 168, 187)",
                                            "accent1": "rgb(240, 179, 86)",
                                            "accent2": "rgb(230, 105, 34)",
                                            "accent3": "rgb(20, 85, 140)",
                                            "accent4": "rgb(98, 168, 187)",
                                            "accent5": "rgb(98, 168, 187)",
                                            "accent6": "rgb(98, 168, 187)",
                                            "accent7": "rgb(98, 168, 187)",
                                            "accent8": "rgb(98, 168, 187)",
                                            "background_dark": "rgb(0, 0, 0)",
                                            "background_light": "rgb(255, 255, 255)",
                                            "background_accent": "rgb(245, 245, 245)",
                                            "chart1": "rgb(20, 85, 140)",
                                            "chart2": "rgb(98, 168, 187)",
                                            "chart3": "rgb(152, 166, 77)",
                                            "chart4": "rgb(240, 179, 86)",
                                            "chart5": "rgb(230, 105, 34)",
                                            "chart6": "rgb(170, 89, 134)",
                                            "hyperlink": "rgb(17, 169, 226)"
                                        }
                                    },
                                    "blockMargin": {
                                        "top": -5.376000000000001,
                                        "bottom": 0,
                                        "left": 0,
                                        "right": 0
                                    },
                                    "topSpace": 5.376000000000001,
                                    "bottomSpace": 5.376000000000001
                                },
                                {
                                    "id": "84092152-ed82-46aa-8fb5-582822f57837",
                                    "html": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras velit turpis, viverra a lectus non, eleifend ultrices lorem. Integer ultricies nulla ut elementum sollicitudin.",
                                    "type": "text",
                                    "textStyle": "body",
                                    "hyphenation": false,
                                    "ligatures": true,
                                    "fontScale": 1,
                                    "indent": 0,
                                    "blockBounds": {
                                        "left": 0,
                                        "top": 27.828125,
                                        "width": 337,
                                        "height": 89.5625
                                    },
                                    "textStyles": {
                                        "fontFamily": "montserrat",
                                        "baseFontSize": 16,
                                        "fontColor": "secondary",
                                        "bulletScale": 1,
                                        "bulletSpacing": 2.5,
                                        "bulletIndent": 50,
                                        "fontScaling": 80,
                                        "fontWeight": 400,
                                        "letterSpacing": "0em",
                                        "lineHeight": 1.4,
                                        "textTransform": "none",
                                        "spaceBelow": 0,
                                        "spaceAbove": 0,
                                        "blockSpacing": 20,
                                        "boldStyles": {
                                            "fontColor": "primary"
                                        },
                                        "maxLines": 9999,
                                        "evenBreak": false,
                                        "listDecorationStyle": "theme",
                                        "allowFancyNumberedDecorations": false,
                                        "hasEmphasized": false,
                                        "blockInset": 0,
                                        "isRTL": false,
                                        "fontScale": 1,
                                        "fontSize": 16,
                                        "textAlign": "center",
                                        "autoColor": "rgb(90, 90, 76)",
                                        "color": "rgb(90, 90, 76)",
                                        "emphasizedColor": "rgb(42, 41, 33)",
                                        "themeColors": {
                                            "primary_light": "rgb(255, 255, 255)",
                                            "primary_dark": "rgb(42, 41, 33)",
                                            "secondary_light": "rgba(255, 255, 255, 0.9)",
                                            "secondary_dark": "rgb(90, 90, 76)",
                                            "theme": "rgb(98, 168, 187)",
                                            "accent1": "rgb(240, 179, 86)",
                                            "accent2": "rgb(230, 105, 34)",
                                            "accent3": "rgb(20, 85, 140)",
                                            "accent4": "rgb(98, 168, 187)",
                                            "accent5": "rgb(98, 168, 187)",
                                            "accent6": "rgb(98, 168, 187)",
                                            "accent7": "rgb(98, 168, 187)",
                                            "accent8": "rgb(98, 168, 187)",
                                            "background_dark": "rgb(0, 0, 0)",
                                            "background_light": "rgb(255, 255, 255)",
                                            "background_accent": "rgb(245, 245, 245)",
                                            "chart1": "rgb(20, 85, 140)",
                                            "chart2": "rgb(98, 168, 187)",
                                            "chart3": "rgb(152, 166, 77)",
                                            "chart4": "rgb(240, 179, 86)",
                                            "chart5": "rgb(230, 105, 34)",
                                            "chart6": "rgb(170, 89, 134)",
                                            "hyperlink": "rgb(17, 169, 226)"
                                        }
                                    },
                                    "blockMargin": {
                                        "top": 9.024,
                                        "bottom": -5.600000000000001,
                                        "left": 0,
                                        "right": 0
                                    },
                                    "topSpace": 5.6,
                                    "bottomSpace": 5.600000000000001
                                }
                            ]
                        }
                    },
                    {
                        "content_type": "icon",
                        "content_value": "space",
                        "id": "225171587586374771",
                        "frameType": "theme",
                        "titleTextStyle": "title",
                        "text": {
                            "blocks": [
                                {
                                    "id": "dd4980d1-6623-497c-84c1-3e09c226060f",
                                    "html": "Another Sample <font class=\"emphasized\">Title</font>",
                                    "type": "text",
                                    "textStyle": "title",
                                    "hyphenation": false,
                                    "ligatures": true,
                                    "fontScale": 1,
                                    "indent": 0,
                                    "blockBounds": {
                                        "left": 0,
                                        "top": -5.375,
                                        "width": 337,
                                        "height": 24.1875
                                    },
                                    "textStyles": {
                                        "fontFamily": "montserrat",
                                        "baseFontSize": 19.200000000000003,
                                        "fontColor": "primary",
                                        "bulletScale": 1,
                                        "bulletSpacing": 2.5,
                                        "bulletIndent": 50,
                                        "fontScaling": 80,
                                        "fontWeight": 500,
                                        "letterSpacing": "0em",
                                        "lineHeight": 1.26,
                                        "textTransform": "none",
                                        "spaceBelow": 0,
                                        "spaceAbove": 0,
                                        "blockSpacing": 20,
                                        "boldStyles": {
                                            "fontColor": "slide"
                                        },
                                        "maxLines": 9999,
                                        "evenBreak": false,
                                        "listDecorationStyle": "theme",
                                        "allowFancyNumberedDecorations": false,
                                        "hasEmphasized": true,
                                        "blockInset": 0,
                                        "isRTL": false,
                                        "fontScale": 1,
                                        "fontSize": 19.200000000000003,
                                        "textAlign": "center",
                                        "autoColor": "rgb(42, 41, 33)",
                                        "color": "rgb(42, 41, 33)",
                                        "emphasizedColor": "rgb(240, 179, 86)",
                                        "themeColors": {
                                            "primary_light": "rgb(255, 255, 255)",
                                            "primary_dark": "rgb(42, 41, 33)",
                                            "secondary_light": "rgba(255, 255, 255, 0.9)",
                                            "secondary_dark": "rgb(90, 90, 76)",
                                            "theme": "rgb(98, 168, 187)",
                                            "accent1": "rgb(240, 179, 86)",
                                            "accent2": "rgb(230, 105, 34)",
                                            "accent3": "rgb(20, 85, 140)",
                                            "accent4": "rgb(98, 168, 187)",
                                            "accent5": "rgb(98, 168, 187)",
                                            "accent6": "rgb(98, 168, 187)",
                                            "accent7": "rgb(98, 168, 187)",
                                            "accent8": "rgb(98, 168, 187)",
                                            "background_dark": "rgb(0, 0, 0)",
                                            "background_light": "rgb(255, 255, 255)",
                                            "background_accent": "rgb(245, 245, 245)",
                                            "chart1": "rgb(20, 85, 140)",
                                            "chart2": "rgb(98, 168, 187)",
                                            "chart3": "rgb(152, 166, 77)",
                                            "chart4": "rgb(240, 179, 86)",
                                            "chart5": "rgb(230, 105, 34)",
                                            "chart6": "rgb(170, 89, 134)",
                                            "hyperlink": "rgb(17, 169, 226)"
                                        }
                                    },
                                    "blockMargin": {
                                        "top": -5.376000000000001,
                                        "bottom": 0,
                                        "left": 0,
                                        "right": 0
                                    },
                                    "topSpace": 5.376000000000001,
                                    "bottomSpace": 5.376000000000001
                                },
                                {
                                    "id": "6a8b6895-06f2-4cc8-98ba-0a1e7cb73d57",
                                    "html": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras velit turpis, viverra a lectus non, eleifend ultrices lorem. Integer ultricies nulla ut elementum sollicitudin.",
                                    "type": "text",
                                    "textStyle": "body",
                                    "hyphenation": false,
                                    "ligatures": true,
                                    "fontScale": 1,
                                    "indent": 0,
                                    "blockBounds": {
                                        "left": 0,
                                        "top": 27.828125,
                                        "width": 337,
                                        "height": 89.5625
                                    },
                                    "textStyles": {
                                        "fontFamily": "montserrat",
                                        "baseFontSize": 16,
                                        "fontColor": "secondary",
                                        "bulletScale": 1,
                                        "bulletSpacing": 2.5,
                                        "bulletIndent": 50,
                                        "fontScaling": 80,
                                        "fontWeight": 400,
                                        "letterSpacing": "0em",
                                        "lineHeight": 1.4,
                                        "textTransform": "none",
                                        "spaceBelow": 0,
                                        "spaceAbove": 0,
                                        "blockSpacing": 20,
                                        "boldStyles": {
                                            "fontColor": "primary"
                                        },
                                        "maxLines": 9999,
                                        "evenBreak": false,
                                        "listDecorationStyle": "theme",
                                        "allowFancyNumberedDecorations": false,
                                        "hasEmphasized": false,
                                        "blockInset": 0,
                                        "isRTL": false,
                                        "fontScale": 1,
                                        "fontSize": 16,
                                        "textAlign": "center",
                                        "autoColor": "rgb(90, 90, 76)",
                                        "color": "rgb(90, 90, 76)",
                                        "emphasizedColor": "rgb(42, 41, 33)",
                                        "themeColors": {
                                            "primary_light": "rgb(255, 255, 255)",
                                            "primary_dark": "rgb(42, 41, 33)",
                                            "secondary_light": "rgba(255, 255, 255, 0.9)",
                                            "secondary_dark": "rgb(90, 90, 76)",
                                            "theme": "rgb(98, 168, 187)",
                                            "accent1": "rgb(240, 179, 86)",
                                            "accent2": "rgb(230, 105, 34)",
                                            "accent3": "rgb(20, 85, 140)",
                                            "accent4": "rgb(98, 168, 187)",
                                            "accent5": "rgb(98, 168, 187)",
                                            "accent6": "rgb(98, 168, 187)",
                                            "accent7": "rgb(98, 168, 187)",
                                            "accent8": "rgb(98, 168, 187)",
                                            "background_dark": "rgb(0, 0, 0)",
                                            "background_light": "rgb(255, 255, 255)",
                                            "background_accent": "rgb(245, 245, 245)",
                                            "chart1": "rgb(20, 85, 140)",
                                            "chart2": "rgb(98, 168, 187)",
                                            "chart3": "rgb(152, 166, 77)",
                                            "chart4": "rgb(240, 179, 86)",
                                            "chart5": "rgb(230, 105, 34)",
                                            "chart6": "rgb(170, 89, 134)",
                                            "hyperlink": "rgb(17, 169, 226)"
                                        }
                                    },
                                    "blockMargin": {
                                        "top": 9.024,
                                        "bottom": -5.600000000000001,
                                        "left": 0,
                                        "right": 0
                                    },
                                    "topSpace": 5.6,
                                    "bottomSpace": 5.600000000000001
                                }
                            ]
                        }
                    },
                    {
                        "content_type": "icon",
                        "content_value": "astronaut",
                        "id": "225181587586374771",
                        "frameType": "theme",
                        "titleTextStyle": "title",
                        "text": {
                            "blocks": [
                                {
                                    "id": "bc5bbfa3-da49-4f74-8ef6-5bb90b1589f4",
                                    "html": "One More Sample <font class=\"emphasized\">Title</font>",
                                    "type": "text",
                                    "textStyle": "title",
                                    "hyphenation": false,
                                    "ligatures": true,
                                    "fontScale": 1,
                                    "indent": 0,
                                    "blockBounds": {
                                        "left": 0,
                                        "top": -5.375,
                                        "width": 337,
                                        "height": 24.1875
                                    },
                                    "textStyles": {
                                        "fontFamily": "montserrat",
                                        "baseFontSize": 19.200000000000003,
                                        "fontColor": "primary",
                                        "bulletScale": 1,
                                        "bulletSpacing": 2.5,
                                        "bulletIndent": 50,
                                        "fontScaling": 80,
                                        "fontWeight": 500,
                                        "letterSpacing": "0em",
                                        "lineHeight": 1.26,
                                        "textTransform": "none",
                                        "spaceBelow": 0,
                                        "spaceAbove": 0,
                                        "blockSpacing": 20,
                                        "boldStyles": {
                                            "fontColor": "slide"
                                        },
                                        "maxLines": 9999,
                                        "evenBreak": false,
                                        "listDecorationStyle": "theme",
                                        "allowFancyNumberedDecorations": false,
                                        "hasEmphasized": true,
                                        "blockInset": 0,
                                        "isRTL": false,
                                        "fontScale": 1,
                                        "fontSize": 19.200000000000003,
                                        "textAlign": "center",
                                        "autoColor": "rgb(42, 41, 33)",
                                        "color": "rgb(42, 41, 33)",
                                        "emphasizedColor": "rgb(230, 105, 34)",
                                        "themeColors": {
                                            "primary_light": "rgb(255, 255, 255)",
                                            "primary_dark": "rgb(42, 41, 33)",
                                            "secondary_light": "rgba(255, 255, 255, 0.9)",
                                            "secondary_dark": "rgb(90, 90, 76)",
                                            "theme": "rgb(98, 168, 187)",
                                            "accent1": "rgb(240, 179, 86)",
                                            "accent2": "rgb(230, 105, 34)",
                                            "accent3": "rgb(20, 85, 140)",
                                            "accent4": "rgb(98, 168, 187)",
                                            "accent5": "rgb(98, 168, 187)",
                                            "accent6": "rgb(98, 168, 187)",
                                            "accent7": "rgb(98, 168, 187)",
                                            "accent8": "rgb(98, 168, 187)",
                                            "background_dark": "rgb(0, 0, 0)",
                                            "background_light": "rgb(255, 255, 255)",
                                            "background_accent": "rgb(245, 245, 245)",
                                            "chart1": "rgb(20, 85, 140)",
                                            "chart2": "rgb(98, 168, 187)",
                                            "chart3": "rgb(152, 166, 77)",
                                            "chart4": "rgb(240, 179, 86)",
                                            "chart5": "rgb(230, 105, 34)",
                                            "chart6": "rgb(170, 89, 134)",
                                            "hyperlink": "rgb(17, 169, 226)"
                                        }
                                    },
                                    "blockMargin": {
                                        "top": -5.376000000000001,
                                        "bottom": 0,
                                        "left": 0,
                                        "right": 0
                                    },
                                    "topSpace": 5.376000000000001,
                                    "bottomSpace": 5.376000000000001
                                },
                                {
                                    "id": "34321399-6ab1-4376-a215-fdf7f72b8456",
                                    "html": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras velit turpis, viverra a lectus non, eleifend ultrices lorem. Integer ultricies nulla ut elementum sollicitudin.",
                                    "type": "text",
                                    "textStyle": "body",
                                    "hyphenation": false,
                                    "ligatures": true,
                                    "fontScale": 1,
                                    "indent": 0,
                                    "blockBounds": {
                                        "left": 0,
                                        "top": 27.828125,
                                        "width": 337,
                                        "height": 89.5625
                                    },
                                    "textStyles": {
                                        "fontFamily": "montserrat",
                                        "baseFontSize": 16,
                                        "fontColor": "secondary",
                                        "bulletScale": 1,
                                        "bulletSpacing": 2.5,
                                        "bulletIndent": 50,
                                        "fontScaling": 80,
                                        "fontWeight": 400,
                                        "letterSpacing": "0em",
                                        "lineHeight": 1.4,
                                        "textTransform": "none",
                                        "spaceBelow": 0,
                                        "spaceAbove": 0,
                                        "blockSpacing": 20,
                                        "boldStyles": {
                                            "fontColor": "primary"
                                        },
                                        "maxLines": 9999,
                                        "evenBreak": false,
                                        "listDecorationStyle": "theme",
                                        "allowFancyNumberedDecorations": false,
                                        "hasEmphasized": false,
                                        "blockInset": 0,
                                        "isRTL": false,
                                        "fontScale": 1,
                                        "fontSize": 16,
                                        "textAlign": "center",
                                        "autoColor": "rgb(90, 90, 76)",
                                        "color": "rgb(90, 90, 76)",
                                        "emphasizedColor": "rgb(42, 41, 33)",
                                        "themeColors": {
                                            "primary_light": "rgb(255, 255, 255)",
                                            "primary_dark": "rgb(42, 41, 33)",
                                            "secondary_light": "rgba(255, 255, 255, 0.9)",
                                            "secondary_dark": "rgb(90, 90, 76)",
                                            "theme": "rgb(98, 168, 187)",
                                            "accent1": "rgb(240, 179, 86)",
                                            "accent2": "rgb(230, 105, 34)",
                                            "accent3": "rgb(20, 85, 140)",
                                            "accent4": "rgb(98, 168, 187)",
                                            "accent5": "rgb(98, 168, 187)",
                                            "accent6": "rgb(98, 168, 187)",
                                            "accent7": "rgb(98, 168, 187)",
                                            "accent8": "rgb(98, 168, 187)",
                                            "background_dark": "rgb(0, 0, 0)",
                                            "background_light": "rgb(255, 255, 255)",
                                            "background_accent": "rgb(245, 245, 245)",
                                            "chart1": "rgb(20, 85, 140)",
                                            "chart2": "rgb(98, 168, 187)",
                                            "chart3": "rgb(152, 166, 77)",
                                            "chart4": "rgb(240, 179, 86)",
                                            "chart5": "rgb(230, 105, 34)",
                                            "chart6": "rgb(170, 89, 134)",
                                            "hyperlink": "rgb(17, 169, 226)"
                                        }
                                    },
                                    "blockMargin": {
                                        "top": 9.024,
                                        "bottom": -5.600000000000001,
                                        "left": 0,
                                        "right": 0
                                    },
                                    "topSpace": 5.6,
                                    "bottomSpace": 5.600000000000001
                                }
                            ]
                        }
                    }
                ],
                "orientation": "auto"
            },
            "tray": {
                "items": [
                    {
                        "id": "232551587586706266",
                        "showText": true,
                        "cellColor": "background_accent",
                        "blocks": [
                            {
                                "type": "headline",
                                "id": "139651596755949420",
                                "content": {
                                    "text": "10x",
                                    "styles": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "start": 0,
                                            "end": 3
                                        }
                                    ]
                                }
                            },
                            {
                                "id": "140111596755960852",
                                "type": "body",
                                "content": {
                                    "text": "Show off with an impressive stat or accomplishment here.",
                                    "styles": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "start": 0,
                                            "end": 56
                                        }
                                    ]
                                }
                            },
                            {
                                "id": "184521596757110839",
                                "type": "divider"
                            },
                            {
                                "id": "156411596756329102",
                                "type": "headline",
                                "content": {
                                    "text": "1.5x",
                                    "styles": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "start": 0,
                                            "end": 4
                                        }
                                    ]
                                }
                            },
                            {
                                "id": "157251596756341963",
                                "type": "body",
                                "content": {
                                    "text": "Or two!",
                                    "styles": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "start": 0,
                                            "end": 51
                                        }
                                    ]
                                }
                            },
                            {
                                "id": "191511596757166542",
                                "type": "body",
                                "content": {
                                    "text": "Go on—let them know what you've been up to.",
                                    "styles": [
                                        {
                                            "bold": false,
                                            "italic": false,
                                            "start": 0,
                                            "end": 43
                                        }
                                    ]
                                }
                            }
                        ],
                        "scale": 0.18025000000000002,
                        "aoiBottom": 3999.9999999999995,
                        "aoiTop": 0,
                        "aoiRight": 3078.3722592799786,
                        "aoiLeft": 977.1062428004643,
                        "version": 1,
                        "textPosition": "center",
                        "textStyle": "white_box"
                    }
                ],
                "trayWidth": 344.81520300088266,
                "aspectRatio": "fill",
                "userFontScale": {
                    "CanvasElement/TrayContainer/TrayPhotoCollage/PhotoCollageItem/ContentBlockContainer/ContentBlockCollection/ContentBlockItem/ContentBlockTextCaption": 0.8500000000000001
                }
            }
        }
    ],
    "version": 10,
    "timeline": {
        "preset": "autoplay",
        "speed": "normal",
        "buildAnimationType": "overlapping"
    },
    "migrationVersion": 10.01
};

export const PreviewSlide3 = {
    "template_id": "chart_column",
    "layout": {
        "headerPosition": "top",
        "showFooter": "theme",
        "showElementTitle": false,
        "showAttribution": false,
        "trayLayout": "right_tray",
        "slideColor": "theme"
    },
    "states": [
        {
            "header": {
                "body": "",
                "text": {
                    "blocks": [
                        {
                            "id": "e47268ad-0a0a-434e-856c-f1c78ea63848",
                            "html": "Sample Header",
                            "type": "text",
                            "textStyle": "heading",
                            "hyphenation": false,
                            "ligatures": true,
                            "fontScale": 1,
                            "indent": 0,
                            "blockBounds": {
                                "left": 0,
                                "top": -11.890625,
                                "width": 843.296875,
                                "height": 53.546875
                            },
                            "textStyles": {
                                "fontFamily": "montserrat",
                                "baseFontSize": 42.5,
                                "fontColor": "primary",
                                "bulletScale": 1,
                                "bulletSpacing": 2.5,
                                "bulletIndent": 50,
                                "fontScaling": 85,
                                "fontWeight": 400,
                                "letterSpacing": "0em",
                                "lineHeight": 1.26,
                                "textTransform": "none",
                                "spaceBelow": 0,
                                "spaceAbove": 0,
                                "blockSpacing": 30,
                                "boldStyles": {
                                    "fontColor": "slide"
                                },
                                "maxLines": 9999,
                                "evenBreak": true,
                                "listDecorationStyle": "theme",
                                "allowFancyNumberedDecorations": false,
                                "hasEmphasized": false,
                                "blockInset": 0,
                                "isRTL": false,
                                "fontScale": 1,
                                "fontSize": 42.5,
                                "textAlign": "center",
                                "autoColor": "rgb(42, 41, 33)",
                                "color": "rgb(42, 41, 33)",
                                "emphasizedColor": "rgb(98, 168, 187)",
                                "themeColors": {
                                    "primary_light": "rgb(255, 255, 255)",
                                    "primary_dark": "rgb(42, 41, 33)",
                                    "secondary_light": "rgba(255, 255, 255, 0.9)",
                                    "secondary_dark": "rgb(90, 90, 76)",
                                    "theme": "rgb(98, 168, 187)",
                                    "accent1": "rgb(240, 179, 86)",
                                    "accent2": "rgb(230, 105, 34)",
                                    "accent3": "rgb(20, 85, 140)",
                                    "accent4": "rgb(98, 168, 187)",
                                    "accent5": "rgb(98, 168, 187)",
                                    "accent6": "rgb(98, 168, 187)",
                                    "accent7": "rgb(98, 168, 187)",
                                    "accent8": "rgb(98, 168, 187)",
                                    "background_dark": "rgb(0, 0, 0)",
                                    "background_light": "rgb(255, 255, 255)",
                                    "background_accent": "rgb(245, 245, 245)",
                                    "chart1": "rgb(20, 85, 140)",
                                    "chart2": "rgb(98, 168, 187)",
                                    "chart3": "rgb(152, 166, 77)",
                                    "chart4": "rgb(240, 179, 86)",
                                    "chart5": "rgb(230, 105, 34)",
                                    "chart6": "rgb(170, 89, 134)",
                                    "hyperlink": "rgb(17, 169, 226)"
                                }
                            },
                            "blockMargin": {
                                "top": -11.9,
                                "bottom": -11.899999999999999,
                                "left": 0,
                                "right": 0
                            },
                            "topSpace": 11.9,
                            "bottomSpace": 11.899999999999999
                        }
                    ]
                }
            },
            "primary": {
                "items": [
                    {
                        "elementType": "chart",
                        "chart": {
                            "showLegend": false,
                            "showXAxis": true,
                            "showYAxis": true,
                            "showAxisLabels": false,
                            "showXGridLines": true,
                            "showYGridLines": true,
                            "groupData": false,
                            "chartData": {
                                "series": [
                                    {
                                        "id": "series1",
                                        "name": "Item 1",
                                        "type": "column",
                                        "data": [
                                            {
                                                "y": 8
                                            },
                                            {
                                                "y": 18
                                            },
                                            {
                                                "y": 15
                                            },
                                            {
                                                "y": 22
                                            },
                                            {
                                                "y": 22
                                            },
                                            {
                                                "pt": true,
                                                "y": 29
                                            }
                                        ],
                                        "colorName": "chart1",
                                        "stacking": "normal"
                                    },
                                    {
                                        "id": "series1578512353076",
                                        "name": "Item 2",
                                        "type": "column",
                                        "data": [
                                            {
                                                "y": 5,
                                                "pt": true
                                            },
                                            {
                                                "y": 18,
                                                "pt": true
                                            },
                                            {
                                                "y": 23,
                                                "pt": true
                                            },
                                            {
                                                "y": 26,
                                                "pt": true
                                            },
                                            {
                                                "y": 15,
                                                "pt": true
                                            },
                                            {
                                                "y": 21,
                                                "pt": true
                                            }
                                        ],
                                        "colorName": "chart2",
                                        "marker": "none",
                                        "stacking": "normal"
                                    },
                                    {
                                        "id": "series1578512355605",
                                        "name": "Item 3",
                                        "type": "column",
                                        "data": [
                                            {
                                                "y": 5,
                                                "pt": true
                                            },
                                            {
                                                "y": 13,
                                                "pt": true
                                            },
                                            {
                                                "y": 22,
                                                "pt": true
                                            },
                                            {
                                                "y": 20,
                                                "pt": true
                                            },
                                            {
                                                "y": 12,
                                                "pt": true
                                            },
                                            {
                                                "y": 8,
                                                "pt": true
                                            }
                                        ],
                                        "colorName": "chart3",
                                        "marker": "none",
                                        "stacking": "normal"
                                    },
                                    {
                                        "id": "series1578512365862",
                                        "name": "Item 4",
                                        "type": "column",
                                        "data": [
                                            {
                                                "y": 0,
                                                "pt": true
                                            },
                                            {
                                                "y": 11,
                                                "pt": true
                                            },
                                            {
                                                "y": 9,
                                                "pt": true
                                            },
                                            {
                                                "y": 12,
                                                "pt": true
                                            },
                                            {
                                                "y": 0,
                                                "pt": true
                                            },
                                            {
                                                "y": 2,
                                                "pt": true
                                            }
                                        ],
                                        "colorName": "chart4",
                                        "marker": "none",
                                        "stacking": "normal",
                                        "zoneAxis": "x",
                                        "zones": [
                                            {
                                                "style": "default",
                                                "color": "rgb(65, 60, 88)",
                                                "fillColor": "rgb(65, 60, 88)",
                                                "dashStyle": "Solid"
                                            }
                                        ]
                                    },
                                    {
                                        "id": "series1600385371944",
                                        "name": "Item 5",
                                        "type": "line",
                                        "data": [
                                            {
                                                "y": 10,
                                                "pt": true
                                            },
                                            {
                                                "y": 31,
                                                "pt": true
                                            },
                                            {
                                                "y": 19,
                                                "pt": true
                                            },
                                            {
                                                "y": 42,
                                                "pt": true
                                            },
                                            {
                                                "y": 35,
                                                "pt": true
                                            },
                                            {
                                                "y": 60,
                                                "pt": true
                                            }
                                        ],
                                        "colorName": "chart5",
                                        "marker": "circle"
                                    }
                                ],
                                "xAxis": {
                                    "visible": true,
                                    "showFirstLabel": true,
                                    "showLastLabel": true,
                                    "endOnTick": true,
                                    "categories": [
                                        "Jan",
                                        "Feb",
                                        "Mar",
                                        "Apr",
                                        "May",
                                        "June"
                                    ],
                                    "showAxisLine": true,
                                    "showGridLine": false,
                                    "showMajorTicks": false,
                                    "showMinorTicks": false,
                                    "axisTitle": false,
                                    "axisTitleText": "Title",
                                    "labelInterval": 1,
                                    "dateSimple": true,
                                    "dateFormat": "monthFirst",
                                    "dateInterval": "daily",
                                    "type": "linear",
                                    "autoType": "linear",
                                    "categoryType": "auto",
                                    "labels": {
                                        "enabled": true
                                    },
                                    "zeroAxisPadding": true
                                },
                                "yAxis": {
                                    "visible": true,
                                    "showFirstLabel": true,
                                    "showLastLabel": true,
                                    "endOnTick": true,
                                    "showAxisLine": false,
                                    "showGridLines": true,
                                    "showMajorTicks": false,
                                    "showMinorTicks": false,
                                    "labelAbbreviation": false,
                                    "labelCurrency": "none",
                                    "labelFormat": "ones",
                                    "axisTitle": "none",
                                    "axisTitleText": "Title",
                                    "labels": {
                                        "enabled": true
                                    },
                                    "format": "currency",
                                    "formatOptions": {
                                        "decimal": 0,
                                        "separator": true,
                                        "abbreviated": false,
                                        "changeStyle": "none",
                                        "changeColor": true,
                                        "currency": "$",
                                        "dateFormat": "MMM Do",
                                        "accountingStyle": false
                                    },
                                    "prefix": "",
                                    "suffix": "k",
                                    "tickAmount": 6,
                                    "max": ""
                                },
                                "yAxis2": {
                                    "visible": false,
                                    "opposite": true,
                                    "showFirstLabel": true,
                                    "showLastLabel": true,
                                    "endOnTick": true,
                                    "showAxisLine": true,
                                    "showGridLines": true,
                                    "showMajorTicks": false,
                                    "showMinorTicks": false,
                                    "labelAbbreviation": false,
                                    "labelCurrency": "none",
                                    "labelFormat": "ones",
                                    "axisTitle": "none",
                                    "axisTitleText": "Title",
                                    "labels": {
                                        "enabled": true
                                    }
                                },
                                "legend": {
                                    "enabled": false
                                }
                            },
                            "legendPosition": "bottom",
                            "legendReverse": false,
                            "chartAnnotations": {
                                "items": [
                                    {
                                        "id": "98077",
                                        "nodeType": "text",
                                        "x": 0.6372561947084491,
                                        "y": 0.08142606985960489,
                                        "text": {
                                            "blocks": [
                                                {
                                                    "type": "text",
                                                    "id": "980991601580642120",
                                                    "textStyle": "title",
                                                    "html": "Title Copy",
                                                    "indent": 0,
                                                    "blockBounds": {
                                                        "left": 0,
                                                        "top": -5.375,
                                                        "width": 96,
                                                        "height": 24.1875
                                                    },
                                                    "textStyles": {
                                                        "fontFamily": "montserrat",
                                                        "baseFontSize": 19.200000000000003,
                                                        "fontColor": "primary",
                                                        "bulletScale": 1,
                                                        "bulletSpacing": 2.5,
                                                        "bulletIndent": 50,
                                                        "fontScaling": 80,
                                                        "fontWeight": 500,
                                                        "letterSpacing": "0em",
                                                        "lineHeight": 1.26,
                                                        "textTransform": "none",
                                                        "spaceBelow": 0,
                                                        "spaceAbove": 0,
                                                        "blockSpacing": 20,
                                                        "boldStyles": {
                                                            "fontColor": "slide"
                                                        },
                                                        "maxLines": 9999,
                                                        "evenBreak": false,
                                                        "listDecorationStyle": "theme",
                                                        "allowFancyNumberedDecorations": false,
                                                        "hasEmphasized": false,
                                                        "blockInset": 0,
                                                        "isRTL": false,
                                                        "fontScale": 1,
                                                        "fontSize": 19.200000000000003,
                                                        "textAlign": "center",
                                                        "autoColor": "rgb(42, 41, 33)",
                                                        "color": "rgb(42, 41, 33)",
                                                        "emphasizedColor": "rgb(98, 168, 187)",
                                                        "themeColors": {
                                                            "primary_light": "rgb(255, 255, 255)",
                                                            "primary_dark": "rgb(42, 41, 33)",
                                                            "secondary_light": "rgba(255, 255, 255, 0.9)",
                                                            "secondary_dark": "rgb(90, 90, 76)",
                                                            "theme": "rgb(98, 168, 187)",
                                                            "accent1": "rgb(240, 179, 86)",
                                                            "accent2": "rgb(230, 105, 34)",
                                                            "accent3": "rgb(20, 85, 140)",
                                                            "accent4": "rgb(98, 168, 187)",
                                                            "accent5": "rgb(98, 168, 187)",
                                                            "accent6": "rgb(98, 168, 187)",
                                                            "accent7": "rgb(98, 168, 187)",
                                                            "accent8": "rgb(98, 168, 187)",
                                                            "background_dark": "rgb(0, 0, 0)",
                                                            "background_light": "rgb(255, 255, 255)",
                                                            "background_accent": "rgb(245, 245, 245)",
                                                            "chart1": "rgb(20, 85, 140)",
                                                            "chart2": "rgb(98, 168, 187)",
                                                            "chart3": "rgb(152, 166, 77)",
                                                            "chart4": "rgb(240, 179, 86)",
                                                            "chart5": "rgb(230, 105, 34)",
                                                            "chart6": "rgb(170, 89, 134)",
                                                            "hyperlink": "rgb(17, 169, 226)"
                                                        }
                                                    },
                                                    "blockMargin": {
                                                        "top": -5.376000000000001,
                                                        "bottom": -5.376000000000001,
                                                        "left": 0,
                                                        "right": 0
                                                    },
                                                    "topSpace": 5.376000000000001,
                                                    "bottomSpace": 5.376000000000001
                                                }
                                            ]
                                        }
                                    }
                                ],
                                "connections": {
                                    "items": [
                                        {
                                            "id": "980791601580642096",
                                            "source": "98077",
                                            "target": "/primary/117771578511685794/infographic",
                                            "endDecoration": "circle",
                                            "endPointIsLocked": true,
                                            "canChangeConnectorType": false,
                                            "targetSnapOptions": {
                                                "axis": "xAxis"
                                            },
                                            "lineStyle": "solid",
                                            "lineWeight": 2,
                                            "startDecoration": "none",
                                            "connectorType": "straight",
                                            "startAnchor": "anchor-free",
                                            "endAnchor": "anchor-free",
                                            "labels": [],
                                            "startAnchorOffsetY": 0,
                                            "endAnchorOffsetY": 0
                                        }
                                    ]
                                }
                            }
                        },
                        "id": "117771578511685794"
                    }
                ]
            },
            "tray": {
                "items": [
                    {
                        "id": "135611578513040667",
                        "cellColor": "background_accent",
                        "verticalGap": 30,
                        "backdropPadding": 30,
                        "text": {
                            "blocks": [
                                {
                                    "type": "text",
                                    "id": "2310151600384649558",
                                    "html": "Sample caption",
                                    "textStyle": "caption",
                                    "hyphenation": false,
                                    "ligatures": true,
                                    "indent": 0,
                                    "blockBounds": {
                                        "left": 0,
                                        "top": -4.46875,
                                        "width": 274,
                                        "height": 20.15625
                                    },
                                    "textStyles": {
                                        "fontFamily": "montserrat",
                                        "baseFontSize": 16,
                                        "fontColor": "secondary",
                                        "bulletScale": 1,
                                        "bulletSpacing": 2.5,
                                        "bulletIndent": 50,
                                        "fontScaling": 80,
                                        "fontWeight": 500,
                                        "letterSpacing": "0em",
                                        "lineHeight": 1.26,
                                        "textTransform": "uppercase",
                                        "spaceBelow": 0,
                                        "spaceAbove": 0,
                                        "blockSpacing": 30,
                                        "maxLines": 9999,
                                        "evenBreak": false,
                                        "listDecorationStyle": "theme",
                                        "allowFancyNumberedDecorations": false,
                                        "hasEmphasized": false,
                                        "blockInset": 0,
                                        "isRTL": false,
                                        "fontScale": 1,
                                        "fontSize": 16,
                                        "textAlign": "center",
                                        "autoColor": "rgb(90, 90, 76)",
                                        "color": "rgb(90, 90, 76)",
                                        "emphasizedColor": "rgb(90, 90, 76)",
                                        "themeColors": {
                                            "primary_light": "rgb(255, 255, 255)",
                                            "primary_dark": "rgb(42, 41, 33)",
                                            "secondary_light": "rgba(255, 255, 255, 0.9)",
                                            "secondary_dark": "rgb(90, 90, 76)",
                                            "theme": "rgb(98, 168, 187)",
                                            "accent1": "rgb(240, 179, 86)",
                                            "accent2": "rgb(230, 105, 34)",
                                            "accent3": "rgb(20, 85, 140)",
                                            "accent4": "rgb(98, 168, 187)",
                                            "accent5": "rgb(98, 168, 187)",
                                            "accent6": "rgb(98, 168, 187)",
                                            "accent7": "rgb(98, 168, 187)",
                                            "accent8": "rgb(98, 168, 187)",
                                            "background_dark": "rgb(0, 0, 0)",
                                            "background_light": "rgb(255, 255, 255)",
                                            "background_accent": "rgb(245, 245, 245)",
                                            "chart1": "rgb(20, 85, 140)",
                                            "chart2": "rgb(98, 168, 187)",
                                            "chart3": "rgb(152, 166, 77)",
                                            "chart4": "rgb(240, 179, 86)",
                                            "chart5": "rgb(230, 105, 34)",
                                            "chart6": "rgb(170, 89, 134)",
                                            "hyperlink": "rgb(17, 169, 226)"
                                        }
                                    },
                                    "blockMargin": {
                                        "top": -4.48,
                                        "bottom": 0,
                                        "left": 0,
                                        "right": 0
                                    },
                                    "topSpace": 4.48,
                                    "bottomSpace": 4.48
                                },
                                {
                                    "id": "139101578513217575",
                                    "type": "text",
                                    "html": "$60,000",
                                    "textStyle": "headline",
                                    "hyphenation": false,
                                    "ligatures": true,
                                    "indent": 0,
                                    "blockBounds": {
                                        "left": 0,
                                        "top": 30.046875,
                                        "width": 274,
                                        "height": 66.9375
                                    },
                                    "textStyles": {
                                        "fontFamily": "montserrat",
                                        "baseFontSize": 63.75,
                                        "fontColor": "primary",
                                        "bulletScale": 1,
                                        "bulletSpacing": 2.5,
                                        "bulletIndent": 50,
                                        "fontScaling": 85,
                                        "fontWeight": 400,
                                        "letterSpacing": "0em",
                                        "lineHeight": 1.05,
                                        "textTransform": "none",
                                        "spaceBelow": 0,
                                        "spaceAbove": 0,
                                        "blockSpacing": 30,
                                        "boldStyles": {
                                            "fontColor": "slide"
                                        },
                                        "maxLines": 9999,
                                        "evenBreak": true,
                                        "listDecorationStyle": "theme",
                                        "allowFancyNumberedDecorations": false,
                                        "hasEmphasized": false,
                                        "blockInset": 0,
                                        "isRTL": false,
                                        "fontScale": 1,
                                        "fontSize": 63.75,
                                        "textAlign": "center",
                                        "autoColor": "rgb(42, 41, 33)",
                                        "color": "rgb(42, 41, 33)",
                                        "emphasizedColor": "rgb(98, 168, 187)",
                                        "themeColors": {
                                            "primary_light": "rgb(255, 255, 255)",
                                            "primary_dark": "rgb(42, 41, 33)",
                                            "secondary_light": "rgba(255, 255, 255, 0.9)",
                                            "secondary_dark": "rgb(90, 90, 76)",
                                            "theme": "rgb(98, 168, 187)",
                                            "accent1": "rgb(240, 179, 86)",
                                            "accent2": "rgb(230, 105, 34)",
                                            "accent3": "rgb(20, 85, 140)",
                                            "accent4": "rgb(98, 168, 187)",
                                            "accent5": "rgb(98, 168, 187)",
                                            "accent6": "rgb(98, 168, 187)",
                                            "accent7": "rgb(98, 168, 187)",
                                            "accent8": "rgb(98, 168, 187)",
                                            "background_dark": "rgb(0, 0, 0)",
                                            "background_light": "rgb(255, 255, 255)",
                                            "background_accent": "rgb(245, 245, 245)",
                                            "chart1": "rgb(20, 85, 140)",
                                            "chart2": "rgb(98, 168, 187)",
                                            "chart3": "rgb(152, 166, 77)",
                                            "chart4": "rgb(240, 179, 86)",
                                            "chart5": "rgb(230, 105, 34)",
                                            "chart6": "rgb(170, 89, 134)",
                                            "hyperlink": "rgb(17, 169, 226)"
                                        }
                                    },
                                    "blockMargin": {
                                        "top": 14.36375,
                                        "bottom": 0,
                                        "left": 0,
                                        "right": 0
                                    },
                                    "topSpace": 11.15625,
                                    "bottomSpace": 11.15625
                                },
                                {
                                    "id": "3083361602613148019",
                                    "type": "text",
                                    "html": "<i>Sample italicized body copy. The assumptions of rationality lead to modern portfolio theory.</i>",
                                    "textStyle": "body",
                                    "hyphenation": false,
                                    "ligatures": true,
                                    "indent": 0,
                                    "blockBounds": {
                                        "left": 0,
                                        "top": 109.09375,
                                        "width": 274,
                                        "height": 107.5
                                    },
                                    "textStyles": {
                                        "fontFamily": "montserrat",
                                        "baseFontSize": 19.200000000000003,
                                        "fontColor": "secondary",
                                        "bulletScale": 1,
                                        "bulletSpacing": 2.5,
                                        "bulletIndent": 50,
                                        "fontScaling": 80,
                                        "fontWeight": 400,
                                        "letterSpacing": "0em",
                                        "lineHeight": 1.4,
                                        "textTransform": "none",
                                        "spaceBelow": 0,
                                        "spaceAbove": 0,
                                        "blockSpacing": 30,
                                        "boldStyles": {
                                            "fontColor": "primary"
                                        },
                                        "maxLines": 9999,
                                        "evenBreak": false,
                                        "listDecorationStyle": "theme",
                                        "allowFancyNumberedDecorations": false,
                                        "hasEmphasized": false,
                                        "blockInset": 0,
                                        "isRTL": false,
                                        "fontScale": 1,
                                        "fontSize": 19.200000000000003,
                                        "textAlign": "center",
                                        "autoColor": "rgb(90, 90, 76)",
                                        "color": "rgb(90, 90, 76)",
                                        "emphasizedColor": "rgb(42, 41, 33)",
                                        "themeColors": {
                                            "primary_light": "rgb(255, 255, 255)",
                                            "primary_dark": "rgb(42, 41, 33)",
                                            "secondary_light": "rgba(255, 255, 255, 0.9)",
                                            "secondary_dark": "rgb(90, 90, 76)",
                                            "theme": "rgb(98, 168, 187)",
                                            "accent1": "rgb(240, 179, 86)",
                                            "accent2": "rgb(230, 105, 34)",
                                            "accent3": "rgb(20, 85, 140)",
                                            "accent4": "rgb(98, 168, 187)",
                                            "accent5": "rgb(98, 168, 187)",
                                            "accent6": "rgb(98, 168, 187)",
                                            "accent7": "rgb(98, 168, 187)",
                                            "accent8": "rgb(98, 168, 187)",
                                            "background_dark": "rgb(0, 0, 0)",
                                            "background_light": "rgb(255, 255, 255)",
                                            "background_accent": "rgb(245, 245, 245)",
                                            "chart1": "rgb(20, 85, 140)",
                                            "chart2": "rgb(98, 168, 187)",
                                            "chart3": "rgb(152, 166, 77)",
                                            "chart4": "rgb(240, 179, 86)",
                                            "chart5": "rgb(230, 105, 34)",
                                            "chart6": "rgb(170, 89, 134)",
                                            "hyperlink": "rgb(17, 169, 226)"
                                        }
                                    },
                                    "blockMargin": {
                                        "top": 12.123750000000001,
                                        "bottom": -6.719999999999999,
                                        "left": 0,
                                        "right": 0
                                    },
                                    "topSpace": 6.720000000000001,
                                    "bottomSpace": 6.719999999999999
                                }
                            ]
                        }
                    }
                ],
                "trayWidth": 336.6983523447402,
                "aspectRatio": "fill",
                "showGutter": false
            }
        }
    ],
    "version": 10,
    "migrationVersion": 10.01
};

export const PreviewSlide4 = {
    "layout": {
        "headerPosition": "top",
        "showAttribution": false,
        "showElementTitle": false,
        "showFooter": "theme",
        "showHeader": true,
        "trayLayout": "none",
        "slideColor": "theme"
    },
    "states": [
        {
            "header": {
                "showBody": true,
                "text": {
                    "blocks": [
                        {
                            "id": "edf6b224-a1b4-488e-b8f2-f22ff6789342",
                            "html": "Sample Header",
                            "type": "text",
                            "textStyle": "heading",
                            "hyphenation": false,
                            "ligatures": true,
                            "fontScale": 1,
                            "indent": 0,
                            "blockBounds": {
                                "left": 0,
                                "top": -11.890625,
                                "width": 1180,
                                "height": 53.546875
                            },
                            "textStyles": {
                                "fontFamily": "montserrat",
                                "baseFontSize": 42.5,
                                "fontColor": "primary",
                                "bulletScale": 1,
                                "bulletSpacing": 2.5,
                                "bulletIndent": 50,
                                "fontScaling": 85,
                                "fontWeight": 400,
                                "letterSpacing": "0em",
                                "lineHeight": 1.26,
                                "textTransform": "none",
                                "spaceBelow": 0,
                                "spaceAbove": 0,
                                "blockSpacing": 30,
                                "boldStyles": {
                                    "fontColor": "slide"
                                },
                                "maxLines": 9999,
                                "evenBreak": true,
                                "listDecorationStyle": "theme",
                                "allowFancyNumberedDecorations": false,
                                "hasEmphasized": false,
                                "blockInset": 0,
                                "isRTL": false,
                                "fontScale": 1,
                                "fontSize": 42.5,
                                "textAlign": "center",
                                "autoColor": "rgb(42, 41, 33)",
                                "color": "rgb(42, 41, 33)",
                                "emphasizedColor": "rgb(98, 168, 187)",
                                "themeColors": {
                                    "primary_light": "rgb(255, 255, 255)",
                                    "primary_dark": "rgb(42, 41, 33)",
                                    "secondary_light": "rgba(255, 255, 255, 0.9)",
                                    "secondary_dark": "rgb(90, 90, 76)",
                                    "theme": "rgb(98, 168, 187)",
                                    "accent1": "rgb(240, 179, 86)",
                                    "accent2": "rgb(230, 105, 34)",
                                    "accent3": "rgb(20, 85, 140)",
                                    "accent4": "rgb(98, 168, 187)",
                                    "accent5": "rgb(98, 168, 187)",
                                    "accent6": "rgb(98, 168, 187)",
                                    "accent7": "rgb(98, 168, 187)",
                                    "accent8": "rgb(98, 168, 187)",
                                    "background_dark": "rgb(0, 0, 0)",
                                    "background_light": "rgb(255, 255, 255)",
                                    "background_accent": "rgb(245, 245, 245)",
                                    "chart1": "rgb(20, 85, 140)",
                                    "chart2": "rgb(98, 168, 187)",
                                    "chart3": "rgb(152, 166, 77)",
                                    "chart4": "rgb(240, 179, 86)",
                                    "chart5": "rgb(230, 105, 34)",
                                    "chart6": "rgb(170, 89, 134)",
                                    "hyperlink": "rgb(17, 169, 226)"
                                }
                            },
                            "blockMargin": {
                                "top": -11.9,
                                "bottom": -11.899999999999999,
                                "left": 0,
                                "right": 0
                            },
                            "topSpace": 11.9,
                            "bottomSpace": 11.899999999999999
                        }
                    ]
                }
            },
            "primary": {
                "color": "accent1",
                "inverted": false,
                "items": [
                    {
                        "hilited": false,
                        "id": "4821541555179942",
                        "titleTextStyle": "title",
                        "text": {
                            "blocks": [
                                {
                                    "id": "66fa80a3-4b2b-44d1-81d6-a0f63de2a5dd",
                                    "html": "10,000",
                                    "type": "text",
                                    "textStyle": "title",
                                    "hyphenation": false,
                                    "ligatures": true,
                                    "fontScale": 1,
                                    "indent": 0,
                                    "blockBounds": {
                                        "left": 0,
                                        "top": -4.46875,
                                        "width": 450,
                                        "height": 20.15625
                                    },
                                    "textStyles": {
                                        "fontFamily": "montserrat",
                                        "baseFontSize": 16,
                                        "fontColor": "primary",
                                        "bulletScale": 1,
                                        "bulletSpacing": 2.5,
                                        "bulletIndent": 50,
                                        "fontScaling": 80,
                                        "fontWeight": 500,
                                        "letterSpacing": "0em",
                                        "lineHeight": 1.26,
                                        "textTransform": "none",
                                        "spaceBelow": 0,
                                        "spaceAbove": 0,
                                        "blockSpacing": 20,
                                        "boldStyles": {
                                            "fontColor": "slide"
                                        },
                                        "maxLines": 9999,
                                        "evenBreak": false,
                                        "listDecorationStyle": "theme",
                                        "allowFancyNumberedDecorations": false,
                                        "hasEmphasized": false,
                                        "blockInset": 0,
                                        "isRTL": false,
                                        "fontScale": 1,
                                        "fontSize": 16,
                                        "textAlign": "left",
                                        "autoColor": "rgb(42, 41, 33)",
                                        "color": "rgb(42, 41, 33)",
                                        "emphasizedColor": "rgb(98, 168, 187)",
                                        "themeColors": {
                                            "primary_light": "rgb(255, 255, 255)",
                                            "primary_dark": "rgb(42, 41, 33)",
                                            "secondary_light": "rgba(255, 255, 255, 0.9)",
                                            "secondary_dark": "rgb(90, 90, 76)",
                                            "theme": "rgb(98, 168, 187)",
                                            "accent1": "rgb(240, 179, 86)",
                                            "accent2": "rgb(230, 105, 34)",
                                            "accent3": "rgb(20, 85, 140)",
                                            "accent4": "rgb(98, 168, 187)",
                                            "accent5": "rgb(98, 168, 187)",
                                            "accent6": "rgb(98, 168, 187)",
                                            "accent7": "rgb(98, 168, 187)",
                                            "accent8": "rgb(98, 168, 187)",
                                            "background_dark": "rgb(0, 0, 0)",
                                            "background_light": "rgb(255, 255, 255)",
                                            "background_accent": "rgb(245, 245, 245)",
                                            "chart1": "rgb(20, 85, 140)",
                                            "chart2": "rgb(98, 168, 187)",
                                            "chart3": "rgb(152, 166, 77)",
                                            "chart4": "rgb(240, 179, 86)",
                                            "chart5": "rgb(230, 105, 34)",
                                            "chart6": "rgb(170, 89, 134)",
                                            "hyperlink": "rgb(17, 169, 226)"
                                        }
                                    },
                                    "blockMargin": {
                                        "top": -4.48,
                                        "bottom": 0,
                                        "left": 0,
                                        "right": 0
                                    },
                                    "topSpace": 4.48,
                                    "bottomSpace": 4.48
                                },
                                {
                                    "id": "0c9a7264-7b50-44ea-8f70-ce05cfd61885",
                                    "html": "Sample body copy. At the corporate level, marketing objectives are typically broad-based.",
                                    "type": "text",
                                    "textStyle": "body",
                                    "hyphenation": false,
                                    "ligatures": true,
                                    "fontScale": 1,
                                    "indent": 0,
                                    "blockBounds": {
                                        "left": 0,
                                        "top": 26.4375,
                                        "width": 450,
                                        "height": 38.0625
                                    },
                                    "textStyles": {
                                        "fontFamily": "montserrat",
                                        "baseFontSize": 13.600000000000001,
                                        "fontColor": "secondary",
                                        "bulletScale": 1,
                                        "bulletSpacing": 2.5,
                                        "bulletIndent": 50,
                                        "fontScaling": 80,
                                        "fontWeight": 400,
                                        "letterSpacing": "0em",
                                        "lineHeight": 1.3999999999999997,
                                        "textTransform": "none",
                                        "spaceBelow": 0,
                                        "spaceAbove": 0,
                                        "blockSpacing": 20,
                                        "boldStyles": {
                                            "fontColor": "primary"
                                        },
                                        "maxLines": 9999,
                                        "evenBreak": false,
                                        "listDecorationStyle": "theme",
                                        "allowFancyNumberedDecorations": false,
                                        "hasEmphasized": false,
                                        "blockInset": 0,
                                        "isRTL": false,
                                        "fontScale": 1,
                                        "fontSize": 13.600000000000001,
                                        "textAlign": "left",
                                        "autoColor": "rgb(90, 90, 76)",
                                        "color": "rgb(90, 90, 76)",
                                        "emphasizedColor": "rgb(42, 41, 33)",
                                        "themeColors": {
                                            "primary_light": "rgb(255, 255, 255)",
                                            "primary_dark": "rgb(42, 41, 33)",
                                            "secondary_light": "rgba(255, 255, 255, 0.9)",
                                            "secondary_dark": "rgb(90, 90, 76)",
                                            "theme": "rgb(98, 168, 187)",
                                            "accent1": "rgb(240, 179, 86)",
                                            "accent2": "rgb(230, 105, 34)",
                                            "accent3": "rgb(20, 85, 140)",
                                            "accent4": "rgb(98, 168, 187)",
                                            "accent5": "rgb(98, 168, 187)",
                                            "accent6": "rgb(98, 168, 187)",
                                            "accent7": "rgb(98, 168, 187)",
                                            "accent8": "rgb(98, 168, 187)",
                                            "background_dark": "rgb(0, 0, 0)",
                                            "background_light": "rgb(255, 255, 255)",
                                            "background_accent": "rgb(245, 245, 245)",
                                            "chart1": "rgb(20, 85, 140)",
                                            "chart2": "rgb(98, 168, 187)",
                                            "chart3": "rgb(152, 166, 77)",
                                            "chart4": "rgb(240, 179, 86)",
                                            "chart5": "rgb(230, 105, 34)",
                                            "chart6": "rgb(170, 89, 134)",
                                            "hyperlink": "rgb(17, 169, 226)"
                                        }
                                    },
                                    "blockMargin": {
                                        "top": 10.76,
                                        "bottom": -4.76,
                                        "left": 0,
                                        "right": 0
                                    },
                                    "topSpace": 4.76,
                                    "bottomSpace": 4.76
                                }
                            ]
                        }
                    },
                    {
                        "id": "4831541555179944",
                        "hilited": false,
                        "titleTextStyle": "title",
                        "text": {
                            "blocks": [
                                {
                                    "id": "66028a54-1ed4-411c-8f8b-908c33244c24",
                                    "html": "1,000",
                                    "type": "text",
                                    "textStyle": "title",
                                    "hyphenation": false,
                                    "ligatures": true,
                                    "fontScale": 1,
                                    "indent": 0,
                                    "blockBounds": {
                                        "left": 0,
                                        "top": -4.46875,
                                        "width": 536.53125,
                                        "height": 20.15625
                                    },
                                    "textStyles": {
                                        "fontFamily": "montserrat",
                                        "baseFontSize": 16,
                                        "fontColor": "primary",
                                        "bulletScale": 1,
                                        "bulletSpacing": 2.5,
                                        "bulletIndent": 50,
                                        "fontScaling": 80,
                                        "fontWeight": 500,
                                        "letterSpacing": "0em",
                                        "lineHeight": 1.26,
                                        "textTransform": "none",
                                        "spaceBelow": 0,
                                        "spaceAbove": 0,
                                        "blockSpacing": 20,
                                        "boldStyles": {
                                            "fontColor": "slide"
                                        },
                                        "maxLines": 9999,
                                        "evenBreak": false,
                                        "listDecorationStyle": "theme",
                                        "allowFancyNumberedDecorations": false,
                                        "hasEmphasized": false,
                                        "blockInset": 0,
                                        "isRTL": false,
                                        "fontScale": 1,
                                        "fontSize": 16,
                                        "textAlign": "left",
                                        "autoColor": "rgb(42, 41, 33)",
                                        "color": "rgb(42, 41, 33)",
                                        "emphasizedColor": "rgb(98, 168, 187)",
                                        "themeColors": {
                                            "primary_light": "rgb(255, 255, 255)",
                                            "primary_dark": "rgb(42, 41, 33)",
                                            "secondary_light": "rgba(255, 255, 255, 0.9)",
                                            "secondary_dark": "rgb(90, 90, 76)",
                                            "theme": "rgb(98, 168, 187)",
                                            "accent1": "rgb(240, 179, 86)",
                                            "accent2": "rgb(230, 105, 34)",
                                            "accent3": "rgb(20, 85, 140)",
                                            "accent4": "rgb(98, 168, 187)",
                                            "accent5": "rgb(98, 168, 187)",
                                            "accent6": "rgb(98, 168, 187)",
                                            "accent7": "rgb(98, 168, 187)",
                                            "accent8": "rgb(98, 168, 187)",
                                            "background_dark": "rgb(0, 0, 0)",
                                            "background_light": "rgb(255, 255, 255)",
                                            "background_accent": "rgb(245, 245, 245)",
                                            "chart1": "rgb(20, 85, 140)",
                                            "chart2": "rgb(98, 168, 187)",
                                            "chart3": "rgb(152, 166, 77)",
                                            "chart4": "rgb(240, 179, 86)",
                                            "chart5": "rgb(230, 105, 34)",
                                            "chart6": "rgb(170, 89, 134)",
                                            "hyperlink": "rgb(17, 169, 226)"
                                        }
                                    },
                                    "blockMargin": {
                                        "top": -4.48,
                                        "bottom": 0,
                                        "left": 0,
                                        "right": 0
                                    },
                                    "topSpace": 4.48,
                                    "bottomSpace": 4.48
                                },
                                {
                                    "id": "6831c9aa-eda9-4b3d-a0ce-e947ac87012a",
                                    "html": "Sample body copy. Pertaining to the general vision of the firm in the short, medium or long-term.",
                                    "type": "text",
                                    "textStyle": "body",
                                    "hyphenation": false,
                                    "ligatures": true,
                                    "fontScale": 1,
                                    "indent": 0,
                                    "blockBounds": {
                                        "left": 0,
                                        "top": 26.4375,
                                        "width": 536.53125,
                                        "height": 38.0625
                                    },
                                    "textStyles": {
                                        "fontFamily": "montserrat",
                                        "baseFontSize": 13.600000000000001,
                                        "fontColor": "secondary",
                                        "bulletScale": 1,
                                        "bulletSpacing": 2.5,
                                        "bulletIndent": 50,
                                        "fontScaling": 80,
                                        "fontWeight": 400,
                                        "letterSpacing": "0em",
                                        "lineHeight": 1.3999999999999997,
                                        "textTransform": "none",
                                        "spaceBelow": 0,
                                        "spaceAbove": 0,
                                        "blockSpacing": 20,
                                        "boldStyles": {
                                            "fontColor": "primary"
                                        },
                                        "maxLines": 9999,
                                        "evenBreak": false,
                                        "listDecorationStyle": "theme",
                                        "allowFancyNumberedDecorations": false,
                                        "hasEmphasized": false,
                                        "blockInset": 0,
                                        "isRTL": false,
                                        "fontScale": 1,
                                        "fontSize": 13.600000000000001,
                                        "textAlign": "left",
                                        "autoColor": "rgb(90, 90, 76)",
                                        "color": "rgb(90, 90, 76)",
                                        "emphasizedColor": "rgb(42, 41, 33)",
                                        "themeColors": {
                                            "primary_light": "rgb(255, 255, 255)",
                                            "primary_dark": "rgb(42, 41, 33)",
                                            "secondary_light": "rgba(255, 255, 255, 0.9)",
                                            "secondary_dark": "rgb(90, 90, 76)",
                                            "theme": "rgb(98, 168, 187)",
                                            "accent1": "rgb(240, 179, 86)",
                                            "accent2": "rgb(230, 105, 34)",
                                            "accent3": "rgb(20, 85, 140)",
                                            "accent4": "rgb(98, 168, 187)",
                                            "accent5": "rgb(98, 168, 187)",
                                            "accent6": "rgb(98, 168, 187)",
                                            "accent7": "rgb(98, 168, 187)",
                                            "accent8": "rgb(98, 168, 187)",
                                            "background_dark": "rgb(0, 0, 0)",
                                            "background_light": "rgb(255, 255, 255)",
                                            "background_accent": "rgb(245, 245, 245)",
                                            "chart1": "rgb(20, 85, 140)",
                                            "chart2": "rgb(98, 168, 187)",
                                            "chart3": "rgb(152, 166, 77)",
                                            "chart4": "rgb(240, 179, 86)",
                                            "chart5": "rgb(230, 105, 34)",
                                            "chart6": "rgb(170, 89, 134)",
                                            "hyperlink": "rgb(17, 169, 226)"
                                        }
                                    },
                                    "blockMargin": {
                                        "top": 10.76,
                                        "bottom": -4.76,
                                        "left": 0,
                                        "right": 0
                                    },
                                    "topSpace": 4.76,
                                    "bottomSpace": 4.76
                                }
                            ]
                        }
                    },
                    {
                        "id": "4841541555179945",
                        "titleTextStyle": "title",
                        "text": {
                            "blocks": [
                                {
                                    "id": "203cf546-d8d5-484a-a7d6-9b25b10895ed",
                                    "html": "300",
                                    "type": "text",
                                    "textStyle": "title",
                                    "hyphenation": false,
                                    "ligatures": true,
                                    "fontScale": 1,
                                    "indent": 0,
                                    "blockBounds": {
                                        "left": 0,
                                        "top": -4.46875,
                                        "width": 623.0625,
                                        "height": 20.15625
                                    },
                                    "textStyles": {
                                        "fontFamily": "montserrat",
                                        "baseFontSize": 16,
                                        "fontColor": "primary",
                                        "bulletScale": 1,
                                        "bulletSpacing": 2.5,
                                        "bulletIndent": 50,
                                        "fontScaling": 80,
                                        "fontWeight": 500,
                                        "letterSpacing": "0em",
                                        "lineHeight": 1.26,
                                        "textTransform": "none",
                                        "spaceBelow": 0,
                                        "spaceAbove": 0,
                                        "blockSpacing": 20,
                                        "boldStyles": {
                                            "fontColor": "slide"
                                        },
                                        "maxLines": 9999,
                                        "evenBreak": false,
                                        "listDecorationStyle": "theme",
                                        "allowFancyNumberedDecorations": false,
                                        "hasEmphasized": false,
                                        "blockInset": 0,
                                        "isRTL": false,
                                        "fontScale": 1,
                                        "fontSize": 16,
                                        "textAlign": "left",
                                        "autoColor": "rgb(42, 41, 33)",
                                        "color": "rgb(42, 41, 33)",
                                        "emphasizedColor": "rgb(98, 168, 187)",
                                        "themeColors": {
                                            "primary_light": "rgb(255, 255, 255)",
                                            "primary_dark": "rgb(42, 41, 33)",
                                            "secondary_light": "rgba(255, 255, 255, 0.9)",
                                            "secondary_dark": "rgb(90, 90, 76)",
                                            "theme": "rgb(98, 168, 187)",
                                            "accent1": "rgb(240, 179, 86)",
                                            "accent2": "rgb(230, 105, 34)",
                                            "accent3": "rgb(20, 85, 140)",
                                            "accent4": "rgb(98, 168, 187)",
                                            "accent5": "rgb(98, 168, 187)",
                                            "accent6": "rgb(98, 168, 187)",
                                            "accent7": "rgb(98, 168, 187)",
                                            "accent8": "rgb(98, 168, 187)",
                                            "background_dark": "rgb(0, 0, 0)",
                                            "background_light": "rgb(255, 255, 255)",
                                            "background_accent": "rgb(245, 245, 245)",
                                            "chart1": "rgb(20, 85, 140)",
                                            "chart2": "rgb(98, 168, 187)",
                                            "chart3": "rgb(152, 166, 77)",
                                            "chart4": "rgb(240, 179, 86)",
                                            "chart5": "rgb(230, 105, 34)",
                                            "chart6": "rgb(170, 89, 134)",
                                            "hyperlink": "rgb(17, 169, 226)"
                                        }
                                    },
                                    "blockMargin": {
                                        "top": -4.48,
                                        "bottom": 0,
                                        "left": 0,
                                        "right": 0
                                    },
                                    "topSpace": 4.48,
                                    "bottomSpace": 4.48
                                },
                                {
                                    "id": "6b21a703-25e5-4207-82f6-4737ebeb837a",
                                    "html": "Sample body copy. As an example, if one pictures a group of companies (or a conglomerate).",
                                    "type": "text",
                                    "textStyle": "body",
                                    "hyphenation": false,
                                    "ligatures": true,
                                    "fontScale": 1,
                                    "indent": 0,
                                    "blockBounds": {
                                        "left": 0,
                                        "top": 26.4375,
                                        "width": 623.0625,
                                        "height": 38.0625
                                    },
                                    "textStyles": {
                                        "fontFamily": "montserrat",
                                        "baseFontSize": 13.600000000000001,
                                        "fontColor": "secondary",
                                        "bulletScale": 1,
                                        "bulletSpacing": 2.5,
                                        "bulletIndent": 50,
                                        "fontScaling": 80,
                                        "fontWeight": 400,
                                        "letterSpacing": "0em",
                                        "lineHeight": 1.3999999999999997,
                                        "textTransform": "none",
                                        "spaceBelow": 0,
                                        "spaceAbove": 0,
                                        "blockSpacing": 20,
                                        "boldStyles": {
                                            "fontColor": "primary"
                                        },
                                        "maxLines": 9999,
                                        "evenBreak": false,
                                        "listDecorationStyle": "theme",
                                        "allowFancyNumberedDecorations": false,
                                        "hasEmphasized": false,
                                        "blockInset": 0,
                                        "isRTL": false,
                                        "fontScale": 1,
                                        "fontSize": 13.600000000000001,
                                        "textAlign": "left",
                                        "autoColor": "rgb(90, 90, 76)",
                                        "color": "rgb(90, 90, 76)",
                                        "emphasizedColor": "rgb(42, 41, 33)",
                                        "themeColors": {
                                            "primary_light": "rgb(255, 255, 255)",
                                            "primary_dark": "rgb(42, 41, 33)",
                                            "secondary_light": "rgba(255, 255, 255, 0.9)",
                                            "secondary_dark": "rgb(90, 90, 76)",
                                            "theme": "rgb(98, 168, 187)",
                                            "accent1": "rgb(240, 179, 86)",
                                            "accent2": "rgb(230, 105, 34)",
                                            "accent3": "rgb(20, 85, 140)",
                                            "accent4": "rgb(98, 168, 187)",
                                            "accent5": "rgb(98, 168, 187)",
                                            "accent6": "rgb(98, 168, 187)",
                                            "accent7": "rgb(98, 168, 187)",
                                            "accent8": "rgb(98, 168, 187)",
                                            "background_dark": "rgb(0, 0, 0)",
                                            "background_light": "rgb(255, 255, 255)",
                                            "background_accent": "rgb(245, 245, 245)",
                                            "chart1": "rgb(20, 85, 140)",
                                            "chart2": "rgb(98, 168, 187)",
                                            "chart3": "rgb(152, 166, 77)",
                                            "chart4": "rgb(240, 179, 86)",
                                            "chart5": "rgb(230, 105, 34)",
                                            "chart6": "rgb(170, 89, 134)",
                                            "hyperlink": "rgb(17, 169, 226)"
                                        }
                                    },
                                    "blockMargin": {
                                        "top": 10.76,
                                        "bottom": -4.76,
                                        "left": 0,
                                        "right": 0
                                    },
                                    "topSpace": 4.76,
                                    "bottomSpace": 4.76
                                }
                            ]
                        }
                    }
                ],
                "pyramidCapWidth": 0,
                "showDescription": true,
                "showTitle": false,
                "step": true,
                "capSize": 50,
                "baseSize": 6,
                "capAlign": "center",
                "style": "sequence"
            }
        }
    ],
    "template_id": "pyramid",
    "version": 10,
    "migrationVersion": 10.01
};

export const PreviewSlide5 = {
    "template_id": "arrow_bars",
    "template_version": 1,
    "layout": {
        "headerPosition": "top",
        "showFooter": "theme",
        "showElementTitle": false,
        "showAttribution": false,
        "trayLayout": "none",
        "slideColor": "colorful"
    },
    "states": [
        {
            "header": {
                "body": "",
                "text": {
                    "blocks": [
                        {
                            "id": "86c6467b-5d08-415d-8969-df7999790d47",
                            "html": "Sample Label",
                            "type": "text",
                            "textStyle": "label",
                            "hyphenation": false,
                            "ligatures": true,
                            "fontScale": 1,
                            "indent": 0,
                            "blockBounds": {
                                "left": 0,
                                "top": -5.9375,
                                "width": 1180,
                                "height": 26.765625
                            },
                            "textStyles": {
                                "fontFamily": "montserrat",
                                "baseFontSize": 21.25,
                                "fontColor": "slide",
                                "bulletScale": 1,
                                "bulletSpacing": 2.5,
                                "bulletIndent": 50,
                                "fontScaling": 85,
                                "fontWeight": 400,
                                "letterSpacing": "0em",
                                "lineHeight": 1.26,
                                "textTransform": "none",
                                "spaceBelow": 0,
                                "spaceAbove": 0,
                                "blockSpacing": 30,
                                "maxLines": 9999,
                                "evenBreak": false,
                                "listDecorationStyle": "theme",
                                "allowFancyNumberedDecorations": false,
                                "hasEmphasized": false,
                                "blockInset": 0,
                                "isRTL": false,
                                "fontScale": 1,
                                "fontSize": 21.25,
                                "textAlign": "center",
                                "autoColor": "rgb(98, 168, 187)",
                                "color": "rgb(98, 168, 187)",
                                "emphasizedColor": "rgb(98, 168, 187)",
                                "themeColors": {
                                    "primary_light": "rgb(255, 255, 255)",
                                    "primary_dark": "rgb(42, 41, 33)",
                                    "secondary_light": "rgba(255, 255, 255, 0.9)",
                                    "secondary_dark": "rgb(90, 90, 76)",
                                    "theme": "rgb(98, 168, 187)",
                                    "accent1": "rgb(240, 179, 86)",
                                    "accent2": "rgb(230, 105, 34)",
                                    "accent3": "rgb(20, 85, 140)",
                                    "accent4": "rgb(98, 168, 187)",
                                    "accent5": "rgb(98, 168, 187)",
                                    "accent6": "rgb(98, 168, 187)",
                                    "accent7": "rgb(98, 168, 187)",
                                    "accent8": "rgb(98, 168, 187)",
                                    "background_dark": "rgb(0, 0, 0)",
                                    "background_light": "rgb(255, 255, 255)",
                                    "background_accent": "rgb(245, 245, 245)",
                                    "chart1": "rgb(20, 85, 140)",
                                    "chart2": "rgb(98, 168, 187)",
                                    "chart3": "rgb(152, 166, 77)",
                                    "chart4": "rgb(240, 179, 86)",
                                    "chart5": "rgb(230, 105, 34)",
                                    "chart6": "rgb(170, 89, 134)",
                                    "hyperlink": "rgb(17, 169, 226)"
                                }
                            },
                            "blockMargin": {
                                "top": -5.95,
                                "bottom": 0,
                                "left": 0,
                                "right": 0
                            },
                            "topSpace": 5.95,
                            "bottomSpace": 5.949999999999999
                        },
                        {
                            "id": "8d9c1ee2-497d-4e1b-8b68-9db75a9000f3",
                            "html": "Sample Header",
                            "type": "text",
                            "textStyle": "heading",
                            "hyphenation": false,
                            "ligatures": true,
                            "fontScale": 1,
                            "indent": 0,
                            "blockBounds": {
                                "left": 0,
                                "top": 32.96875,
                                "width": 1180,
                                "height": 53.546875
                            },
                            "textStyles": {
                                "fontFamily": "montserrat",
                                "baseFontSize": 42.5,
                                "fontColor": "primary",
                                "bulletScale": 1,
                                "bulletSpacing": 2.5,
                                "bulletIndent": 50,
                                "fontScaling": 85,
                                "fontWeight": 400,
                                "letterSpacing": "0em",
                                "lineHeight": 1.26,
                                "textTransform": "none",
                                "spaceBelow": 0,
                                "spaceAbove": 0,
                                "blockSpacing": 30,
                                "boldStyles": {
                                    "fontColor": "slide"
                                },
                                "maxLines": 9999,
                                "evenBreak": true,
                                "listDecorationStyle": "theme",
                                "allowFancyNumberedDecorations": false,
                                "hasEmphasized": false,
                                "blockInset": 0,
                                "isRTL": false,
                                "fontScale": 1,
                                "fontSize": 42.5,
                                "textAlign": "center",
                                "autoColor": "rgb(42, 41, 33)",
                                "color": "rgb(42, 41, 33)",
                                "emphasizedColor": "rgb(240, 179, 86)",
                                "themeColors": {
                                    "primary_light": "rgb(255, 255, 255)",
                                    "primary_dark": "rgb(42, 41, 33)",
                                    "secondary_light": "rgba(255, 255, 255, 0.9)",
                                    "secondary_dark": "rgb(90, 90, 76)",
                                    "theme": "rgb(98, 168, 187)",
                                    "accent1": "rgb(240, 179, 86)",
                                    "accent2": "rgb(230, 105, 34)",
                                    "accent3": "rgb(20, 85, 140)",
                                    "accent4": "rgb(98, 168, 187)",
                                    "accent5": "rgb(98, 168, 187)",
                                    "accent6": "rgb(98, 168, 187)",
                                    "accent7": "rgb(98, 168, 187)",
                                    "accent8": "rgb(98, 168, 187)",
                                    "background_dark": "rgb(0, 0, 0)",
                                    "background_light": "rgb(255, 255, 255)",
                                    "background_accent": "rgb(245, 245, 245)",
                                    "chart1": "rgb(20, 85, 140)",
                                    "chart2": "rgb(98, 168, 187)",
                                    "chart3": "rgb(152, 166, 77)",
                                    "chart4": "rgb(240, 179, 86)",
                                    "chart5": "rgb(230, 105, 34)",
                                    "chart6": "rgb(170, 89, 134)",
                                    "hyperlink": "rgb(17, 169, 226)"
                                }
                            },
                            "blockMargin": {
                                "top": 12.150000000000002,
                                "bottom": 0,
                                "left": 0,
                                "right": 0
                            },
                            "topSpace": 11.9,
                            "bottomSpace": 11.899999999999999
                        },
                        {
                            "id": "f0cc5955-d461-4db2-a016-f86823d71f3e",
                            "html": "Sample body copy. This is a description.",
                            "type": "text",
                            "textStyle": "body",
                            "hyphenation": false,
                            "ligatures": true,
                            "fontScale": 1,
                            "indent": 0,
                            "blockBounds": {
                                "left": 0,
                                "top": 97.609375,
                                "width": 1180,
                                "height": 28
                            },
                            "textStyles": {
                                "fontFamily": "montserrat",
                                "baseFontSize": 20,
                                "fontColor": "secondary",
                                "bulletScale": 1,
                                "bulletSpacing": 2.5,
                                "bulletIndent": 50,
                                "fontScaling": 80,
                                "fontWeight": 400,
                                "letterSpacing": "0em",
                                "lineHeight": 1.4,
                                "textTransform": "none",
                                "spaceBelow": 0,
                                "spaceAbove": 0,
                                "blockSpacing": 30,
                                "boldStyles": {
                                    "fontColor": "primary"
                                },
                                "maxLines": 9999,
                                "evenBreak": false,
                                "listDecorationStyle": "theme",
                                "allowFancyNumberedDecorations": false,
                                "hasEmphasized": false,
                                "blockInset": 0,
                                "isRTL": false,
                                "fontScale": 1,
                                "fontSize": 20,
                                "textAlign": "center",
                                "autoColor": "rgb(90, 90, 76)",
                                "color": "rgb(90, 90, 76)",
                                "emphasizedColor": "rgb(42, 41, 33)",
                                "themeColors": {
                                    "primary_light": "rgb(255, 255, 255)",
                                    "primary_dark": "rgb(42, 41, 33)",
                                    "secondary_light": "rgba(255, 255, 255, 0.9)",
                                    "secondary_dark": "rgb(90, 90, 76)",
                                    "theme": "rgb(98, 168, 187)",
                                    "accent1": "rgb(240, 179, 86)",
                                    "accent2": "rgb(230, 105, 34)",
                                    "accent3": "rgb(20, 85, 140)",
                                    "accent4": "rgb(98, 168, 187)",
                                    "accent5": "rgb(98, 168, 187)",
                                    "accent6": "rgb(98, 168, 187)",
                                    "accent7": "rgb(98, 168, 187)",
                                    "accent8": "rgb(98, 168, 187)",
                                    "background_dark": "rgb(0, 0, 0)",
                                    "background_light": "rgb(255, 255, 255)",
                                    "background_accent": "rgb(245, 245, 245)",
                                    "chart1": "rgb(20, 85, 140)",
                                    "chart2": "rgb(98, 168, 187)",
                                    "chart3": "rgb(152, 166, 77)",
                                    "chart4": "rgb(240, 179, 86)",
                                    "chart5": "rgb(230, 105, 34)",
                                    "chart6": "rgb(170, 89, 134)",
                                    "hyperlink": "rgb(17, 169, 226)"
                                }
                            },
                            "blockMargin": {
                                "top": 11.100000000000001,
                                "bottom": -7,
                                "left": 0,
                                "right": 0
                            },
                            "topSpace": 7,
                            "bottomSpace": 7
                        }
                    ]
                }
            },
            "primary": {
                "showIndex": true,
                "showFoldEffect": true,
                "items": [
                    {
                        "value": 58,
                        "showArrowHead": true,
                        "label": {
                            "text": "One Sample Title",
                            "styles": [
                                {
                                    "bold": false,
                                    "italic": false,
                                    "start": 0,
                                    "end": 16
                                }
                            ],
                            "blocks": [
                                {
                                    "id": "ba01c875-ef32-4d73-b3db-f840140eb164",
                                    "html": "One Sample Title",
                                    "type": "text",
                                    "textStyle": "_use_styles_",
                                    "hyphenation": false,
                                    "ligatures": true,
                                    "fontScale": 1,
                                    "indent": 0,
                                    "blockBounds": {
                                        "left": 0,
                                        "top": -7.15625,
                                        "width": 512.53125,
                                        "height": 32.234375
                                    },
                                    "textStyles": {
                                        "fontFamily": "montserrat",
                                        "baseFontSize": 25.6,
                                        "fontColor": "primary",
                                        "bulletScale": 1,
                                        "bulletSpacing": 2.5,
                                        "bulletIndent": 50,
                                        "fontScaling": 80,
                                        "fontWeight": 500,
                                        "letterSpacing": "0em",
                                        "lineHeight": 1.26,
                                        "textTransform": "none",
                                        "spaceBelow": 0,
                                        "spaceAbove": 0,
                                        "blockSpacing": 20,
                                        "boldStyles": {
                                            "fontColor": "primary"
                                        },
                                        "maxLines": 9999,
                                        "evenBreak": false,
                                        "listDecorationStyle": "theme",
                                        "allowFancyNumberedDecorations": false,
                                        "hasEmphasized": false,
                                        "blockInset": 0,
                                        "isRTL": false,
                                        "fontScale": 1,
                                        "fontSize": 25.6,
                                        "textAlign": "left",
                                        "autoColor": "rgb(42, 41, 33)",
                                        "color": "rgb(42, 41, 33)",
                                        "emphasizedColor": "rgb(42, 41, 33)",
                                        "themeColors": {
                                            "primary_light": "rgb(255, 255, 255)",
                                            "primary_dark": "rgb(42, 41, 33)",
                                            "secondary_light": "rgba(255, 255, 255, 0.9)",
                                            "secondary_dark": "rgb(90, 90, 76)",
                                            "theme": "rgb(98, 168, 187)",
                                            "accent1": "rgb(240, 179, 86)",
                                            "accent2": "rgb(230, 105, 34)",
                                            "accent3": "rgb(20, 85, 140)",
                                            "accent4": "rgb(98, 168, 187)",
                                            "accent5": "rgb(98, 168, 187)",
                                            "accent6": "rgb(98, 168, 187)",
                                            "accent7": "rgb(98, 168, 187)",
                                            "accent8": "rgb(98, 168, 187)",
                                            "background_dark": "rgb(0, 0, 0)",
                                            "background_light": "rgb(255, 255, 255)",
                                            "background_accent": "rgb(245, 245, 245)",
                                            "chart1": "rgb(20, 85, 140)",
                                            "chart2": "rgb(98, 168, 187)",
                                            "chart3": "rgb(152, 166, 77)",
                                            "chart4": "rgb(240, 179, 86)",
                                            "chart5": "rgb(230, 105, 34)",
                                            "chart6": "rgb(170, 89, 134)",
                                            "hyperlink": "rgb(17, 169, 226)"
                                        }
                                    },
                                    "blockMargin": {
                                        "top": -7.168000000000001,
                                        "bottom": -7.167999999999999,
                                        "left": 0,
                                        "right": 0
                                    },
                                    "topSpace": 7.168000000000001,
                                    "bottomSpace": 7.167999999999999
                                }
                            ]
                        },
                        "id": "31561591229491073"
                    },
                    {
                        "value": 92,
                        "showArrowHead": true,
                        "label": {
                            "text": "Another Sample Title",
                            "styles": [
                                {
                                    "bold": false,
                                    "italic": false,
                                    "start": 0,
                                    "end": 20
                                }
                            ],
                            "blocks": [
                                {
                                    "id": "b5e8b1a8-745d-489f-bcdd-ba5f568314d5",
                                    "html": "Another Sample Title",
                                    "type": "text",
                                    "textStyle": "_use_styles_",
                                    "hyphenation": false,
                                    "ligatures": true,
                                    "fontScale": 1,
                                    "indent": 0,
                                    "blockBounds": {
                                        "left": 0,
                                        "top": -7.15625,
                                        "width": 858.578125,
                                        "height": 32.234375
                                    },
                                    "textStyles": {
                                        "fontFamily": "montserrat",
                                        "baseFontSize": 25.6,
                                        "fontColor": "primary",
                                        "bulletScale": 1,
                                        "bulletSpacing": 2.5,
                                        "bulletIndent": 50,
                                        "fontScaling": 80,
                                        "fontWeight": 500,
                                        "letterSpacing": "0em",
                                        "lineHeight": 1.26,
                                        "textTransform": "none",
                                        "spaceBelow": 0,
                                        "spaceAbove": 0,
                                        "blockSpacing": 20,
                                        "boldStyles": {
                                            "fontColor": "primary"
                                        },
                                        "maxLines": 9999,
                                        "evenBreak": false,
                                        "listDecorationStyle": "theme",
                                        "allowFancyNumberedDecorations": false,
                                        "hasEmphasized": false,
                                        "blockInset": 0,
                                        "isRTL": false,
                                        "fontScale": 1,
                                        "fontSize": 25.6,
                                        "textAlign": "left",
                                        "autoColor": "rgb(42, 41, 33)",
                                        "color": "rgb(42, 41, 33)",
                                        "emphasizedColor": "rgb(42, 41, 33)",
                                        "themeColors": {
                                            "primary_light": "rgb(255, 255, 255)",
                                            "primary_dark": "rgb(42, 41, 33)",
                                            "secondary_light": "rgba(255, 255, 255, 0.9)",
                                            "secondary_dark": "rgb(90, 90, 76)",
                                            "theme": "rgb(98, 168, 187)",
                                            "accent1": "rgb(240, 179, 86)",
                                            "accent2": "rgb(230, 105, 34)",
                                            "accent3": "rgb(20, 85, 140)",
                                            "accent4": "rgb(98, 168, 187)",
                                            "accent5": "rgb(98, 168, 187)",
                                            "accent6": "rgb(98, 168, 187)",
                                            "accent7": "rgb(98, 168, 187)",
                                            "accent8": "rgb(98, 168, 187)",
                                            "background_dark": "rgb(0, 0, 0)",
                                            "background_light": "rgb(255, 255, 255)",
                                            "background_accent": "rgb(245, 245, 245)",
                                            "chart1": "rgb(20, 85, 140)",
                                            "chart2": "rgb(98, 168, 187)",
                                            "chart3": "rgb(152, 166, 77)",
                                            "chart4": "rgb(240, 179, 86)",
                                            "chart5": "rgb(230, 105, 34)",
                                            "chart6": "rgb(170, 89, 134)",
                                            "hyperlink": "rgb(17, 169, 226)"
                                        }
                                    },
                                    "blockMargin": {
                                        "top": -7.168000000000001,
                                        "bottom": -7.167999999999999,
                                        "left": 0,
                                        "right": 0
                                    },
                                    "topSpace": 7.168000000000001,
                                    "bottomSpace": 7.167999999999999
                                }
                            ]
                        },
                        "id": "31571591229491075"
                    },
                    {
                        "value": 74,
                        "showArrowHead": true,
                        "label": {
                            "text": "One More Sample Title",
                            "styles": [
                                {
                                    "bold": false,
                                    "italic": false,
                                    "start": 0,
                                    "end": 21
                                }
                            ],
                            "blocks": [
                                {
                                    "id": "512d2aa9-a8fd-4397-b96f-84bd17fbbdc7",
                                    "html": "One More Sample Title",
                                    "type": "text",
                                    "textStyle": "_use_styles_",
                                    "hyphenation": false,
                                    "ligatures": true,
                                    "fontScale": 1,
                                    "indent": 0,
                                    "blockBounds": {
                                        "left": 0,
                                        "top": -7.15625,
                                        "width": 675.375,
                                        "height": 32.234375
                                    },
                                    "textStyles": {
                                        "fontFamily": "montserrat",
                                        "baseFontSize": 25.6,
                                        "fontColor": "primary",
                                        "bulletScale": 1,
                                        "bulletSpacing": 2.5,
                                        "bulletIndent": 50,
                                        "fontScaling": 80,
                                        "fontWeight": 500,
                                        "letterSpacing": "0em",
                                        "lineHeight": 1.26,
                                        "textTransform": "none",
                                        "spaceBelow": 0,
                                        "spaceAbove": 0,
                                        "blockSpacing": 20,
                                        "boldStyles": {
                                            "fontColor": "primary"
                                        },
                                        "maxLines": 9999,
                                        "evenBreak": false,
                                        "listDecorationStyle": "theme",
                                        "allowFancyNumberedDecorations": false,
                                        "hasEmphasized": false,
                                        "blockInset": 0,
                                        "isRTL": false,
                                        "fontScale": 1,
                                        "fontSize": 25.6,
                                        "textAlign": "left",
                                        "autoColor": "rgb(42, 41, 33)",
                                        "color": "rgb(42, 41, 33)",
                                        "emphasizedColor": "rgb(42, 41, 33)",
                                        "themeColors": {
                                            "primary_light": "rgb(255, 255, 255)",
                                            "primary_dark": "rgb(42, 41, 33)",
                                            "secondary_light": "rgba(255, 255, 255, 0.9)",
                                            "secondary_dark": "rgb(90, 90, 76)",
                                            "theme": "rgb(98, 168, 187)",
                                            "accent1": "rgb(240, 179, 86)",
                                            "accent2": "rgb(230, 105, 34)",
                                            "accent3": "rgb(20, 85, 140)",
                                            "accent4": "rgb(98, 168, 187)",
                                            "accent5": "rgb(98, 168, 187)",
                                            "accent6": "rgb(98, 168, 187)",
                                            "accent7": "rgb(98, 168, 187)",
                                            "accent8": "rgb(98, 168, 187)",
                                            "background_dark": "rgb(0, 0, 0)",
                                            "background_light": "rgb(255, 255, 255)",
                                            "background_accent": "rgb(245, 245, 245)",
                                            "chart1": "rgb(20, 85, 140)",
                                            "chart2": "rgb(98, 168, 187)",
                                            "chart3": "rgb(152, 166, 77)",
                                            "chart4": "rgb(240, 179, 86)",
                                            "chart5": "rgb(230, 105, 34)",
                                            "chart6": "rgb(170, 89, 134)",
                                            "hyperlink": "rgb(17, 169, 226)"
                                        }
                                    },
                                    "blockMargin": {
                                        "top": -7.168000000000001,
                                        "bottom": -7.167999999999999,
                                        "left": 0,
                                        "right": 0
                                    },
                                    "topSpace": 7.168000000000001,
                                    "bottomSpace": 7.167999999999999
                                }
                            ]
                        },
                        "id": "31581591229491076"
                    }
                ],
                "matchFontSizes": true
            },
            "tray": {
                "items": [
                    {
                        "content_value": "81eb309f8e0855e6f181892a1fd88364c400974ad359d713b4438e77b27be9c2-I",
                        "content_type": "image",
                        "id": "82391591658441484",
                        "aoiLeft": 168.9089048106448,
                        "aoiRight": 3288.85977482088,
                        "aoiTop": 4.547473508864641e-13,
                        "aoiBottom": 3456,
                        "scale": 0.14134837962962965,
                        "version": 1,
                        "cellColor": "background_accent"
                    }
                ]
            }
        }
    ],
    "version": 10,
    "migrationVersion": 10.01
};

export const PreviewSlide6 = {
    "template_id": "user_testimonial",
    "template_version": 1,
    "layout": {
        "headerPosition": "none",
        "showFooter": "theme",
        "showElementTitle": false,
        "showAttribution": false,
        "slideColor": "theme",
        "trayLayout": "left_tray"
    },
    "states": [
        {
            "header": {
                "title": "",
                "body": ""
            },
            "primary": {
                "showTail": true,
                "showDesignQuotes": true,
                "frameStyle": "quote-box",
                "items": [
                    {
                        "quote": {
                            "text": "Maybe stories are just data with a soul.",
                            "styles": [
                                {
                                    "bold": false,
                                    "italic": false,
                                    "start": 0,
                                    "end": 28
                                },
                                {
                                    "bold": true,
                                    "italic": false,
                                    "start": 28,
                                    "end": 40
                                }
                            ],
                            "blocks": [
                                {
                                    "id": "4f024ed0-dca1-4a22-a9aa-b0283b652bd7",
                                    "html": "Maybe stories are just data <font class=\"emphasized\">with a soul.</font>",
                                    "type": "text",
                                    "textStyle": "_use_styles_",
                                    "hyphenation": false,
                                    "ligatures": true,
                                    "fontScale": 1,
                                    "indent": 0,
                                    "blockBounds": {
                                        "left": 0,
                                        "top": -16.65625,
                                        "width": 645,
                                        "height": 149.9375
                                    },
                                    "textStyles": {
                                        "fontFamily": "montserrat",
                                        "baseFontSize": 59.5,
                                        "fontColor": "primary",
                                        "bulletScale": 1,
                                        "bulletSpacing": 2.5,
                                        "bulletIndent": 50,
                                        "fontScaling": 85,
                                        "fontWeight": 400,
                                        "letterSpacing": "0em",
                                        "lineHeight": 1.26,
                                        "textTransform": "none",
                                        "spaceBelow": 0,
                                        "spaceAbove": 0,
                                        "blockSpacing": 20,
                                        "boldStyles": {
                                            "fontColor": "slide!"
                                        },
                                        "maxLines": 9999,
                                        "evenBreak": true,
                                        "listDecorationStyle": "theme",
                                        "allowFancyNumberedDecorations": false,
                                        "hasEmphasized": true,
                                        "blockInset": 0,
                                        "isRTL": false,
                                        "fontScale": 1,
                                        "fontSize": 59.5,
                                        "textAlign": "center",
                                        "autoColor": "rgb(42, 41, 33)",
                                        "color": "rgb(42, 41, 33)",
                                        "emphasizedColor": "rgb(98, 168, 187)",
                                        "themeColors": {
                                            "primary_light": "rgb(255, 255, 255)",
                                            "primary_dark": "rgb(42, 41, 33)",
                                            "secondary_light": "rgba(255, 255, 255, 0.9)",
                                            "secondary_dark": "rgb(90, 90, 76)",
                                            "theme": "rgb(98, 168, 187)",
                                            "accent1": "rgb(240, 179, 86)",
                                            "accent2": "rgb(230, 105, 34)",
                                            "accent3": "rgb(20, 85, 140)",
                                            "accent4": "rgb(98, 168, 187)",
                                            "accent5": "rgb(98, 168, 187)",
                                            "accent6": "rgb(98, 168, 187)",
                                            "accent7": "rgb(98, 168, 187)",
                                            "accent8": "rgb(98, 168, 187)",
                                            "background_dark": "rgb(0, 0, 0)",
                                            "background_light": "rgb(255, 255, 255)",
                                            "background_accent": "rgb(245, 245, 245)",
                                            "chart1": "rgb(20, 85, 140)",
                                            "chart2": "rgb(98, 168, 187)",
                                            "chart3": "rgb(152, 166, 77)",
                                            "chart4": "rgb(240, 179, 86)",
                                            "chart5": "rgb(230, 105, 34)",
                                            "chart6": "rgb(170, 89, 134)",
                                            "hyperlink": "rgb(17, 169, 226)"
                                        }
                                    },
                                    "blockMargin": {
                                        "top": -16.66,
                                        "bottom": -16.659999999999997,
                                        "left": 0,
                                        "right": 0
                                    },
                                    "topSpace": 16.66,
                                    "bottomSpace": 16.659999999999997
                                }
                            ]
                        },
                        "showAttribution": true,
                        "showImage": false,
                        "showLogo": false,
                        "id": "3338781602614473279",
                        "textAlign": "center",
                        "content": {
                            "aoiLeft": 0,
                            "aoiRight": 3456,
                            "aoiTop": 0,
                            "aoiBottom": 4608,
                            "scale": 0.012152777777777778,
                            "version": 1
                        },
                        "showDesignQuotes": true,
                        "titleTextStyle": "title",
                        "textGroup": {
                            "blocks": [
                                {
                                    "id": "e776dfa8-3947-43f2-8ef5-a06dd0cd1f90",
                                    "html": "Brené Brown",
                                    "type": "text",
                                    "textStyle": "title",
                                    "hyphenation": false,
                                    "ligatures": true,
                                    "fontScale": 1,
                                    "indent": 0,
                                    "blockBounds": {
                                        "left": 0,
                                        "top": -4.46875,
                                        "width": 107,
                                        "height": 20.15625
                                    },
                                    "textStyles": {
                                        "fontFamily": "montserrat",
                                        "baseFontSize": 16,
                                        "fontColor": "primary",
                                        "bulletScale": 1,
                                        "bulletSpacing": 2.5,
                                        "bulletIndent": 50,
                                        "fontScaling": 80,
                                        "fontWeight": 500,
                                        "letterSpacing": "0em",
                                        "lineHeight": 1.26,
                                        "textTransform": "none",
                                        "spaceBelow": 0,
                                        "spaceAbove": 0,
                                        "blockSpacing": 10,
                                        "boldStyles": {
                                            "fontColor": "slide"
                                        },
                                        "maxLines": 1,
                                        "evenBreak": false,
                                        "listDecorationStyle": "theme",
                                        "allowFancyNumberedDecorations": false,
                                        "hasEmphasized": false,
                                        "blockInset": 0,
                                        "isRTL": false,
                                        "fontScale": 1,
                                        "fontSize": 16,
                                        "textAlign": "left",
                                        "autoColor": "rgb(42, 41, 33)",
                                        "color": "rgb(42, 41, 33)",
                                        "emphasizedColor": "rgb(98, 168, 187)",
                                        "themeColors": {
                                            "primary_light": "rgb(255, 255, 255)",
                                            "primary_dark": "rgb(42, 41, 33)",
                                            "secondary_light": "rgba(255, 255, 255, 0.9)",
                                            "secondary_dark": "rgb(90, 90, 76)",
                                            "theme": "rgb(98, 168, 187)",
                                            "accent1": "rgb(240, 179, 86)",
                                            "accent2": "rgb(230, 105, 34)",
                                            "accent3": "rgb(20, 85, 140)",
                                            "accent4": "rgb(98, 168, 187)",
                                            "accent5": "rgb(98, 168, 187)",
                                            "accent6": "rgb(98, 168, 187)",
                                            "accent7": "rgb(98, 168, 187)",
                                            "accent8": "rgb(98, 168, 187)",
                                            "background_dark": "rgb(0, 0, 0)",
                                            "background_light": "rgb(255, 255, 255)",
                                            "background_accent": "rgb(245, 245, 245)",
                                            "chart1": "rgb(20, 85, 140)",
                                            "chart2": "rgb(98, 168, 187)",
                                            "chart3": "rgb(152, 166, 77)",
                                            "chart4": "rgb(240, 179, 86)",
                                            "chart5": "rgb(230, 105, 34)",
                                            "chart6": "rgb(170, 89, 134)",
                                            "hyperlink": "rgb(17, 169, 226)"
                                        }
                                    },
                                    "blockMargin": {
                                        "top": -4.48,
                                        "bottom": -4.48,
                                        "left": 0,
                                        "right": 0
                                    },
                                    "topSpace": 4.48,
                                    "bottomSpace": 4.48
                                }
                            ]
                        }
                    }
                ],
                "userFontScale": {
                    "CanvasElement/UserTestimonial/UserTestimonialItem/UserTestimonialQuoteElement": 1
                }
            },
            "tray": {
                "items": [
                    {
                        "content_value": "ec74fe6eb4905f125318fa63f6cbef4a14f7d8be0eecf0ea65ef6b1e3a66553d-I",
                        "content_type": "image",
                        "id": "3355461602614642620",
                        "cellColor": "background_accent",
                        "aoiLeft": 0,
                        "aoiRight": 4000,
                        "aoiTop": 183.67346938775518,
                        "aoiBottom": 5816.326530612245,
                        "scale": 0.11025,
                        "version": 1,
                        "assetWidth": 4000,
                        "assetHeight": 6000
                    }
                ],
                "trayWidth": 290.2994296577947
            }
        }
    ],
    "version": 10,
    "migrationVersion": 10.01
};
