export interface ErrorResponseBody {
    message: string;
    [key: string]: any;
}

class BaseError extends Error {
    public readonly status: number;
    public readonly responseBody: ErrorResponseBody;
    public readonly name: string;

    constructor(message: string, responseBody?: Omit<ErrorResponseBody, "message">) {
        super(message);
        this.responseBody = { message, ...responseBody };
        this.name = this.constructor.name;
    }
}

export class BadRequestError extends BaseError {
    public readonly status = 400;
}

export class NotFoundError extends BaseError {
    public readonly status = 404;
}

export class InternalServerError extends BaseError {
    public readonly status = 500;
}

export class UnauthorizedError extends BaseError {
    public readonly status = 401;
}

export class ForbiddenError extends BaseError {
    public readonly status = 403;
}

export class TooManyRequestsError extends BaseError {
    public readonly status = 429;
}

export class ConflictError extends BaseError {
    public readonly status = 409;
}
