import PusherJS, { Channel, PresenceChannel } from "pusher-js";

import Api from "js/core/api";
import { isDevelopment, pusherKey, pusherCluster, disablePusher } from "js/config";
import getLogger, { LogGroup } from "js/core/logger";
import { IChunkedPusherEvent } from "common/interfaces";

const logger = getLogger(LogGroup.PUSHER);

const PUSHER_ERROR_TYPE = "PusherError";

// Uncomment to enable pusher events logging
// PusherJS.logToConsole = true;

export type ExtendedChannel = Channel & ChannelExtension;
export type ExtendedPresenceChannel = PresenceChannel & ChannelExtension;

interface ChannelExtension {
    /**
     * Indicates if the channel has any listeners bound to it
     */
    isInUse: boolean;
    /**
     * Handles chunked events
     * Returns unsubscriber function to be able to unbind the chunked event
     */
    bindChunked<T>(eventName: string, callback: (payload: T) => void): () => void;
}

class Pusher {
    private _pusherClient: PusherJS = null;

    constructor() {
        if (disablePusher) {
            return;
        }

        // Skip authorization for jest tests.
        //   If we ever want to test pusher events, we'll need to actually mock this step.
        //   See collaborationSlidesLock.test.ts for an example of how we might mock Api.pusherAuthenticate.post.
        //   We'll also need to devise a way to authorize different users which would likely involve allowing the
        //     creation of multiple puseher clients instead of the single one created in this service.
        // @ts-ignore
        if (window.jestTestActive) {
            // @ts-ignore
            this._pusherClient = new PusherJS();
        } else {
            this._pusherClient = new PusherJS(pusherKey, {
                cluster: pusherCluster,
                authorizer: channel => ({
                    authorize: (socketId, callback) => {
                        const channelId = channel.name;

                        // Won't track offline status for pusher auth requests
                        Api.pusherAuthenticate.post({ channelId, socketId }, null, false, false, false)
                            .then(authData => callback(null, authData))
                            .catch(err => callback(err, { auth: "" }));
                    }
                })
            });
        }

        this._pusherClient.connection.bind("error", err => {
            // In some cases pusher puts the actual pusher error object
            // into the 'error' subfield
            let pusherError;
            if (err.type === PUSHER_ERROR_TYPE) {
                pusherError = err;
            } else if (err.error?.type === PUSHER_ERROR_TYPE) {
                pusherError = err.error;
            }

            if (pusherError && (
                (pusherError.data.code >= 4200 && pusherError.data.code <= 4299) ||
                pusherError.data.code === 1006
            )) {
                // Non critical errors upon which the connection will be re-established
                logger.warn("[pusher] connnection error", pusherError);
            } else {
                logger.error(err, "[pusher] critical connnection error", err);
            }
        });
    }

    private _ensureSubscribed(channel: Channel) {
        if (channel.subscribed) {
            return Promise.resolve();
        }

        return new Promise<void>((resolve, reject) => {
            const onError = err => {
                channel.unbind("pusher:subscription_error", onError);
                channel.unbind("pusher:subscription_succeeded", onSuccess);
                reject(err);
            };
            const onSuccess = () => {
                channel.unbind("pusher:subscription_error", onError);
                channel.unbind("pusher:subscription_succeeded", onSuccess);
                resolve();
            };
            channel.bind("pusher:subscription_error", onError);
            channel.bind("pusher:subscription_succeeded", onSuccess);
        });
    }

    public async subscribe(channelId: string) {
        if (!this._pusherClient) {
            return null;
        }

        // Subscribing to channel
        const channel = this._pusherClient.subscribe(channelId);

        // Allows to know if something else is using the channel
        if (!channel.hasOwnProperty("isInUse")) {
            Object.defineProperty(channel, "isInUse", {
                get() {
                    const channel: Channel = this; // Casting
                    return Object.keys(channel.callbacks._callbacks || {}).length > 0 || channel.subscriptionPending;
                }
            });
        }

        // Allows to seamlessly bind chunked events
        if (!channel.hasOwnProperty("bindChunked")) {
            Object.defineProperty(channel, "bindChunked", {
                value: (eventName: string, callback: (parsedEvent: any) => void) => {
                    const decodeAndParseChunksData = (encodedData: string) =>
                        JSON.parse(decodeURIComponent(escape(atob(encodedData))));

                    const chunksBuffer: Record<string, string[]> = {};

                    const handleEvent = (payload: IChunkedPusherEvent) => {
                        const {
                            eventId,
                            chunksTotalCount,
                            chunkIndex,
                            chunk
                        } = payload;

                        // Message has only one chunk -> process immediately
                        if (chunksTotalCount === 1) {
                            callback(decodeAndParseChunksData(chunk));
                            return;
                        }

                        // Creating chunks buffer for event if not exists
                        if (!chunksBuffer[eventId]) {
                            chunksBuffer[eventId] = new Array(chunksTotalCount);
                        }

                        // Saving received chunk under its index
                        chunksBuffer[eventId][chunkIndex] = chunk;

                        // Checking if we have all chunks and composing the event if we do
                        if (chunksBuffer[eventId].filter(Boolean).length === chunksTotalCount) {
                            const parsedEvent = decodeAndParseChunksData(chunksBuffer[eventId].join(""));
                            delete chunksBuffer[eventId];
                            callback(parsedEvent);
                        }
                    };

                    channel.bind(eventName, handleEvent);

                    return () => channel.unbind(eventName, handleEvent);
                }
            });
        }

        // Waiting for subscription to establish
        await this._ensureSubscribed(channel);

        if (channelId.startsWith("presence-")) {
            return channel as ExtendedPresenceChannel;
        }

        return channel as ExtendedChannel;
    }

    public unsubscribe(channelId: string) {
        if (!this._pusherClient) {
            return;
        }

        this._pusherClient.unsubscribe(channelId);
    }

    public async waitForEvent<ResponseType>(channelId: string, successEventType: string, errorEventType: string = null, timeoutMs: number = null) {
        const channel = await this.subscribe(channelId);
        if (!channel) {
            return null;
        }

        return new Promise<ResponseType>((resolve, reject) => {
            let timeoutHandler;
            const pusherCallbacks: Record<string, Function> = {};

            const unsubscribe = () => {
                Object.entries(pusherCallbacks).forEach(([eventName, callback]) => {
                    channel.unbind(eventName, callback);
                });
                if (!channel.isInUse) {
                    channel.unsubscribe();
                }
            };

            pusherCallbacks[successEventType] = response => {
                unsubscribe();
                if (timeoutHandler) clearTimeout(timeoutHandler);
                resolve(response as ResponseType);
            };

            if (errorEventType) {
                pusherCallbacks[errorEventType] = response => {
                    unsubscribe();
                    if (timeoutHandler) clearTimeout(timeoutHandler);
                    reject(new Error(response.errorMessage));
                };
            }

            if (timeoutMs) {
                timeoutHandler = setTimeout(() => {
                    unsubscribe();
                    reject(new Error(`Failed to receive event for channel id ${channelId}, timed out after ${timeoutMs}ms`));
                }, timeoutMs);
            }

            Object.entries(pusherCallbacks).forEach(([eventName, callback]) => {
                channel.bind(eventName, callback);
            });
        });
    }
}

const pusher = new Pusher();

if (isDevelopment) {
    // For local debugging
    (window as any).pusher = pusher;
}

export default pusher;
