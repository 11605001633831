import type { Model as BackboneModel } from "backbone";
import { v4 as uuid } from "uuid";

import { ConflictError } from "apis/errors";
import { PusherEventType } from "common/constants";
import removeTrailingNullsFromArrays from "common/utils/removeTrailingNullsFromArrays";
import getLogger, { LogGroup } from "js/core/logger";
import pusher, { ExtendedChannel } from "js/core/services/pusher";
import { _ } from "js/vendor";

import Adapter from "./adapter";

const logger = getLogger(LogGroup.ADAPTER);

export class ShouldUseFallbackAdapterError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "ShouldUseFallbackAdapterError";
    }
}

class ApiAdapter<Model extends { modifiedAt: number }, AdditionalProps> extends Adapter {
    public id: string;
    public model: BackboneModel<Model>;
    public autoSync: boolean;
    public readonly: boolean;
    public connected: boolean = false;

    protected _additionalProps: AdditionalProps;
    protected _asyncActionsPromiseChain: Promise<void> = Promise.resolve();
    protected _pusherEventChunksBuffer: { [id: string]: string[] } = {};
    protected _pusherChannel: ExtendedChannel = null;
    protected _pusherChannelUnbind: () => void = null;
    protected _useFallbackAdapter: boolean = false;
    protected _fallbackAdapter: Adapter = null;

    protected _onRemoteChange: (changeType: number, data: Partial<Model>) => void;
    protected _onRemoteError: (err: Error) => void;

    constructor({
        model,
        autoSync = true,
        readonly = false,
        ...additionalProps
    }: {
        model: BackboneModel<Model>,
        autoSync?: boolean,
        readonly?: boolean,
    } & AdditionalProps) {
        super();

        this.model = model;
        this.autoSync = autoSync;
        this.readonly = readonly;
        this._additionalProps = additionalProps as unknown as AdditionalProps;
    }

    public connect(
        {
            id = null,
            onRemoteChange = () => { },
            onRemoteError = () => { },
            data = null
        }: {
            id?: string,
            onRemoteChange?: (changeType: number, data: Partial<Model>) => void,
            onRemoteError?: (err: Error) => void,
            data?: Partial<Model>
        }
    ) {
        if (data && id) {
            throw new Error("Initializing model with both id and initial data is not permitted");
        }

        if (!data && !id) {
            throw new Error("Initial data or id must be present");
        }

        if (this.readonly && data) {
            throw new Error("Cannot create new record in readonly mode");
        }

        // Auto generating an id if not passed
        this.id = _.defaultTo(id, this.createUid());

        // Wrapping callbacks to allow logging
        this._onRemoteChange = (changeType, data) => {
            // We have to remove trailing nulls from arrays since there's no handy way to entirely remove elements from arrays
            // in Mongo so "removed" elements are marked as nulls instead, so we have to trim them
            const normalizedData = removeTrailingNullsFromArrays(_.cloneDeep(data));
            return onRemoteChange(changeType, normalizedData);
        };
        this._onRemoteError = err => {
            logger.error(err, "[ApiAdapter] onRemoteError()", { id: this.id });
            return onRemoteError(err);
        };

        // Initializing (loading/setting the data)
        this._initialize(data);

        this.connected = true;

        // Returning the model id
        return this.id;
    }

    public disconnect() {
        if (!this.connected) {
            return;
        }

        this._disconnectPusher();

        if (this._useFallbackAdapter) {
            this._fallbackAdapter.disconnect();
        }

        this.connected = false;

        // NOTE: there may be still background updates running after the
        // adapter has been disconnected
    }

    public update(type: number, changesetUpdates: Partial<Model>, changesetOriginal: Partial<Model>) {
        if (this._useFallbackAdapter) {
            return this._fallbackAdapter.update(type, changesetUpdates, changesetOriginal);
        }

        if (!this.isConnected()) {
            throw new Error("Adapter is not connected");
        }

        if (this.readonly) {
            throw new Error("Adapter is in read only mode, cannot update");
        }

        switch (type) {
            case Adapter.TYPE.remove:
                throw new Error("Remove update type not supported");
            case Adapter.TYPE.replace:
                throw new Error("Replace update type not supported");
            case Adapter.TYPE.update:
                return this._handleUpdate(changesetUpdates, changesetOriginal);
            default:
                throw new Error(`Unsupported update type ${type}`);
        }
    }

    public isConnected() {
        if (this._useFallbackAdapter) {
            return this._fallbackAdapter.isConnected();
        }

        return this.connected;
    }

    public createUid() {
        if (this._useFallbackAdapter) {
            return this._fallbackAdapter.createUid();
        }

        return uuid();
    }

    public async setAutoSync(autoSync: boolean) {
        if (this._useFallbackAdapter) {
            return this._fallbackAdapter.setAutoSync(autoSync);
        }

        if (this.autoSync === autoSync) {
            return;
        }

        this.autoSync = autoSync;
        if (this.autoSync) {
            await this._connectPusher();
        } else {
            this._disconnectPusher();
        }
    }

    protected _disconnectPusher() {
        if (this._pusherChannel) {
            this._pusherChannelUnbind();
            // Will unsubscribe if only nobody else is using the channel (e.g. another model instance)
            if (!this._pusherChannel.isInUse) {
                pusher.unsubscribe(this._pusherChannel.name);
            }
            this._pusherChannel = null;
            this._pusherChannelUnbind = null;
        }
    }

    protected _onPusherEvent = (payload: { original: Partial<Model>, update: Partial<Model>, triggeredAt: number }) => {
        this._asyncActionsPromiseChain = this._asyncActionsPromiseChain
            .then(() => {
                // May have been disconnected while was waiting for the changes
                // to be written
                if (!this.connected) {
                    return;
                }

                const { update, triggeredAt } = payload;

                if (triggeredAt <= this.model.attributes.modifiedAt) {
                    return;
                }

                // Data is a changeset
                this._onRemoteChange(Adapter.TYPE.update, update);
            })
            .catch(err => {
                // Won't throw here to avoid breaking the promise chain
                logger.error(err, `[ApiAdapter] _onPusherEvent() failed`, { id: this.id });

                this._onRemoteError(err);
            });
    }

    protected _handleUpdate(changesetUpdates, changesetOriginal) {
        const handleUpdate = async () => {
            try {
                const updated = await this._updateApi(changesetUpdates, changesetOriginal);
                // Report back the updated model values
                this._onRemoteChange(Adapter.TYPE.update, updated);
            } catch (err) {
                if (err instanceof ConflictError) {
                    logger.warn(`[ApiAdapter] _handleUpdate() the model is out of sync with the server, will pull the latest version`, { id: this.id });
                    try {
                        const data = await this._getApi();
                        // Setting the server state
                        this._onRemoteChange(Adapter.TYPE.replace, data);
                    } catch (err) {
                        logger.error(err, `[ApiAdapter] _handleUpdate() _getApi() failed`, { id: this.id });
                    }
                } else {
                    logger.error(err, `[ApiAdapter] _handleUpdate() failed`, { id: this.id });
                    // Restoring the original state
                    this._onRemoteChange(Adapter.TYPE.update, changesetOriginal);
                }

                throw err;
            }
        };

        return new Promise((resolve, reject) => {
            this._asyncActionsPromiseChain = this._asyncActionsPromiseChain
                .then(handleUpdate)
                .then(resolve)
                .catch(err => {
                    this._onRemoteError(err);
                    reject(err);
                });
        });
    }

    protected _initialize(initialData?: Partial<Model>) {
        this._asyncActionsPromiseChain = this._asyncActionsPromiseChain
            .then(async () => {
                if (initialData) {
                    // Composing the model
                    const model = this._composeModel(initialData);

                    // Saving the model
                    const createdModel = await this._createApi(model);

                    // Reporting initialized
                    this._onRemoteChange(Adapter.TYPE.initialize, createdModel);

                    // Connecting pusher (if needed)
                    await this._connectPusher();

                    return;
                }

                // Getting the model
                // The retry logic is necessary to handle cases when we're requesting model that's
                // being written to database at the moment so the server may not know about its existence yet
                let data: Model = null;
                let apiFetchAttemptsLeft = 5;
                while (true) {
                    try {
                        data = await this._getApi();
                        break;
                    } catch (err) {
                        if (err instanceof ShouldUseFallbackAdapterError) {
                            logger.info(`[ApiAdapter] _initialize() _getApi() requested to use fallback adapter, switching to fallback adapter`, { id: this.id });
                            this._switchToFallbackAdapter();
                            return;
                        }

                        // 404 means record not found so it's possible it's being written to Mongo at the moment, will retry
                        if (err.status === 404 && apiFetchAttemptsLeft > 0) {
                            await new Promise(resolve => setTimeout(resolve, 1000));
                            apiFetchAttemptsLeft--;
                            continue;
                        }

                        logger.error(err, `[ApiAdapter] _initialize() _getApi() request failed`, { id: this.id });
                        throw err;
                    }
                }

                // Reporting initialized
                this._onRemoteChange(Adapter.TYPE.initialize, data);
                // Connecting pusher (if needed)
                await this._connectPusher();
            })
            .catch(err => {
                // NOTE: Since initialize() is called from a sync function
                // we should report errors by calling onRemoteError()
                this._onRemoteError(err);
            });
    }

    protected async _connectPusher() {
        if (!this.connected) {
            // NOTE: this may happen if disconnect() was called before initialization has finished
            return;
        }

        if (!this.autoSync) {
            return;
        }

        const pusherChannel = await pusher.subscribe(this._getPusherChannelId());
        if (pusherChannel) {
            if (!this.connected) {
                // Will unsubscribe if only nobody else is using the channel (e.g. another model instance)
                if (!pusherChannel.isInUse) {
                    pusher.unsubscribe(pusherChannel.name);
                }
                return;
            }

            this._pusherChannelUnbind = pusherChannel.bindChunked(PusherEventType.DATA_RECORD_UPDATED, this._onPusherEvent);
            this._pusherChannel = pusherChannel;
        }
    }

    protected async _createApi(model: Model): Promise<Model> {
        throw new Error("Not implemented");
    }

    protected async _updateApi(changesetUpdates: Partial<Model>, changesetOriginal: Partial<Model>): Promise<Partial<Model>> {
        throw new Error("Not implemented");
    }

    protected async _getApi(): Promise<Model> {
        throw new Error("Not implemented");
    }

    protected _getPusherChannelId(): string {
        throw new Error("Not implemented");
    }

    protected _composeModel(baseModel: Partial<Model>): Model {
        throw new Error("Not implemented");
    }

    protected _switchToFallbackAdapter(): void {
        throw new Error("Not implemented");
    }
}

export default ApiAdapter;
