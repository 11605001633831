import React, { Component } from "react";
import styled from "styled-components";

import { Tooltip as MaterialTooltip, Popper } from "@material-ui/core";

import { themeColors } from "js/react/sharedStyles";

import { Icon } from "./Icon";

const StyledPopper = styled(Popper)`
    &&& {
        .MuiTooltip-tooltip {
            background: white;
            color: #111;
            font-family: Source Sans Pro;
            font-weight: 500;
            font-size: 14px;
            border: solid 1px ${themeColors.ui_blue};
            border-radius: 4px;
            padding: 10px 16px;
            box-shadow: 2px 2px 5px rgba(0, 0, 0, .2);
            max-width: 400px;
            z-index: 10;

            p+p {
                margin-top: 8px;
            }

            .MuiTooltip-arrow {
                z-index: 1;
                width: 10px;
                height: 10px;
                background: white;
                color: transparent;
                transform: rotate(45deg);
                transform-origin: center;
            }
        }

        .MuiTooltip-tooltipPlacementBottom .MuiTooltip-arrow {
            position: absolute;
            margin-top: -6px;
            border-top: 1px solid ${themeColors.ui_blue};
            border-left: 1px solid ${themeColors.ui_blue};
        }

        .MuiTooltip-tooltipPlacementTop .MuiTooltip-arrow {
            position: absolute;
            bottom: 4px;
            border-bottom: 1px solid ${themeColors.ui_blue};
            border-right: 1px solid ${themeColors.ui_blue};
        }
    }
`;

const TooltipIconContainer = styled.div`
    background: white;
    font-size: 12px;
    border: solid 1px ${themeColors.ui_blue};
    border-radius: 50%;
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${themeColors.ui_blue};
    cursor: pointer;
    pointer-events: auto;

    .MuiIcon-root {
        font-size: 11px;
        font-weight: 500;
    }

    span.bai-icon {
        margin-right: 0;
    }
`;

export class ToolTip extends Component {
    render() {
        const { children, title, placement = "bottom", ...rest } = this.props;

        if (title) {
            return (
                <MaterialTooltip
                    title={title}
                    placement={placement}
                    arrow
                    PopperComponent={StyledPopper}
                    {...rest}
                >
                    <span>
                        {children}
                    </span>
                </MaterialTooltip>
            );
        } else {
            return (<>{children}</>);
        }
    }
}

export class ToolTipBadge extends Component {
    render() {
        return (
            <ToolTip title={this.props.children} placement="bottom">
                <TooltipIconContainer className="tooltip-badge">
                    <Icon blue size={9}>question_mark</Icon>
                </TooltipIconContainer>
            </ToolTip>
        );
    }
}

export class DisabledStateToolTip extends Component {
    render() {
        const { children, disabled, disabledTooltip, ...rest } = this.props;

        if (disabled && disabledTooltip) {
            return (
                <ToolTip title={disabledTooltip} {...rest}>
                    <div style={{ padding: 0 }}>{children}</div>
                </ToolTip>
            );
        } else {
            return children;
        }
    }
}

