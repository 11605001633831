import { Icon, IconButton, MenuItem } from "@material-ui/core";
import React, { Component, Fragment } from "react";
import styled from "styled-components";

import { PERMISSION_RESOURCE_TYPE, PresentationFilters } from "common/constants";
import { FeatureType } from "common/features";
import PresentationLibraryController from "js/controllers/PresentationLibraryController";
import { TeamFoldersController } from "js/core/dataServices/TeamFoldersDataService";
import getLogger from "js/core/logger";
import { ds } from "js/core/models/dataService";
import { getPresentation } from "js/core/models/presentation";
import shouldShowProBadge from "js/core/utilities/shouldShowProBadge";
import { trackActivity } from "js/core/utilities/utilities";
import { app } from "js/namespaces";
import {
    ShowConfirmationDialog,
    ShowDialog,
    ShowErrorDialog,
    ShowInputDialog,
    ShowSnackBar,
    ShowUpgradeDialog,
    ShowWarningDialog
} from "js/react/components/Dialogs/BaseDialog";
import InputConfirmationDialog from "js/react/components/Dialogs/InputConfirmationDialog";
import { FlexSpacer, Gap20 } from "js/react/components/Gap";
import { FlexBox } from "js/react/components/LayoutGrid";
import ProBadge from "js/react/components/ProBadge";
import Spinner from "js/react/components/Spinner";
import { Divider, IconMenu } from "js/react/components/UiComponents";
import { themeColors } from "js/react/sharedStyles";
import { UpgradePlanDialogType } from "js/react/views/MarketingDialogs/UpgradePlanDialog";
import {
    AddPresentationToFolderDialog,
    ManageTeamFolderMembersDialog,
} from "js/react/views/TeamResources/dialogs/TeamFolderDialogs";
import { $, _ } from "js/vendor";

const logger = getLogger();

export const SideBarItem = styled.div`
    width: 100%;
    color: #444;
    font-size: 14px;
    letter-spacing: 0px;
    font-weight: 600;
    display: flex;
    flex-shrink: 0;
    margin-bottom: 0px;
    align-items: flex-start;
    overflow: hidden;
    padding: 12px 10px;
    transition: background-color 300ms;
    background: none;
    cursor: pointer;

    & > .material-icons {
        font-size: 18px;
        margin-right: 10px;
        color: #666;
    }

    //.hover was added to fix a safari issue when hovering over a SideBarItem while dragging a presentation
    &:hover,
    &.hover {
        background: ${themeColors.hoverBlue};
    }

    &.selected {
        cursor: default;
        background: ${themeColors.lightBlue};

        & > .material-icons {
            color: ${themeColors.ui_blue};
        }

        //.hover was added to fix a safari issue when hovering over a SideBarItem while dragging a presentation
        &:hover,
        &.hover {
            background: ${themeColors.lightBlue};
        }
    }

    .folder-name {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .dark-mode & {
        color: #ccc;
        background: none;

        & > .material-icons {
            color: #aaa;
        }

        //.hover was added to fix a safari issue when hovering over a SideBarItem while dragging a presentation
        &:hover {
            background: #555;
        }

        &.selected {
            background: #333;

            & > .material-icons {
                color: ${themeColors.ui_blue};
            }

            //.hover was added to fix a safari issue when hovering over a SideBarItem while dragging a presentation
            &:hover {
                background: #333;
            }
        }
    }
`;

const FolderContainer = styled.div`
  width: 100%;
  //overflow-y: auto;
  //min-height: 130px;
  position: relative;
`;

const FolderLabel = styled.div`
    padding: 0 8px;
`;

const SideBarLabel = styled.div`
    width: 100%;
    font-size: 12px;
    display: flex;
    align-items: center;
    text-transform: uppercase;

    color: ${themeColors.mediumGray};
    padding: 6px 0px;

    .dark-mode & {
        color: #bbb;
    }
`;

const LibraryFolder = styled(SideBarItem)`
    padding: 6px 0px;
    align-items: center;

    .MuiIconButton-root {
        padding: 0px 0px;
        opacity: 0;
    }

    .expand-arrow {
        font-size: 24px;
        margin-right: 0;
    }

    &:hover {
        .MuiIconButton-root {
            opacity: 1;
        }
    }
`;

const SubLibraryFolder = styled(LibraryFolder)`
    margin-left: 40px;
    width: calc(100% - 40px);
`;

const StyledFlexBox = styled(FlexBox)`
    height: ${props => props.$canEditLibraryItems ? `calc(100% - 90px)`
        : `calc(100% - 40px)`};
`;

const StyledTeamFolders = styled.div`
    overflow: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const TrashSideBarContainer = styled.div`
    width: 100%;
    background-color: #ffffff;
`;

const StyledAddIcon = styled(Icon)`
    &&& {
        font-size: 20px;
    }
`;

// NOTE: We have to use `className="selected"` instead of `props.selected`
//   because a bug with `styled and` the application of `.dark-mode`
function selectedClass(filter) {
    return filter ? "selected" : "";
}

export class PresentationLibrarySideBar extends Component {
    state = {
        isTeamFoldersExpanded: true,
        isPersonalFoldersExpanded: true
    }

    handleSelectFilter = (type, folderId) => {
        this.props.onSetFilter({ type, folderId });
    }

    handleFolderMouseUp = async folderId => {
        const { draggedItems, onSetFilter } = this.props;

        const presentationIds = draggedItems.map(p => p.id);

        if (presentationIds.length === 0) {
            onSetFilter({ type: PresentationFilters.FOLDER, folderId });
            return;
        }

        try {
            // Recover any presentations that are in the trash before adding them to a folder.
            if (draggedItems.some(item => item.trashedAt)) {
                await PresentationLibraryController.recoverPresentations(draggedItems.filter(item => item.trashedAt).map(item => item.id));
            }

            /////// Old code below, must be refactored in the future ///////

            // Logic to remove presentation(s) from existing folders
            ds.userFolders.forEach(folder => {
                const folderPresentationIds = folder.get("presentations");
                if (folderPresentationIds?.length) {
                    folderPresentationIds.forEach(folderPresentationId => {
                        if (presentationIds.includes(folderPresentationId) && folder.id !== folderId) {
                            folder.removePresentationFromFolder(folderPresentationId);
                        }
                    });
                }
            });

            const folder = ds.userFolders.get(folderId);

            // Add presentation(s) to target folder.
            const folderPresentationIds = folder.get("presentations") ?? [];

            let movedPresentationCount = 0;
            for (const presentationId of presentationIds) {
                if (!folderPresentationIds.includes(presentationId)) {
                    folder.addPresentationToFolder(presentationId);
                    movedPresentationCount++;
                }
            }

            ///////////////////////////////////////////////////////////////

            if (movedPresentationCount === 0) {
                throw new Error("The selected presentations are already in this folder.");
            }

            let snackbar;
            const handleClick = () => {
                onSetFilter({ type: PresentationFilters.FOLDER, folderId });
                snackbar.close();
            };
            snackbar = ShowSnackBar({
                message: (
                    <p>
                        <span>{movedPresentationCount} {(movedPresentationCount > 1) ? " presentations were added to " : " presentation was added to "}</span>
                        <span className="action_item" onClick={handleClick}>{folder.get("name")}</span>
                    </p>
                ),
            });
        } catch (err) {
            ShowErrorDialog({
                title: "Unable to move presentations into folder",
                message: err.message
            });
        }
    }

    handleTeamFolderMouseUp = async (folderId, subFolderId) => {
        const { draggedItems, onSetFilter, workspaceId } = this.props;

        if (draggedItems.length === 0) {
            onSetFilter({ type: PresentationFilters.TEAM_FOLDER, folderId, subFolderId });
            return;
        }

        const folder = ds.teams.get(folderId);
        const isDefault = folder.get("isDefault");
        const canModifyDefaultFolder = app.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_MODIFY_ALL_TEAM_MEMBERS_FOLDER, workspaceId);

        if (
            isDefault &&
            !canModifyDefaultFolder
        ) {
            ShowWarningDialog({
                title: `Only Librarians and Owners can add and remove presentations from the All Team Members folder.`
            });
            return;
        }

        const folderPresentationIds = Object.keys(folder.get("sharedResources")?.[PERMISSION_RESOURCE_TYPE.PRESENTATION] || {});

        const presentationsNotInFolder = draggedItems.filter(presentation => !folderPresentationIds.includes(presentation.id));
        if (presentationsNotInFolder.length === 0) {
            return;
        }

        const presentations = await Promise.all(presentationsNotInFolder.map(p => getPresentation(p.id, { permission: "write", autoSync: true })));

        ShowDialog(AddPresentationToFolderDialog, {
            folder,
            presentations,
            callback: async () => {
                if (subFolderId) {
                    await TeamFoldersController.addPresentationsToTeamSubFolder(
                        presentations,
                        folderId,
                        subFolderId,
                        () => {
                            onSetFilter({
                                type: PresentationFilters.TEAM_FOLDER,
                                folderId: folderId,
                                subFolderId
                            });
                        }
                    );
                } else {
                    await TeamFoldersController.removePresentationsFromAllTeamSubFolders(presentations, folderId);
                }

                presentations.forEach(p => p.disconnect());
            }
        });
    }

    handleTrashMouseUp = () => {
        let presentations = this.props.draggedItems;
        const { filter } = this.props;

        if (presentations.length) {
            PresentationLibraryController.trashPresentations(presentations.map(p => p.id));
        } else {
            this.props.onSetFilter({ type: PresentationFilters.TRASH });
        }
    }

    handleCreateFolder = () => {
        const { workspaceId } = this.props;

        this.setState({ isPersonalFoldersExpanded: true });

        if (!app.user.features.isFeatureEnabled(FeatureType.FOLDERS, workspaceId)) {
            ShowUpgradeDialog({
                type: UpgradePlanDialogType.UPGRADE_PLAN,
                analytics: { cta: "Folder" },
                workspaceId
            });
            return;
        }

        if (ds.userFolders.length > 49) {
            ShowWarningDialog({
                title: "Oops!",
                message: "You have reached the folder limit!",
            });
            return;
        }

        ShowDialog(InputConfirmationDialog, {
            title: "Create Folder",
            input: {
                type: "text",
                placeholder: "Type folder name...",
                maxLength: "50",
                id: "create_folder_input",
                inputProps: {
                    maxLength: 50
                }
            },
            acceptCallback: async value => {
                let folderName = value;
                const orgId = workspaceId == "personal" ? undefined : workspaceId;

                if (folderName.length && !ds.userFolders.find({ name: folderName })) {
                    // StorageModel turns null -> undefined, but Backbone doesn't *and* Firebase
                    // doesn't accept undefined field values, so we have to remove the orgId field
                    // entirely instead of setting it to undefined or null
                    const optionalOrgId = orgId === undefined ? {} : { orgId };
                    let folder = await ds.userFolders.createFolder({ name: folderName, ...optionalOrgId });
                    let folder_id = folder.id;

                    trackActivity("Library", "NewFolder", null, null, { folder_id }, { audit: false });
                } else if (folderName.length) {
                    ShowWarningDialog({
                        title: "Unable to create folder",
                        message: "Please enter a unique folder name.",
                    });
                } else {
                    ShowWarningDialog({
                        title: "Unable to create folder",
                        message: "Please enter a folder name.",
                    });
                }
            }
        });
    }

    handleCreateTeamFolder = () => {
        this.setState({ isTeamFoldersExpanded: true });
        ShowDialog(InputConfirmationDialog, {
            title: "Create Team Folder",
            input: {
                type: "text",
                placeholder: "Type folder name...",
                maxLength: "50",
                id: "create_folder_input",
                inputProps: {
                    maxLength: 50
                }
            },
            acceptCallback: async name => {
                try {
                    let folder = await TeamFoldersController.createTeamFolder(name, true);
                    ShowDialog(ManageTeamFolderMembersDialog, {
                        folder,
                        addCurrentUser: true
                    });
                } catch (err) {
                    ShowWarningDialog({ title: "Sorry, we were unable to create the team folder", message: err });
                }
            }
        });
    }

    handleMouseEnter = event => {
        $(event.target).addClass("hover");
    }

    handleMouseLeave = event => {
        $(event.target).removeClass("hover");
    }

    handleToggleTeamFoldersExpand = event => {
        event.stopPropagation();
        this.setState({ isTeamFoldersExpanded: !this.state.isTeamFoldersExpanded });
    }

    handleTogglePersonalFoldersExpand = event => {
        event.stopPropagation();
        this.setState({ isPersonalFoldersExpanded: !this.state.isPersonalFoldersExpanded });
    }

    renderTeamFolders() {
        const { workspaceId, teamFolders, isEveryTeamFolderLoaded, filter, onSetFilter, readOnly } = this.props;
        const { isTeamFoldersExpanded } = this.state;

        const myTeamFolders = teamFolders.filter(folder => {
            if (_.isArray(folder.members)) {
                return folder.members.find(member => member.id === app.user.id);
            } else if (_.isObject(folder.members)) {
                return folder.members[app.user.id];
            } else {
                throw Error(`Unexpected folder.members type: ${JSON.stringify(folder)}`);
            }
        });
        const sortedFolders = _.sortBy(myTeamFolders.filter(folder => folder.orgId == workspaceId), ["isDefault", "name"]);

        if (workspaceId !== "personal") {
            return (
                <Fragment>
                    <SideBarLabel>
                        <Icon onMouseUp={this.handleToggleTeamFoldersExpand}
                            className="expand-arrow">{isTeamFoldersExpanded ? "arrow_drop_down" : "arrow_right"}</Icon>
                        <FolderLabel>Team Folders</FolderLabel>
                        {
                            !readOnly && !app.isConstrained &&
                            <IconButton
                                size="small"
                                id="create-folder-button"
                                onClick={this.handleCreateTeamFolder}
                            >
                                <StyledAddIcon>add</StyledAddIcon>
                            </IconButton>
                        }
                    </SideBarLabel>
                    {isTeamFoldersExpanded && <FolderContainer>
                        {isEveryTeamFolderLoaded
                            ? sortedFolders.map(folder => (
                                <TeamFolder
                                    workspaceId={workspaceId}
                                    key={folder.id}
                                    folder={folder}
                                    onMouseUp={this.handleTeamFolderMouseUp}
                                    onSetFilter={onSetFilter}
                                    filter={filter}
                                />
                            )) : <Spinner />}
                    </FolderContainer>}
                    <Gap20 />
                </Fragment>
            );
        }
    }

    renderFolders() {
        let { workspaceId, userFolders, readOnly, filter, onSetFilter } = this.props;
        const { isPersonalFoldersExpanded } = this.state;

        const orgId = workspaceId == "personal" ? undefined : workspaceId;

        if (userFolders.models) {
            userFolders = userFolders.sortBy(folder => folder.get("name").toLowerCase());
        }

        return (
            <Fragment>
                <SideBarLabel>
                    <Icon onMouseUp={this.handleTogglePersonalFoldersExpand}
                        className="expand-arrow">{isPersonalFoldersExpanded ? "arrow_drop_down" : "arrow_right"}</Icon>
                    <FolderLabel>My Folders</FolderLabel>
                    {
                        !readOnly && !app.isConstrained &&
                        <IconButton
                            size="small"
                            id="create-folder-button"
                            onClick={this.handleCreateFolder}
                        >
                            <StyledAddIcon>add</StyledAddIcon>
                        </IconButton>
                    }
                    <ProBadge
                        workspaceId={workspaceId}
                        show={shouldShowProBadge(FeatureType.FOLDERS, workspaceId)}
                        analytics={{ cta: "Folders" }}
                    />
                </SideBarLabel>
                {isPersonalFoldersExpanded && <FolderContainer>
                    {
                        userFolders.map(folderModel => {
                            if (folderModel.get("orgId") == orgId) {
                                return (
                                    <UserFolder
                                        key={folderModel.id}
                                        className={selectedClass(filter.type == PresentationFilters.FOLDER && filter.folderId == folderModel.id)}
                                        folder={folderModel}
                                        onMouseUp={() => this.handleFolderMouseUp(folderModel.id)}
                                        readOnly={readOnly}
                                        onSetFilter={onSetFilter}
                                    />
                                );
                            }
                        })
                    }
                </FolderContainer>}
            </Fragment>
        );
    }

    render() {
        const { readOnly, filter, canEditLibraryItems } = this.props;

        return (
            <>
                <StyledFlexBox column
                    className="library-sidebar"
                    verticalAlign="start"
                    horizontalAlign="start"
                    $canEditLibraryItems={canEditLibraryItems}
                >
                    <SideBarItem
                        className={selectedClass(filter.type == PresentationFilters.ALL_PRESENTATIONS)}
                        onClick={() => this.handleSelectFilter(PresentationFilters.ALL_PRESENTATIONS)}
                        id="all-presentations"
                    >
                        <Icon>select_all</Icon>
                        All Presentations
                    </SideBarItem>
                    <SideBarItem
                        className={selectedClass(filter.type == PresentationFilters.RECENT)}
                        onClick={() => this.handleSelectFilter(PresentationFilters.RECENT)}
                        id="rect"
                    >
                        <Icon>history</Icon>
                        Recent Presentations
                    </SideBarItem>
                    <SideBarItem
                        className={selectedClass(filter.type == PresentationFilters.OWNED_BY_ME)}
                        onClick={() => this.handleSelectFilter(PresentationFilters.OWNED_BY_ME)}
                        id="owned-by-me"
                    >
                        <Icon>person_pin</Icon>
                        Created By Me
                    </SideBarItem>
                    <SideBarItem
                        className={selectedClass(filter.type == PresentationFilters.SHARED_WITH_ME)}
                        onClick={() => this.handleSelectFilter(PresentationFilters.SHARED_WITH_ME)}
                        id="shared-with-me"
                    >
                        <Icon>share</Icon>
                        Shared With Me
                    </SideBarItem>
                    <Divider margin={10} />
                    <StyledTeamFolders>
                        {this.renderTeamFolders()}
                        {this.renderFolders()}
                    </StyledTeamFolders>
                </StyledFlexBox>

                {
                    !readOnly &&
                    !app.isConstrained &&
                    <TrashSideBarContainer>
                        <Divider margin={0} />
                        <SideBarItem
                            className={selectedClass(filter.type == PresentationFilters.TRASH)}
                            onMouseUp={() => this.handleTrashMouseUp()}
                            id="trash"
                        >
                            <Icon>delete</Icon>
                            Trash
                        </SideBarItem>
                    </TrashSideBarContainer>
                }
            </>
        );
    }
}

class TeamFolder extends Component {
    state = {
        anchorEl: null,
        canManage: false,
        isExpanded: false
    }

    constructor(props) {
        super(props);
        this.ref = React.createRef();

        this.state.canManagePermissions = !(this.props.folder.onlyOwnerCanEdit ?? true) || app.user.features.isFeatureEnabled(FeatureType.MANAGE_TEAM, props.workspaceId);
        this.state.canManageFully = app.user.features.isFeatureEnabled(FeatureType.MANAGE_TEAM, props.workspaceId);
    }

    handleShowMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    }

    handleCloseMenu = () => {
        this.setState({ anchorEl: null });
    }

    handleEditFolder = () => {
        const { folder } = this.props;

        if (folder.isDefault) {
            return ShowWarningDialog({
                title: "Sorry, sharing on this folder cannot be modified.",
                message: "The All Team Members folder is shared with all members in your team and cannot be modified or deleted."
            });
        }

        ShowDialog(ManageTeamFolderMembersDialog, {
            folder,
            onRemovedSelf: () => {
                this.props.onSetFilter(PresentationFilters.ALL_PRESENTATIONS);
            }
        });
        this.handleCloseMenu();
    }

    handleRenameFolder = async () => {
        const { folder } = this.props;

        if (folder.isDefault) {
            return ShowWarningDialog({
                title: "Sorry, this folder cannot be modified.",
                message: "The All Team Members folder is shared with all members in your team and cannot be modified or deleted."
            });
        }

        const name = await ShowInputDialog({
            title: "Rename Team Folder",
            maxChars: 50,
            value: folder.name
        });

        if (name) {
            try {
                TeamFoldersController.renameTeamFolder(folder, name);
            } catch (err) {
                ShowErrorDialog({
                    title: "Unable to rename folder",
                    message: err.message
                });
            }
        }
        this.handleCloseMenu();
    }

    handleCreateSubFolder = async () => {
        const { folder } = this.props;

        const name = await ShowInputDialog({
            title: "New folder",
            maxChars: 100,
            value: "New Folder"
        });

        if (name) {
            try {
                TeamFoldersController.createSubFolder(folder, name);
            } catch (err) {
                ShowErrorDialog({
                    title: "Unable to rename folder",
                    message: err.message
                });
            }
        }
        this.handleCloseMenu();
    }

    handleLeaveFolder = async () => {
        const { folder } = this.props;

        if (folder.isDefault) {
            return ShowWarningDialog({
                title: "Sorry, this folder cannot be modified.",
                message: "The All Team Members folder is shared with all members in your team and cannot be modified or deleted."
            });
        }

        if (await ShowConfirmationDialog({
            title: "Are you sure you want to leave this team folder?",
            message: "You may no longer have access to any presentations or data shared in this folder. You will not be able rejoin the team folder unless a team owner or librarian invites you."
        })) {
            this.props.onSetFilter(PresentationFilters.ALL_PRESENTATIONS);
            await TeamFoldersController.removeMembersFromTeamFolder(folder, [app.user.id], true);
        }
    }

    handleDeleteFolder = async () => {
        const { folder } = this.props;

        if (folder.isDefault) {
            return ShowWarningDialog({
                title: "Sorry, this folder cannot be modified.",
                message: "The All Team Members folder is shared with all members in your team and cannot be modified or deleted."
            });
        }

        if (await ShowConfirmationDialog({
            title: "Are you sure you want to delete this shared team folder?",
            message: <Fragment>
                <p>Presentations added to this team folder will NOT be deleted but team members who were added to this
                    folder may no longer have access to the presentations in the folder.</p>
                <br />
                <p>You cannot undo this action.</p>
            </Fragment>
        })) {
            try {
                await TeamFoldersController.deleteTeamFolder(folder);
                this.props.onSetFilter({ type: PresentationFilters.ALL_PRESENTATIONS });
            } catch (err) {
                if (err) {
                    logger.error(err, "PresentationLibrarySideBar TeamFoldersController.deleteTeamFolder() failed", { folderId: folder.id });
                    ShowErrorDialog({
                        error: "Failed to delete team folder",
                        message: "Something went wrong. Please try again and reach out to support@beautiful.ai if the issue persists."
                    });
                }
            }
        }
        this.handleCloseMenu();
    }

    handleMouseEnter = () => {
        $(this.ref.current).addClass("hover");
    }

    handleMouseLeave = () => {
        $(this.ref.current).removeClass("hover");
    }

    handleToggleExpand = event => {
        event.stopPropagation();
        this.setState({ isExpanded: !this.state.isExpanded });
    }

    render() {
        const { canManagePermissions, canManageFully, isExpanded } = this.state;
        const { folder, readOnly, onMouseUp, filter, onSetFilter } = this.props;

        return (
            <Fragment>
                <LibraryFolder
                    data-attr={folder.name}
                    ref={this.ref}
                    className={selectedClass(filter.type === PresentationFilters.TEAM_FOLDER && filter.folderId === folder.id && !filter.subFolderId)}
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                    onMouseUp={() => onMouseUp(folder.id, null)}>
                    {folder.subFolders?.length > 0 ? <Icon onMouseUp={this.handleToggleExpand}
                        className="expand-arrow">{isExpanded ? "arrow_drop_down" : "arrow_right"}</Icon>
                        : <Icon className="expand-arrow" />}
                    <Icon>folder_shared</Icon>
                    <div className="folder-name">
                        {folder.isDefault ? "All Team Members" : folder.name}
                    </div>
                    <FlexSpacer />
                    {
                        !readOnly &&
                        <IconMenu icon="more_vert">
                            <MenuItem divider onClick={this.handleEditFolder}>
                                <Icon>people_add</Icon>
                                {canManagePermissions ? "Share Team Folder..." : "View Sharing..."}
                            </MenuItem>
                            {(canManagePermissions || canManageFully) && !app.isConstrained && !folder.isDefault &&
                                <MenuItem divider onClick={this.handleRenameFolder}>
                                    <Icon>label</Icon>
                                    Rename Team Folder...
                                </MenuItem>
                            }
                            {!app.isConstrained &&
                                <MenuItem divider onClick={this.handleCreateSubFolder}>
                                    <Icon>folder_open</Icon>
                                    Create SubFolder...
                                </MenuItem>
                            }
                            {canManageFully && !app.isConstrained && !folder.isDefault &&
                                <MenuItem onClick={this.handleDeleteFolder}>
                                    <Icon>delete</Icon>
                                    Delete Team Folder
                                </MenuItem>
                            }
                            {!(canManagePermissions && canManageFully) && !folder.isDefault &&
                                <MenuItem onClick={this.handleLeaveFolder}>
                                    <Icon>logout</Icon>
                                    Leave Team Folder
                                </MenuItem>
                            }
                        </IconMenu>
                    }
                </LibraryFolder>
                {isExpanded && _.sortBy(folder.subFolders || [], subFolder => subFolder.name).map(subFolder => (
                    <TeamSubFolder
                        key={subFolder.id}
                        folder={folder}
                        subFolder={subFolder}
                        readOnly={readOnly}
                        canManageFully={canManageFully}
                        onMouseUp={() => onMouseUp(folder.id, subFolder.id)}
                        className={selectedClass(filter.type == PresentationFilters.TEAM_FOLDER && filter.folderId == folder.id && filter.subFolderId == subFolder.id)}
                        onSetFilter={onSetFilter}
                    />
                ))}
            </Fragment>
        );
    }
}

class TeamSubFolder extends Component {
    state = {
        anchorEl: null
    }

    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    handleShowMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    }

    handleCloseMenu = () => {
        this.setState({ anchorEl: null });
    }

    handleRenameFolder = async () => {
        let { folder, subFolder } = this.props;
        let name = await ShowInputDialog({
            title: "Rename Folder",
            maxChars: 100,
            value: subFolder.name
        });

        if (name) {
            try {
                TeamFoldersController.renameSubFolder(folder.id, subFolder.id, name);
            } catch (err) {
                ShowErrorDialog({
                    title: "Unable to rename folder",
                    message: err.message
                });
            }
        }
        this.handleCloseMenu();
    }

    handleDeleteFolder = async () => {
        let { folder, subFolder } = this.props;
        try {
            if (subFolder.presentations?.length) {
                if (await ShowConfirmationDialog({
                    title: "Are you sure you want to delete this folder?",
                    message: <p>Presentations in the folder will be moved into the root team folder and will <strong>not
                        be deleted.</strong></p>
                }) == false) {
                    return;
                }
            }

            TeamFoldersController.deleteSubFolder(folder.id, subFolder.id);
            this.props.onSetFilter({ type: PresentationFilters.TEAM_FOLDER, folderId: folder.id });
        } catch (err) {
            ShowErrorDialog({
                title: "Unable to delete folder",
                message: err.message
            });
        }
        this.handleCloseMenu();
    }

    handleMouseEnter = () => {
        $(this.ref.current).addClass("hover");
    }

    handleMouseLeave = () => {
        $(this.ref.current).removeClass("hover");
    }

    render() {
        let { subFolder, readOnly, canManageFully, onMouseUp, className } = this.props;

        return (
            <SubLibraryFolder
                data-attr={subFolder.name}
                ref={this.ref}
                className={className}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                onMouseUp={onMouseUp}
                onTouchEnd={onMouseUp}
            >
                <Icon>folder_open</Icon>
                <div className="folder-name">{subFolder.name}</div>

                <FlexSpacer />

                {
                    !readOnly && !app.isConstrained &&
                    <IconMenu icon="more_vert">
                        <MenuItem onClick={this.handleRenameFolder}>
                            <Icon>label</Icon>
                            Rename Folder
                        </MenuItem>
                        <hr />
                        <MenuItem onClick={this.handleDeleteFolder}>
                            <Icon>delete</Icon>
                            Delete Folder
                        </MenuItem>
                    </IconMenu>
                }
            </SubLibraryFolder>
        );
    }
}

class UserFolder extends Component {
    state = {
        anchorEl: null
    }

    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    handleShowMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    }

    handleCloseMenu = () => {
        this.setState({ anchorEl: null });
    }

    handleRenameFolder = async () => {
        const { folder } = this.props;

        const name = await ShowInputDialog({
            title: "Rename Folder",
            maxChars: 50,
            value: folder.get("name")
        });

        if (!name || folder.get("name") === name) {
            this.handleCloseMenu();
            return;
        }

        try {
            if (ds.userFolders.find({ name })) {
                throw new Error("Please enter a unique folder name.");
            }

            folder.update({ name });
            await folder.updatePromise;
        } catch (err) {
            ShowErrorDialog({
                title: "Unable to rename folder",
                message: err.message
            });
        }

        this.handleCloseMenu();
    }

    handleDeleteFolder = async () => {
        const { onSetFilter, folder } = this.props;

        try {
            if (folder.get("presentations")?.length) {
                throw new Error("This folder contains presentations and can't be deleted.");
            }

            await folder.destroy();

            onSetFilter({ type: PresentationFilters.ALL_PRESENTATIONS });
        } catch (err) {
            ShowErrorDialog({
                title: "Unable to delete folder",
                message: err.message
            });
        }

        this.handleCloseMenu();
    }

    handleMouseEnter = () => {
        $(this.ref.current).addClass("hover");
    }

    handleMouseLeave = () => {
        $(this.ref.current).removeClass("hover");
    }

    render() {
        let { folder, readOnly, selected, onMouseUp, className } = this.props;

        return (
            <LibraryFolder
                data-attr={folder.get("name")}
                ref={this.ref}
                selected={selected}
                className={className}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                onMouseUp={onMouseUp}
                onTouchEnd={onMouseUp}
            >
                {/**
                 * Adding expand-arrow so we can control the gap, we should think of refactoring <SideBarItem/>
                 * that is being used in multiple places and then we can remove this className
                 */}
                <Icon className="expand-arrow" />
                <Icon>folder</Icon>
                <div className="folder-name"> {folder.get("name")}</div>

                <FlexSpacer />

                {
                    !readOnly && !app.isConstrained &&
                    <IconMenu icon="more_vert">
                        <MenuItem onClick={this.handleRenameFolder}>
                            <Icon>label</Icon>
                            Rename Folder
                        </MenuItem>
                        <hr />
                        <MenuItem onClick={this.handleDeleteFolder}>
                            <Icon>delete</Icon>
                            Delete Folder
                        </MenuItem>
                    </IconMenu>
                }
            </LibraryFolder>
        );
    }
}
