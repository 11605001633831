import { tinycolor, _ } from "../../vendor";
import { ForeColorType, PaletteColorType, BackgroundStyleType, DecorationStyle, HiliteType } from "common/constants";
import { blendColors } from "js/core/utilities/utilities";
import getLogger, { LogGroup } from "js/core/logger";

const logger = getLogger(LogGroup.CANVAS);

const BSS_COLOR_REGEX = /^\s*(rgba?\(\s*\d+\s*,\s*\d+\s*,\s*\d+\s*(,\s*\d+(\.\d+)?\s*)?\)|\S+)(\s+.*)?$/;

export function findDecorationBackgroundInColorSet(colorSet, uniquePath) {
    for (let id of uniquePath.split("/").reverse()) {
        if (colorSet[id]?.decorationColor) {
            return colorSet[id];
        }
    }
    return { decorationColor: null, decorationId: null };
}

class Palette {
    constructor(theme) {
        this.theme = theme;

        // palette migration defaults - MJG 6/20
        if (!this.colors.hasOwnProperty("chart1")) {
            let colors = { ...this.colors };
            let index = 0;
            for (let color of Object.values(this.getSlideColors())) {
                colors["chart" + ++index] = color;
            }
            this.theme.set("colors", colors, { silent: true });
        }
        if (!this.colors.hasOwnProperty(PaletteColorType.BACKGROUND_ACCENT)) {
            let blendColor = tinycolor(this.colors[PaletteColorType.THEME]).setAlpha(0.1);
            let accentColor = blendColors(blendColor, tinycolor("white")).toRgbString();
            this.theme.set("colors", {
                ...this.colors,
                [PaletteColorType.BACKGROUND_ACCENT]: accentColor
            }, { silent: true });
        }

        if (!this.colors.hasOwnProperty(PaletteColorType.POSITIVE)) {
            this.theme.set("colors", { ...this.colors, [PaletteColorType.POSITIVE]: "#54C351" }, { silent: true });
        }
        if (!this.colors.hasOwnProperty(PaletteColorType.NEGATIVE)) {
            this.theme.set("colors", { ...this.colors, [PaletteColorType.NEGATIVE]: "#E04C2B" }, { silent: true });
        }

        if (!this.colors.hasOwnProperty(PaletteColorType.HYPERLINK)) {
            this.theme.set("colors", { ...this.colors, [PaletteColorType.HYPERLINK]: "#11a9e2" }, { silent: true });
        }
    }

    get colors() {
        return this.theme.get("colors") || {};
    }

    isRawColor(color) {
        return tinycolor(color).isValid();
    }

    // return {
    //     name: "none",
    //     toHexString: function() {
    //         return "none";
    //     },
    //     toRgbString: function() {
    //         return "none";
    //     },
    //     isDark: function() {
    //         return false;
    //     },
    //     darken: function() {
    //     },
    //     lighten: function() {
    //     },
    //     clone: function() {
    //         return this;
    //     },
    //     setAlpha: function() {
    //         return this;
    //     },
    //     getAlpha: function() {
    //         return 1;
    //     }
    //
    // };

    getColor(color, backgroundColor, options = {}) {
        let { colorMods = [], itemIndex = 0, allowColorOnColor = false, allowForeColorToMatchBackgroundColor = false, canvasBackgroundColor, forceWhiteForPrimaryOnColor } = options;

        if (!color) {
            return null;
        }

        if (color == "none") {
            return tinycolor("transparent");
        }

        if (color == ForeColorType.PRIMARY) {
            // if (allowColorOnColor) {
            //     return this.getColor(PaletteColorType.PRIMARY_DARK);
            // }

            if (!backgroundColor) {
                return this.getColor(PaletteColorType.PRIMARY_DARK);
                // throw new Error("Primary color requires a background color");
            }
            // i think there might be an issue here because im not passing the canvas background color to isDark() in case the backgroundColor has transparency
            if (backgroundColor.isDark(canvasBackgroundColor) || (backgroundColor.isColor && forceWhiteForPrimaryOnColor)) {
                return this.getColor(PaletteColorType.PRIMARY_LIGHT);
            } else {
                return this.getColor(PaletteColorType.PRIMARY_DARK);
            }
        } else if (color == ForeColorType.SECONDARY) {
            if (backgroundColor.isDark(canvasBackgroundColor) || (backgroundColor.isColor && forceWhiteForPrimaryOnColor)) {
                return this.getColor(PaletteColorType.SECONDARY_LIGHT);
            } else {
                return this.getColor(PaletteColorType.SECONDARY_DARK);
            }
        }

        let colorObj;

        if (color instanceof tinycolor) {
            colorObj = color.clone();
            colorObj.name = color.name;
        } else {
            if (typeof color == "object") {
                // protect against a bad model state where a tinycolor was converted to a model object
                logger.error("Invalid color object");
                return this.getColor(PaletteColorType.THEME);
            }

            if (!color.startsWith("rgb")) {
                colorMods = color.split(" ").slice(1);
                color = color.split(" ")[0];
            }

            if (this.colors.hasOwnProperty(color)) {
                colorObj = tinycolor(this.colors[color]);
                colorObj.name = color;
            } else if (color.startsWith("accent")) {
                // if we are referring to a removed accent color, return theme
                colorObj = tinycolor(this.colors["theme"]);
                colorObj.name = "theme";
            } else if (color.startsWith("chart")) {
                // Roll the chart color over if we don't have enough colors
                let chartColors = Object.keys(this.colors)
                    .filter(name => name.startsWith("chart"))
                    .sort((nameA, nameB) => nameA < nameB ? -1 : 1)
                    .map(name => this.colors[name]);
                let index = (parseInt(color.split("chart")[1]) - 1);
                index %= chartColors.length;
                let chartColor = chartColors[index];
                colorObj = tinycolor(chartColor);
            } else if (color == "neutral") {
                colorObj = tinycolor("white");
                colorObj.name = "neutral";
            } else if (color == ForeColorType.COLORFUL) {
                colorObj = this.getColorfulColor(itemIndex);
            } else if (color == "slide") {
                logger.warn("Error requesiong slide color");
                colorObj = this.getColor(PaletteColorType.THEME);
            } else {
                colorObj = tinycolor(color);
                colorObj.name = color;
            }
            colorObj.isColor = this.isColor(colorObj);
        }

        this.applyColorMods(colorObj, colorMods);

        // if the color is not primary or secondary (handled above) and the background is a color, return white
        if (backgroundColor && this.isColor(backgroundColor) && !allowColorOnColor) {
            // if the background color is a color, return white
            let foreColorAlpha = colorObj.getAlpha();
            let white = tinycolor("white").setAlpha(foreColorAlpha);
            white.name = "white";
            return white;
        }

        // check if the color is the same as the background color and return an appropriate color
        if (tinycolor.equals(colorObj, backgroundColor) && backgroundColor.name != "background-image" && !allowForeColorToMatchBackgroundColor) {
            if (colorObj.name == "background_light" && backgroundColor.name == "background_light") {
                // this is a special case that mostly benefits BA-15107
                return this.getColor(PaletteColorType.BACKGROUND_ACCENT);
            } else if (backgroundColor.isDark()) {
                return this.getColor(PaletteColorType.PRIMARY_LIGHT);
            } else {
                return this.getColor(PaletteColorType.PRIMARY_DARK);
            }
        }

        return colorObj;
    }

    isColor(colorObj) {
        if (colorObj.name == "blended") return true;  // special case for blended colors - always treat as a color
        return new Set([colorObj._r, colorObj._g, colorObj._b]).size > 1 && !colorObj.name?.equalsAnyOf(PaletteColorType.BACKGROUND_ACCENT, PaletteColorType.BACKGROUND_DARK, PaletteColorType.BACKGROUND_LIGHT, PaletteColorType.PRIMARY_DARK, PaletteColorType.PRIMARY_LIGHT, "white", "black");
    }

    getColorMods(color) {
        const colorMods = [];

        if (typeof color === "string") {
            // strip and spaces after commas
            color = color.replace(/,\s+/g, ",");

            const results = BSS_COLOR_REGEX.exec(color);

            if (results) {
                color = results[1];
                let mods = results[results.length - 1];
                mods = mods ? mods.split(" ") : [];

                for (let i = 0; i < mods.length; i++) {
                    const colorMod = mods[i];

                    if (colorMod === "") {
                        continue;
                    }

                    if (colorMod.startsWith("darken")) {
                        colorMods.push({
                            type: "darken",
                            value: parseInt(colorMod.substring("darken".length))
                        });
                    } else if (colorMod.startsWith("lighten")) {
                        colorMods.push({
                            type: "lighten",
                            value: parseInt(colorMod.substring("lighten".length))
                        });
                    } else {
                        colorMods.push({
                            type: "alpha",
                            value: parseFloat(colorMod)
                        });
                    }
                }
            }
        }
        return colorMods;
    }

    applyColorMods(color, colorMods) {
        if (color.name != "none") {
            for (let colorMod of colorMods) {
                switch (colorMod.type) {
                    case "alpha":
                        color.setAlpha(colorMod.value);
                        break;
                    case "darken":
                        color.darken(colorMod.value);
                        break;
                    case "lighten":
                        color.lighten(colorMod.value);
                        break;
                }
            }
        }
        return color;
    }

    getBackgroundColorType(backgroundColor) {
        if (backgroundColor == BackgroundStyleType.IMAGE) {
            return BackgroundStyleType.IMAGE;
        } else if (backgroundColor._originalInput == "white") {
            return BackgroundStyleType.LIGHT;
        } else if (backgroundColor._originalInput == "black") {
            return BackgroundStyleType.DARK;
        } else if (tinycolor.equals(backgroundColor, this.getColor(PaletteColorType.BACKGROUND_DARK))) {
            return BackgroundStyleType.DARK;
        } else if (tinycolor.equals(backgroundColor, this.getColor(PaletteColorType.BACKGROUND_LIGHT))) {
            return BackgroundStyleType.LIGHT;
        } else if (tinycolor.equals(backgroundColor, this.getColor(PaletteColorType.BACKGROUND_ACCENT))) {
            return BackgroundStyleType.ACCENT;
        } else {
            return BackgroundStyleType.COLOR;
        }
    }

    getColorfulColor(index) {
        let colors = [PaletteColorType.THEME];
        for (let accentColor of Object.keys(this.getAccentColors())) {
            colors.push(accentColor);
        }

        return this.getColor(colors[Math.max(0, index) % colors.length]);
    }

    getShadedColor(baseColor, index, maxCount, toDark) {
        if (toDark) {
            return baseColor.clone().darken(30 * (index / maxCount));
        } else {
            return baseColor.clone().brighten(30 * (index / maxCount));
        }
    }

    getBlendedColor(baseColor, index, maxCount, toDark) {
        let color = baseColor.clone();
        color.setAlpha(index / maxCount);
        return "white " + (index / maxCount);
    }

    getSlideColors() {
        let colors = {
            [PaletteColorType.THEME]: this.getColor(PaletteColorType.THEME).toHexString()
        };
        _.extend(colors, this.getAccentColors());
        return colors;
    }

    getAccentColors() {
        let colors = {};
        for (let colorName of Object.keys(this.colors)) {
            if (colorName.startsWith("accent")) {
                colors[colorName] = this.colors[colorName];
            }
        }
        return colors;
    }

    getChartColors(includePositiveNegetiveColors = true) {
        let colors = {};
        for (let colorName of Object.keys(this.colors)) {
            if (colorName.startsWith("chart")) {
                colors[colorName] = this.colors[colorName];
            }
        }
        if (includePositiveNegetiveColors) {
            colors.positive = this.colors[PaletteColorType.POSITIVE];
            colors.negative = this.colors[PaletteColorType.NEGATIVE];
        }
        return colors;
    }

    getBackgroundColors(includeColors = true) {
        let colors = {
            [PaletteColorType.BACKGROUND_LIGHT]: this.getColor(PaletteColorType.BACKGROUND_LIGHT).toRgbString(),
            [PaletteColorType.BACKGROUND_DARK]: this.getColor(PaletteColorType.BACKGROUND_DARK).toRgbString(),
            [PaletteColorType.BACKGROUND_ACCENT]: this.getColor(PaletteColorType.BACKGROUND_ACCENT).toRgbString()
        };
        if (includeColors) {
            _.extend(colors, { [PaletteColorType.THEME]: this.getColor(PaletteColorType.THEME).toRgbString() }, this.getAccentColors());
        }
        return colors;
    }

    getColorfulColors() {
        let colors = [PaletteColorType.THEME];

        for (let accentColor of Object.keys(this.getAccentColors())) {
            colors.push(accentColor);
        }

        return colors;
    }

    getColors() {
        let colors = {
            primary_dark: this.getColor("primary_dark").toRgbString(),
            primary_light: this.getColor("primary_light").toRgbString(),
            secondary_dark: this.getColor("secondary_dark").toRgbString(),
            secondary_light: this.getColor("secondary_light").toRgbString(),
            [PaletteColorType.THEME]: this.getColor(PaletteColorType.THEME).toRgbString()
        };
        _.extend(colors, this.getAccentColors());

        return colors;
    }

    // helper function for ui color palette pickers
    getColorList(options = {}) {
        var colors = [];

        if (options.includeNone) {
            colors.push({ type: "color", value: "none", color: "none" });
        }

        _.each(this.colors, (color, key) => {
            colors.push({ type: "color", value: key, color: color });
        });

        return colors;
    }

    getElementColors() {
        return Object.keys(this.getSlideColors())
            .map(value => ({
                type: "color",
                value,
                color: this.getColor(value).toRgbString()
            }));
    }

    getDecorationColors(decorationColor, decorationStyle, hiliteType) {
        let decorationFillColor, decorationStrokeColor;
        switch (decorationStyle ?? this.theme.get("styleElementStyle")) {
            case DecorationStyle.FILLED:
                decorationFillColor = decorationColor;
                decorationStrokeColor = null;

                if (hiliteType == HiliteType.DEEMPHASIZED) {
                    decorationFillColor = decorationFillColor.clone().setAlpha(0.4);
                }
                break;
            case DecorationStyle.OUTLINED:
                decorationFillColor = null;
                decorationStrokeColor = decorationColor;

                if (hiliteType == HiliteType.EMPHASIZED) {
                    decorationFillColor = decorationColor;
                    // decorationStrokeColor = null;
                }

                break;
            case DecorationStyle.MUTED:
                decorationFillColor = decorationColor.clone().setAlpha(0.3);
                decorationStrokeColor = null;

                if (hiliteType == HiliteType.DEEMPHASIZED) {
                    decorationFillColor = decorationFillColor.clone().setAlpha(0.1);
                } else if (hiliteType == HiliteType.EMPHASIZED) {
                    decorationFillColor = decorationFillColor.clone().setAlpha(0.666);
                }
                break;
            case DecorationStyle.FILL_AND_STROKE:
                decorationFillColor = decorationColor.clone().setAlpha(0.3);
                decorationStrokeColor = decorationColor;

                if (hiliteType == HiliteType.DEEMPHASIZED) {
                    decorationFillColor = decorationFillColor.clone().setAlpha(0.2);
                } else if (hiliteType == HiliteType.EMPHASIZED) {
                    decorationFillColor = decorationFillColor.clone().setAlpha(1);
                }
                break;
            case DecorationStyle.NONE:
                decorationFillColor = null;
                decorationStrokeColor = null;
                break;
        }

        return {
            decorationFillColor,
            decorationStrokeColor,
            backgroundColor: decorationFillColor
        };
    }

    doesBackgroundColorAllowColorOnTop(backgroundColor) {
        if (!backgroundColor) return false;

        if (backgroundColor?.name?.equalsAnyOf(PaletteColorType.BACKGROUND_DARK, PaletteColorType.BACKGROUND_LIGHT, PaletteColorType.BACKGROUND_ACCENT)) {
            return true;
        } else if (backgroundColor._r == backgroundColor._g && backgroundColor._g == backgroundColor._b && backgroundColor._r == 255) {
            return true;
        }
    }
}

export {
    Palette
};
