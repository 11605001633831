import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { FlexBox } from "js/react/components/LayoutGrid";
import { ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import { ds } from "js/core/models/dataService";
import {
    findElementsWithImages,
    generateImagesForElement,
    buildSlideModel
} from "js/core/services/slideModelBuilder";
import {
    AiGeneratedSlideGroupType,
    AiGeneratedSlideTypeGroups,
    GenerateSlideResponse,
    GeneratedSlideModel
} from "common/aiConstants";
import AiGenerationService, {
    GenerateSlideRequestWithFiles,
    InsufficientCreditsError,
    TooManyRequestsError
} from "js/core/services/aiGeneration";
import getLogger, { LogGroup } from "js/core/logger";
import AppController from "js/core/AppController";
import Spinner from "js/react/components/Spinner";
import { Gap20 } from "js/react/components/Gap";
import { MetricName } from "common/interfaces";

import {
    ContextSource,
    ContextSourceType
} from "../GeneratePresentationPane/controllers/GeneratePresentationController";

import {
    PaneContainer,
    PaneContent,
    PaneFooter
} from "../../components/PaneComponents";
import GenerationHeader from "../../components/GenerationHeader";
import PromptInput from "../../components/PromptInput";
import { ContextSources } from "../../components/ContextSources";
import { ShowInsufficientCreditsDialog, ShowTooManyRequestsDialog } from "js/react/components/Dialogs/CredtsErrorDialog";

const logger = getLogger(LogGroup.AI);

const promptExamples = [
    "A quote by Albert Einstein about the universe",
    "Chart the population of the world between 1900 and now",
    "Compare popular vacuum cleaners",
    "Top 3 women entrepreneurs",
    "Google's ad revenue in 2022",
    "How to write a blog post in a few easy steps",
    "Why did the chicken cross the road?",
    "Logos of the top 9 consumer companies",
    "Wordcloud about business metrics",
    "Pie chart of the market share of popular social media networks",
    "Top 5 movies of 2010 with movie posters and box office revenue",
    "Timeline of great future inventions",
    "Create a table for the last 10 years of the Superbowl including the year, the winning team, the losing team, the winning quarterback and the score",
];

const GenerationProgress = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .spinner {
        position: relative !important;
        width: 75px;
        height: 75px;
    }

    > label {
        font-family: "Source Sans Pro Heavy";
        font-size: 20px;
        font-weight: 900;
        max-width: 60%;
        text-align: center;
    }
`;

interface GenerateSlidePaneProps {
    onGeneratedSlide: (model: GeneratedSlideModel, prompt: string, generateSlideResponse: GenerateSlideResponse) => void;
}

function GenerateSlidePane({ onGeneratedSlide }: GenerateSlidePaneProps) {
    const [generationMessage, setGenerationMessage] = useState<string>("");
    const [slideGroupType, setSlideGroupType] = useState<AiGeneratedSlideGroupType>(AiGeneratedSlideGroupType.ALL_BUT_TITLE);
    const [contextSources, setContextSources] = useState<ContextSource[]>([]);
    const [prompt, setPrompt] = useState<string>("");
    const [fetching, setFetching] = useState<boolean>(true);
    const [aiCreditsBalance, setAiCreditsBalance] = useState<number>(0);

    const handleAddContextSource = contextSourceType => {
        setContextSources([...contextSources, { type: contextSourceType }]);
    };

    const handleDeleteContextSource = contextSource => {
        setContextSources(contextSources.filter(c => c != contextSource));
    };

    useEffect(() => {
        (async () => {
            await loadAiCreditsBalance();
            setFetching(false);
        })();
    }, []);

    const loadAiCreditsBalance = async () => {
        try {
            const balance = await AiGenerationService.getCreditsBalance(AppController.workspaceId);
            setAiCreditsBalance(balance);
        } catch (err) {
            logger.error(err, "[GenerateSlidePane] Error fetching AI credits balance");
        }
    };

    const handleStartGeneration = async () => {
        const startedAt = Date.now();
        let withContext = false;
        let imageCount = 0;
        let templateId = null;

        try {
            setGenerationMessage("DesignerBot AI is creating a slide...");

            const generateSlideRequest: GenerateSlideRequestWithFiles = {
                prompt,
                presentationTitle: ds.selection?.presentation?.get("name"),
                allowedSlideTypes: AiGeneratedSlideTypeGroups[slideGroupType],
                files: [],
                contextUrls: [],
            };

            for (let contextSource of contextSources) {
                switch (contextSource.type) {
                    case ContextSourceType.FILE:
                        if (contextSource.file) generateSlideRequest.files.push(contextSource.file);
                        break;
                    case ContextSourceType.WEBPAGE:
                        if (contextSource.url) generateSlideRequest.contextUrls.push(contextSource.url);
                        break;
                    case ContextSourceType.TEXT:
                        if (contextSource.text) generateSlideRequest.files.push(new File([contextSource.text], "document.txt", { type: "text/plain" }));
                        break;
                }
            }

            withContext = generateSlideRequest.contextUrls?.length > 0 || generateSlideRequest.files?.length > 0;

            const generateSlideResponse = await AiGenerationService.generateSlide(generateSlideRequest);

            setGenerationMessage("Building your slide...");

            const model = await buildSlideModel(generateSlideResponse);

            templateId = model.template_id;

            const imagesToGenerate = findElementsWithImages(model);
            imageCount = imagesToGenerate.length;

            if (imagesToGenerate.length > 0) {
                let count = imagesToGenerate.length;
                await Promise.all(imagesToGenerate.map(image => generateImagesForElement(image).then(() => {
                    count--;
                    setGenerationMessage(`Finding images (${count} remaining)...`);
                })));
            }

            onGeneratedSlide(
                model,
                prompt,
                generateSlideResponse
            );

            logger.metric(MetricName.AI_GENERATE_SLIDE_TIME, {
                generationTimeMs: Date.now() - startedAt,
                generationFailed: false,
                withContext,
                imageCount,
                templateId
            });
        } catch (err) {
            logger.error(err, "[GenerateSlidePane] handleGenerateSlide() failed", { prompt });

            if (err instanceof InsufficientCreditsError) {
                ShowInsufficientCreditsDialog(() => loadAiCreditsBalance());
            } else if (err instanceof TooManyRequestsError) {
                ShowTooManyRequestsDialog();
            } else {
                ShowErrorDialog({
                    title: "Sorry, something went wrong while generating your slide!",
                    message: "Sometimes DesignerBot's AI can get confused. You can try your prompt again or edit it."
                });
            }

            setGenerationMessage("");

            logger.metric(MetricName.AI_GENERATE_SLIDE_TIME, {
                generationTimeMs: Date.now() - startedAt,
                generationFailed: true,
                withContext,
                imageCount,
                templateId
            });
        }
    };

    const canStartGeneration = () => {
        return prompt.trim().length > 0;
    };

    const handleEnterKeyPress = () => {
        if (canStartGeneration()) {
            handleStartGeneration();
        }
    };

    return (<PaneContainer cta="SlideBot" fetching={fetching}>
        {generationMessage &&
            <GenerationProgress>
                <Spinner />
                <label>{generationMessage}</label>
            </GenerationProgress>
        }
        {!generationMessage &&
            <>
                <GenerationHeader
                    header="Generate a slide with ai."
                />
                <PaneContent>
                    <FlexBox vertical fillWidth fillHeight top left>
                        <PromptInput
                            label="Create a slide about..."
                            prompt={prompt}
                            onPromptChange={setPrompt}
                            promptPlaceholder="Describe the topic of your slide..."
                            promptExamples={promptExamples}
                            onEnterKeyPress={handleEnterKeyPress}
                            slideGroupType={slideGroupType}
                            onSlideGroupTypeChange={setSlideGroupType}
                            onActionButtonClick={handleStartGeneration}
                            actionButtonText="Generate Slide"
                            allowedSlideGroupTypes={Object.values(AiGeneratedSlideGroupType).filter(type => type !== AiGeneratedSlideGroupType.PRESENTATION)}
                        />
                        <Gap20 />
                        <ContextSources
                            contextSources={contextSources}
                            aiCreditsBalance={aiCreditsBalance}
                            onAdd={handleAddContextSource}
                            onDelete={handleDeleteContextSource}
                            reloadAiCreditsBalance={loadAiCreditsBalance}
                            requiredCredits={1}
                        />
                    </FlexBox>
                </PaneContent>
                <PaneFooter
                    showBackButton={false}
                />
            </>
        }
    </PaneContainer>);
}

export default GenerateSlidePane;
