import { Icon, MenuItem, Tooltip } from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { Component } from "reactn";
import styled from "styled-components";

import AppController from "js/core/AppController";
import { PresentationTemplateController } from "js/core/dataServices/PresentationTemplateDataService";
import { ds } from "js/core/models/dataService";
import getUserProfile from "js/core/services/userProfiles";
import { sortTagsByPopularity, trackActivity } from "js/core/utilities/utilities";
import { CreatePresentationDialogPaneType } from "js/editor/CreatePresentation/CreatePresentationDialogTypes";
import { Breadcrumb } from "js/Components/Breadcrumb";
import { app } from "js/namespaces";
import {
    ShowConfirmationDialog,
    ShowDialog,
    ShowErrorDialog
} from "js/react/components/Dialogs/BaseDialog";
import { FlexSpacer, Gap, Gap10, Gap20, Gap5 } from "js/react/components/Gap";
import { FlexBox } from "js/react/components/LayoutGrid";
import Loadable from "js/react/components/Loadable";
import { NoMatchNotice, NoTeamTemplateNotice } from "js/react/components/Notice";
import { IconMenu, RoundIconButton, UIPane } from "js/react/components/UiComponents";
import { themeColors } from "js/react/sharedStyles";
import { FilterDropDown } from "js/react/views/AddSlide/Panes/Components/FilterDropDown";
import { SearchBarContainer, SearchBarInnerContainer, SlideSearchInput, UIPaneResultsContainer, UIPaneResultsInnerContainer } from "js/react/views/AddSlide/Panes/Components/SearchBox";
import { SortMenu } from "js/react/views/AddSlide/Panes/Components/SortMenu";
import { SlideThumbnail } from "js/react/views/AddSlide/Panes/Components/ThumbnailGrid";
import { TeamTemplateSettingsDialog } from "js/react/views/TeamResources/dialogs/TeamTemplateSettingsDialog";
import { _ } from "js/vendor";
import PresentationLibraryController from "js/controllers/PresentationLibraryController";
import { getPresentation } from "js/core/models/presentation";
import { OnMountedReporter } from "js/core/utilities/useMountedAt";

const contentFilterOptions = [
    {
        id: "all",
        name: "All Content",
        filter: (item, searchTerm) => {
            return contentFilterOptions
                .filter(option => option.id !== "all")
                .some(option => option.filter(item, searchTerm));
        },
    },
    {
        id: "name",
        name: "Name",
        filter: (item, searchTerm) => {
            return item.name?.toLowerCase().includes(searchTerm);
        },
    },
    {
        id: "description",
        name: "Description",
        filter: (item, searchTerm) => {
            return item.description?.toLowerCase().includes(searchTerm);
        },
    },
    {
        id: "tags",
        name: "Tags",
        filter: (item, searchTerm) => {
            return item.tags?.some(tag => tag.toLowerCase().includes(searchTerm));
        },
    },
];

const sortOptions = [
    {
        value: "name",
        label: "Name",
        icon: <Icon>sort_by_alpha</Icon>
    },
    {
        value: "ratedByCount",
        label: "Rating",
        reverse: true,
        icon: <Icon>star</Icon>
    },
    {
        value: "createdAt",
        label: "Recently Created",
        reverse: true,
        icon: <Icon>date_range</Icon>
    },
    {
        value: "modifiedAt",
        label: "Recently Modified",
        reverse: true,
        icon: <Icon>update</Icon>
    },
];

export default PresentationLibraryController.withState(class PresentationTemplatesPane extends Component {
    handleEditItem = item => {
        AppController.showEditor({ presentationId: item.id });
    }

    render() {
        const { isInitialized, onReady = () => {} } = this.props;

        return (
            <UIPane>
                <Loadable isLoading={!isInitialized}>
                    <OnMountedReporter onMounted={onReady} />
                    <PresentationTemplatesPaneContents
                        editable={true}
                        onConfirm={this.handleEditItem}
                    />
                </Loadable>
            </UIPane>
        );
    }
});

export const PresentationTemplatesPaneContents = PresentationLibraryController.withState(class PresentationTemplatesPaneContents extends Component {
    state = {
        items: [],
        isLoading: true,
        contentFilter: "all",
        tagFilter: "  all  ", // Spaces needed to prevent conflict with other tags
        sortBy: "modifiedAt",
        sortDir: 1,
        searchTerm: "",
        itemSelected: null,
    }

    componentDidMount() {
        const { editable = true } = this.props;

        PresentationTemplateController.getTeamTemplates(null, editable)
            .then(items => this.setState({ items, isLoading: false }));
    }

    componentDidUpdate(prevProps) {
        const { presentations, editable = true } = this.props;

        const getComparableItem = presentation => _.pick(presentation, "id", "name", "isTemplate", "isPublished", "ratedByUsers");

        const itemsBefore = prevProps.presentations.filter(p => p.isTemplate).map(getComparableItem);
        const itemsAfter = presentations.filter(p => p.isTemplate).map(getComparableItem);

        if (_.isEqual(itemsBefore, itemsAfter)) {
            return;
        }

        const addedItems = itemsAfter.filter(item => !itemsBefore.some(i => i.id === item.id));
        const changedItems = itemsAfter.filter(item => itemsBefore.some(i => i.id === item.id && !_.isEqual(i, item)));
        const unchangedItems = itemsAfter.filter(item => itemsBefore.some(i => i.id === item.id && _.isEqual(i, item)));

        const templateIdsToLoad = [...new Set([...addedItems, ...changedItems].map(item => item.id))];
        PresentationTemplateController.getTeamTemplates(templateIdsToLoad, editable)
            .then(items => {
                this.setState(prevState => ({ ...prevState, items: [...unchangedItems.map(({ id }) => prevState.items.find(i => i.id === id)), ...items].filter(Boolean) }));
            });
    }

    handleSearch = async searchTerm => {
        this.setState({
            searchTerm: searchTerm?.trim().toLowerCase(),
        });
    };

    handleAddItem = () => {
        AppController.showCreatePresentationDialog({
            pane: CreatePresentationDialogPaneType.BLANK_PRESENTATION,
            state: {
                isTeamTemplate: true,
            }
        });
    }

    handleEditItem = item => {
        AppController.showEditor({ presentationId: item.id });
    }

    handleEditTemplateSettings = (item, existingTags) => {
        ShowDialog(TeamTemplateSettingsDialog, {
            item,
            existingTags,
            editTemplate: this.handleEditItem,
            updateTemplate: async delta => {
                if (!isEmpty(delta)) {
                    try {
                        let presentation = await getPresentation(item.id, { autoSync: false, permission: "write" });
                        let itemValues = presentation.attributes;
                        itemValues = {
                            ...itemValues,
                            ...delta,
                            createdById: item.createdById || item.userId || app.user.id,
                            modifiedAt: Date.now(),
                            modifiedById: app.user.id,
                        };

                        presentation.update(itemValues, {
                            replaceKeys: true,
                        });
                        await presentation.updatePromise;
                        this.forceUpdate();

                        trackActivity("TeamTemplate", "Updated", null, null, {
                            id: itemValues.id,
                            orgId: itemValues.orgId,
                            values: itemValues,
                        });
                    } catch (err) {
                        ShowErrorDialog({
                            title: "Unable to edit presentation settings",
                            message: err.message
                        });
                    }
                }
            }
        });
    }

    handleDeleteItem = async item => {
        if (await ShowConfirmationDialog({
            title: "Are you sure you want to delete this template?",
            message: "This action can not be undone."
        })) {
            PresentationTemplateController.deletePresentationTemplate(item);
        }
    }

    handleChangePublishState = async (item, state) => {
        if (state) {
            await PresentationTemplateController.publishPresentationTemplate(item.template);
            item.isPublished = true;
        } else {
            await PresentationTemplateController.unpublishPresentationTemplate(item.template);
            item.isPublished = false;
        }
        this.forceUpdate();
    }

    handleChangeSelection = itemSelected => {
        this.setState({
            itemSelected,
        });
        let {
            onSelection,
        } = this.props;
        onSelection && onSelection(itemSelected);
    }

    getSortedFilteredItems() {
        let {
            items,
            contentFilter,
            tagFilter,
            sortBy,
            sortDir,
            searchTerm,
        } = this.state;
        let {
            editable
        } = this.props;

        // filtering
        let filteredItems = items.filter(item => {
            if (
                !editable &&
                !item.isPublished
            ) {
                return false;
            }

            let {
                tags = [],
            } = item;

            let searchMatch = (
                !searchTerm?.length ||
                contentFilterOptions
                    .find(x => x.id === contentFilter)
                    .filter(item, searchTerm)
            );
            let tagMatch = (
                tagFilter === "  all  " ||
                tags.includes(tagFilter)
            );

            return searchMatch && tagMatch;
        });

        // sorting
        filteredItems.sort((a, b) => {
            const valueA = a[sortBy];
            const valueB = b[sortBy];
            return valueA < valueB ? sortDir : -sortDir;
        });

        return filteredItems;
    }

    getSortedTags() {
        let {
            items,
        } = this.state;
        let {
            editable
        } = this.props;

        let filteredItems = items.filter(item => {
            return editable || item.isPublished;
        });

        let sortedTags = sortTagsByPopularity(filteredItems.map(item => item.tags));

        return sortedTags;
    }

    render() {
        let {
            isLoading,
            contentFilter,
            tagFilter,
            sortBy,
            searchTerm,
            itemSelected,
        } = this.state;
        let {
            editable = true,
            onConfirm,
        } = this.props;

        const existingTags = this.getSortedTags();

        let tagOptions = [
            {
                id: "  all  ", // Spaces needed to prevent conflict with other tags
                name: "All Tags",
            },
            ...existingTags.map(tag => {
                return {
                    id: tag,
                    name: tag,
                };
            }),
        ];

        const items = this.getSortedFilteredItems();

        return (
            <>
                <SearchBarContainer className="search-container">
                    {
                        editable &&
                        <>
                            <Breadcrumb>Team Templates</Breadcrumb>
                            <RoundIconButton onClick={this.handleAddItem}><Icon>add</Icon></RoundIconButton>
                            <Gap size={60} />
                        </>
                    }
                    <SearchBarInnerContainer>
                        <SlideSearchInput
                            query={searchTerm}
                            onSearch={this.handleSearch}
                            placeholder="Search team templates..."
                        />

                        <FilterDropDown
                            onChange={event => this.setState({ contentFilter: event.target.value })}
                            selectedFilter={contentFilter}
                            filterOptions={contentFilterOptions}
                        />
                        <FilterDropDown
                            onChange={event => this.setState({ tagFilter: event.target.value })}
                            selectedFilter={tagFilter}
                            filterOptions={tagOptions}
                        />
                        <SortMenu
                            selectedOption={sortBy}
                            disabled={searchTerm.length > 0}
                            sortOptions={sortOptions}
                            onSetSort={({ field, reverse }) => this.setState({ sortBy: field, sortDir: reverse ? 1 : -1 })}
                        />
                    </SearchBarInnerContainer>
                </SearchBarContainer>
                <UIPaneResultsContainer>
                    <Loadable isLoading={isLoading}>
                        {
                            items.length > 0 &&
                            <UIPaneResultsInnerContainer>
                                {
                                    items.map(item => (
                                        <PresentationTemplateItem
                                            key={item.id}
                                            item={item}
                                            readOnly={!editable}
                                            selected={item === itemSelected}
                                            onSelection={() => this.handleChangeSelection(item)}
                                            onConfirm={() => onConfirm && onConfirm(item)}
                                            onEditSettings={() => this.handleEditTemplateSettings(item, existingTags)}
                                            onEditTemplate={() => this.handleEditItem(item)}
                                            onDelete={() => this.handleDeleteItem(item)}
                                            onChangePublishState={state => this.handleChangePublishState(item, state)}
                                        />
                                    ))
                                }
                            </UIPaneResultsInnerContainer>
                        }
                        {
                            !items.length &&
                            <>
                                {
                                    (
                                        !searchTerm &&
                                        contentFilter === "all" &&
                                        tagFilter === "  all  "
                                    )
                                        ? <NoTeamTemplateNotice />
                                        : <NoMatchNotice />
                                }
                            </>
                        }
                    </Loadable>
                </UIPaneResultsContainer>
            </>
        );
    }
});

const TemplateItem = styled.div`
    width: 100%;
    // max-width: calc(100vw - 310px);
    margin-bottom: 20px;
    background: white;
    padding: 10px 20px 10px 20px;
    border: solid 1px white;
    &:hover {
        border: solid 1px ${themeColors.ui_blue};
    }
    &.selected {
        background: ${themeColors.lightBlue};
    }
    .title {
        font-weight: 600;
        font-size: 16px;
    }
`;

const SlideThumbnailsContainer = styled.div`
    width: 100%;
`;

const SlideThumbnails = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 200px;
    width: 100%;
    overflow-x: auto;
    gap: 10px;
    padding: 10px 1px;
    .thumbnail-container {
        .thumbnail {
            box-shadow: none !important;
            img {
            border: solid 1px #ddd !important;
            }
        }
    }
`;

const PublishLabel = styled.div`
    padding: 5px 10px;
    color: white;
    background: ${props => props.published ? themeColors.ui_blue : "orangered"};
    border-radius: 2px;
    text-transform: uppercase;
    font-size: 12px;
`;

const Stat = styled.div`
    display: flex;
    align-items: center;
    color: rgba(102, 102, 102, ${props => props.active ? 1 : 0.5});
    &.ratedBy {
        cursor: pointer;
        
        .MuiIcon-root {
            color: ${props => props.liked ? themeColors.yellow : "inherit"};
        }
    }
    &:hover {
        color: ${themeColors.ui_blue};
        .MuiIcon-root {
            color: ${themeColors.ui_blue};
        }
    }
`;

const StyledLoadItemInfo = styled.div`
    padding: 10px;
`;

export class PresentationTemplateItem extends Component {
    state = {
        itemInfo: {}
    };

    componentDidUpdate(prevProps) {
        if (this.props.item != prevProps.item) {
            this.loadItemInfo();
        }
    }

    async componentDidMount() {
        await this.loadItemInfo();
    }

    handleDoubleClick = () => {
        let {
            onSelection,
            onConfirm,
        } = this.props;

        onSelection && onSelection();
        onConfirm && onConfirm();
    }

    handleLikeItem = () => {
        const { item } = this.props;

        if (item.ratedByUsers && item.ratedByUsers[app.user.id]) {
            item.template.update({
                ratedByUsers: { [app.user.id]: null }
            });
        } else {
            item.template.update({
                ratedByUsers: { [app.user.id]: true }
            });
        }

        item.ratedByUsers = item.template.get("ratedByUsers") || {};
        item.ratedByCount = Object.keys(item.ratedByUsers).length;

        this.forceUpdate();
    }

    handlePublish = async () => {
        if (await ShowConfirmationDialog({
            title: "Are you sure you want to publish this Presentation Template to your team?",
            message: "Your team members will be able to create a new presentation starting from this template by selecting it in Create New Presentation dialog."
        })) {
            this.props.onChangePublishState(true);
        }
    }

    async loadItemInfo() {
        const { item } = this.props;
        const {
            description,
            createdById
        } = item;
        const profile = await getUserProfile(createdById);

        const info = (
            <StyledLoadItemInfo>
                {description && (<>
                    <div>{description}</div>
                    <Gap10 />
                </>)}
                <div>Created by <strong>{profile.displayName || profile.email || "Unknown"}</strong></div>
            </StyledLoadItemInfo>
        );
        this.setState({
            itemInfo: info
        });
    }

    render() {
        const {
            item,
            readOnly,
            selected,
            onSelection,
            onEditSettings,
            onEditTemplate,
            onDelete,
            onChangePublishState
        } = this.props;

        const {
            ratedByUsers,
            ratedByCount,
            description,
            isPublished,
            name,
            id,
            sips
        } = item;

        return (
            <TemplateItem
                key={id}
                className={(readOnly && selected) ? "selected" : ""}
                onClick={onSelection}
                onDoubleClick={this.handleDoubleClick}
                readOnly={readOnly}
            >
                <FlexBox left center fillHeight>
                    <div className="title">{name}</div>
                    <Gap20 />
                    {!readOnly && isPublished && <PublishLabel published>Published</PublishLabel>}
                    {!readOnly && !isPublished && <PublishLabel onClick={this.handlePublish}>Draft</PublishLabel>}
                    <FlexSpacer />
                    <FlexBox center onDoubleClick={e => e.stopPropagation()}>
                        <Tooltip
                            title={(
                                <div style={{ padding: 10 }}>
                                    Click to {ratedByUsers[app.user.id] ? "unfavorite" : "favorite"} this template.
                                </div>
                            )}
                            classes={{ popper: "popper-white rounded-5" }}
                            placement="top"
                            arrow
                            interactive
                        >
                            <Stat
                                active={ratedByCount}
                                liked={ratedByUsers[app.user.id]}
                                className="ratedBy"
                                onClick={this.handleLikeItem}
                            >
                                <Icon>star</Icon>
                                <span>{ratedByCount}</span>
                            </Stat>
                        </Tooltip>
                        <Gap5 />
                        <Tooltip
                            title={this.state.itemInfo ?? ""}
                            classes={{ popper: "popper-white rounded-5" }}
                            placement="top"
                            arrow
                            interactive
                        >
                            <Stat active={description}>
                                <Icon className="stat">info</Icon>
                            </Stat>
                        </Tooltip>
                        {
                            !readOnly &&
                            <IconMenu>
                                <MenuItem divider onClick={onEditSettings}><Icon>label</Icon>Template Settings...</MenuItem>
                                <MenuItem divider onClick={onEditTemplate}><Icon>edit</Icon>Edit Template...</MenuItem>
                                {isPublished && <MenuItem divider onClick={() => onChangePublishState(false)}><Icon>unpublished</Icon>Unpublish Template</MenuItem>}
                                {!isPublished && <MenuItem divider onClick={this.handlePublish}><Icon>published_with_changes</Icon>Publish Template</MenuItem>}
                                <MenuItem onClick={onDelete}><Icon>delete</Icon>Delete Template</MenuItem>
                            </IconMenu>
                        }
                    </FlexBox>
                </FlexBox>
                <SlideThumbnailsContainer>
                    <SlideThumbnails>
                        {sips.map(slideId => <SlideThumbnail key={slideId} slideId={slideId} />)}
                    </SlideThumbnails>
                </SlideThumbnailsContainer>
            </TemplateItem>
        );
    }
}
