import { resetGlobal } from "reactn";

import { app } from "js/namespaces";
import { Backbone } from "js/vendor";
import { isOfflinePlayer } from "js/config";
import { Assets, UserAssets } from "js/core/models/assets";
import { UserFolders } from "js/core/models/folder";
import { UserPlans } from "js/core/models/plan";
import { Teams } from "js/core/models/team";
import { Observables } from "js/core/models/observables";
import getLogger, { LogGroup } from "js/core/logger";

const logger = getLogger(LogGroup.DATA_SERVICE);

const DataService = Backbone.Model.extend({
    prepare: function({ SharedThemes, BuiltInThemes, UserThemes }) {
        this.shutdown();

        this.SharedThemes = SharedThemes;
        this.BuiltInThemes = BuiltInThemes;
        this.UserThemes = UserThemes;
    },

    testSetup: function() {
        this.selection = new Selection();
        this.builtInThemes = new this.BuiltInThemes();
        this.assets = new Assets(null, { autoLoad: false });
        this.userFolders = {
            get: function() {

            },
            loadModels: function() {
                return Promise.resolve(null);
            },
            models: [{
                get: function() {

                }
            }]
        };
        this.teams = {
            get: function() {

            },
            loadModels: function() {
                return Promise.resolve(null);
            },
            models: [{
                get: function() {

                },
                on: function() {

                }
            }],
            on: function() {

            }
        };
    },

    dummySetup: function() {
        if (!isOfflinePlayer) {
            this.builtInThemes = new this.BuiltInThemes();
        }
        if (app.user && app.user.id) {
            this.userPlans = new UserPlans(null, { autoLoad: !isOfflinePlayer });
        }
        if (!this.selection) {
            this.selection = new Selection();
        }
        this.assets = new Assets(null, { autoLoad: false });
        this.selection.slide = {
            commit: () => {
            }
        };
    },

    setup: async function() {
        // setup should only be called once
        if (this.hasBeenSetup) {
            return;
        }
        this.hasBeenSetup = true;
        if (!this.selection) {
            this.selection = new Selection();
        }
        this.builtInThemes = new this.BuiltInThemes();

        //Any reference collection added here will need an object store created in storage.js.
        //That will ensure the reference collection exists in indexdb
        try {
            this.userPlans = new UserPlans(null, { autoLoad: true });
            this.userThemes = new this.UserThemes(null, { autoLoad: true, modelOptions: { userId: app.user.id } });
            this.sharedThemes = new this.SharedThemes(null, { autoLoad: true, modelOptions: { userId: app.user.id } });
            this.userAssets = new UserAssets(null, { autoLoad: !isOfflinePlayer });
            this.userAssets.comparator = function(model) {
                return -1 * model.get("lastUsed");
            };
            this.assets = new Assets(null, { autoLoad: false, modelOptions: { autoLoad: false } });
            this.userFolders = new UserFolders(null, { autoLoad: true, modelOptions: { userId: app.user.id } });
            this.teams = new Teams(null, { autoLoad: true, modelOptions: { userId: app.user.id } });
            await this.teams.loadModels();
            if (!isOfflinePlayer) {
                await Promise.all(this.teams.map(team => team.loadLibraryItems()));
            }
        } catch (err) {
            logger.error(err, "setup() failed");
        }
    },

    checkoutSetup: function() {
        this.userPlans = new UserPlans(null, { autoLoad: true });
    },

    loadPresentation: function(presentation) {
        this.selection.presentation = presentation;
        this.trigger("presentationLoad");
        this.selection.slide = presentation.slides.at(0);
    },

    shutdown: function() {
        resetGlobal();

        this.selection?.presentation?.links?.disconnect();
        this.selection?.slide?.disconnect();
        this.selection?.presentation?.disconnect();
        this.userAssets?.disconnect();
        this.userThemes?.disconnect();
        this.sharedThemes?.disconnect();
        this.assets?.disconnect();
        this.userPlans?.disconnect();
        this.userFolders?.disconnect();
        this.teams?.disconnect();
        app.user?.disconnect();

        this.hasBeenSetup = false;
        this.selection = null;
        this.assets = null;
        this.userFolders = null;
        this.teams = null;
        this.userPlans = null;
        this.userThemes = null;
        this.sharedThemes = null;
        this.builtInThemes = null;
        this.userAssets = null;
    },

    clearData: function() {
    },

    getObservables: function() {
        if (!this._observables) {
            this._observables = new Observables(this);
        }

        return this._observables;
    }
});

const Selection = Backbone.Model.extend({

    defaults: {
        presentation: null,
        slide: null,
        element: null,
        authoringElements: [],
        childElement: null,
        dataState: 0
    },

    initialize: function() {
        this.type = "Selection";

        //create shortcut getter/setters for attributes for simpler property access when typing code...
        var obj = this;
        this.defineProperty("presentation");
        this.defineProperty("slide");

        Object.defineProperty(obj, "element", {
            get: function() {
                let element = obj.get("element");
                if (!element || element.isDeleted) {
                    return null;
                } else {
                    return element;
                }
            },
            set: function(value) {
                obj.set("element", value);
            }
        });
        this.defineProperty("rolloverElement");

        this.defineProperty("dataState");

        Object.defineProperty(obj, "slides", {
            get: function() {
                var presentation = obj.get("presentation");
                if (presentation) {
                    return presentation.getSips();
                } else {
                    return null;
                }
            }
        });

        this.defineProperty("authoringElements");

        this.listenTo(this, "change:slide", () => {
            this.element = null;
            this.rolloverElement = null;
            this.authoringElements = [];
        });

        this.defineProperty("documentElement");
    },

    selectSlideByIndex: function(index) {
        this.slide = this.presentation.slides.at(index);
    },

    debug: function() {
        /* eslint-disable no-console */
        console.log("slide %s %o", this.slide.cid, this.slide);
        console.log("element %s %o", this.element ? this.element.type : "none", this.element);
        console.log("child %s %o", this.childElement ? this.childElement.type : "none", this.childElement);
        /* eslint-disable no-console */
    },

    SLIDE_LOCK_TIME: 1000 * 5 // 5 seconds

});

const ds = new DataService();

export { DataService, ds, Selection };
