import type { ISharedResource } from "./ISharedResource";
import type { ISubFolderFirebase } from "./IFolder";
import { IGenericModelFields } from "./IGenericFields";

export enum TeamMemberRole {
    OWNER = "owner",
    MEMBER = "member",
    MEMBER_LICENSED = "member-licensed",
    LIBRARIAN = "librarian"
}

export enum TeamMemberLicense {
    TEAM_PRO = "team-pro",
    FREE = "free"
}

export enum BrandingPermission {
    ALL = "all",
    OWNER_AND_LIB = "ownerAndLib",
    NONE = "none",
}

export interface IWorkspaceSettings {
    playerSettingsDefaults?: {
        showTitle?: boolean,
        showAuthor?: boolean,
        requireEmail?: boolean,
        showSocialSharing?: boolean,
        showGetDeckUI?: boolean,
        allowPdfDownload?: boolean,
    },
    brandingPermissions?: {
        inspirationSlides?: BrandingPermission,
        customThemes?: BrandingPermission,
        stockLibraries?: BrandingPermission,
        webImages?: BrandingPermission,
        classicSlides?: BrandingPermission,
        convertToClassic?: BrandingPermission,
        importPPT?: BrandingPermission,
    },
    allTeamMembersFolderSettings?: {
        restrictAccess?: boolean,
        hideFolder?: boolean,
    },
    prohibitExternalWorkspaceCollaboration?: boolean,
    prohibitExternalWorkspaceMovement?: boolean,
    prohibitOpenAIIntegration?: boolean,
    prohibitGenerativeAI?: boolean,
}

export interface IPlayerBranding {
    active?: boolean;
    backgroundColor?: string;
    logoId?: string;
    logoScale?: number;
    useLoadingAnimation?: boolean;
    migratedToThemes?: boolean;
}

export interface ITeam extends IGenericModelFields {
    orgId: string;
    name: string;
    isDefault: boolean;
    members: {
        userId: string;
        role: TeamMemberRole;
    }[];
    sharedResources: ISharedResource[];
    workspaceSettings?: IWorkspaceSettings;
    playerBranding?: IPlayerBranding;
    subFolderIds?: string[]; // user for "All Team Members" folder subfolders
    createdAt: number;
    modifiedAt: number;
}

export interface ITeamFirebase {
    id: string;
    _changeId: string;
    name: string;
    isDefault: boolean;
    orgId: string;
    onlyOwnerCanEdit: boolean;
    customPlayerURLTemplate: string | null;
    members: {
        [userId: string]: {
            hasSeat: boolean;
            role: TeamMemberRole;
        }
    };
    sharedThemeId: string;
    sharedResources: {
        [key: string]: { // PermissionResourceType values
            [resourceId: string]: any;
        }
    };
    workspaceSettings?: IWorkspaceSettings;
    playerBranding?: IPlayerBranding;
    subFolders?: {
        [index: string]: ISubFolderFirebase;
    };
    createdAt: number;
    modifiedAt: number;
}
