import { getCallable } from "../_sdk/client";
import { apiName, endpoints } from "./endpoints";
import {
    GetWorkspacesMetadataRequest,
    GetWorkspacesMetadataResponse,
    GetWorkspaceRequest,
    GetWorkspaceResponse,
    GetWorkspaceUsersRequest,
    GetWorkspaceUsersResponse,
    UpdateWorkspaceRequest,
    UpdateWorkspaceResponse,
    VerifyWorkspaceDomainRequest,
    VerifyWorkspaceDomainResponse,
    ParseSamlMetadataResponse,
    ParseSamlMetadataRequest,
    DisconnectWorkspaceIntegrationRequest,
    DisconnectWorkspaceIntegrationResponse,
    ConnectWorkspaceIntegrationRequest,
    ConnectWorkspaceIntegrationResponse
} from "./types";

export { apiName };

const getWorkspacesMetadata = getCallable<GetWorkspacesMetadataRequest, GetWorkspacesMetadataResponse>(
    apiName,
    "getWorkspacesMetadata",
    {
        ...endpoints.getWorkspacesMetadata,
        composer: request => {
            return {
                url: "/workspaces/metadata",
                options: {
                    method: "GET"
                }
            };
        }
    });

const getWorkspace = getCallable<GetWorkspaceRequest, GetWorkspaceResponse>(
    apiName,
    "getWorkspace",
    {
        ...endpoints.getWorkspace,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}`,
                options: {
                    method: "GET"
                }
            };
        }
    });

const getWorkspaceUsers = getCallable<GetWorkspaceUsersRequest, GetWorkspaceUsersResponse>(
    apiName,
    "getWorkspaceUsers",
    {
        ...endpoints.getWorkspaceUsers,
        composer: request => {
            const searchParams = new URLSearchParams();
            if (request.uids) {
                searchParams.set("uids", JSON.stringify(request.uids));
            }

            return {
                url: `/workspaces/${request.workspaceId}/users?${searchParams.toString()}`,
                options: {
                    method: "GET"
                }
            };
        }
    });

const updateWorkspace = getCallable<UpdateWorkspaceRequest, UpdateWorkspaceResponse>(
    apiName,
    "updateWorkspace",
    {
        ...endpoints.updateWorkspace,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}`,
                options: {
                    method: "PUT",
                    body: JSON.stringify(request)
                }
            };
        }
    });

const verifyWorkspaceDomain = getCallable<VerifyWorkspaceDomainRequest, VerifyWorkspaceDomainResponse>(
    apiName,
    "verifyWorkspaceDomain",
    {
        ...endpoints.verifyWorkspaceDomain,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/verify-domain`,
                options: {
                    method: "POST"
                }
            };
        }
    });

const parseSamlMetadata = getCallable<ParseSamlMetadataRequest, ParseSamlMetadataResponse>(
    apiName,
    "parseSamlMetadata",
    {
        ...endpoints.parseSamlMetadata,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/parse-saml-metadata`,
                options: {
                    method: "POST",
                    body: JSON.stringify(request)
                }
            };
        }
    });

const disconnectWorkspaceIntegration = getCallable<DisconnectWorkspaceIntegrationRequest, DisconnectWorkspaceIntegrationResponse>(
    apiName,
    "disconnectWorkspaceIntegration",
    {
        ...endpoints.disconnectWorkspaceIntegration,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/integrations/${request.integrationType}/disconnect`,
                options: {
                    method: "POST"
                }
            };
        }
    });

const connectWorkspaceIntegration = getCallable<ConnectWorkspaceIntegrationRequest, ConnectWorkspaceIntegrationResponse>(
    apiName,
    "connectWorkspaceIntegration",
    {
        ...endpoints.connectWorkspaceIntegration,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/integrations/${request.integrationType}/connect`,
                options: {
                    method: "POST",
                    body: JSON.stringify(request)
                }
            };
        }
    }
);

export const callables = {
    getWorkspacesMetadata,
    getWorkspace,
    getWorkspaceUsers,
    updateWorkspace,
    verifyWorkspaceDomain,
    parseSamlMetadata,
    disconnectWorkspaceIntegration,
    connectWorkspaceIntegration
};

