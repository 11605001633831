import PresentationLibraryController from "js/controllers/PresentationLibraryController";
import AppController from "js/core/AppController";
import { TeamFoldersController } from "js/core/dataServices/TeamFoldersDataService";
import { getPresentation } from "js/core/models/presentation";
import { trackActivity } from "js/core/utilities/utilities";

export class PresentationTemplateController {
    static async getTeamTemplates(templateIds = null, editable = false) {
        if (!templateIds) {
            templateIds = PresentationLibraryController.getPresentations(AppController.workspaceId)
                .filter(presentation => presentation.isTemplate)
                .map(presentation => presentation.id);
        }

        // This is very innefficient, must be refactored in the future
        let templates = await Promise.all(templateIds.map(id => getPresentation(id, { permission: editable ? "write" : "read", autoSync: false })));

        /// We should clean up the mess below in the future...
        templates = templates
            .map(template => {
                let {
                    ratedByUsers = {},
                    createdById,
                    modifiedById,
                    ...rest
                } = template.toJSON();
                let ratedByCount = Object.keys(ratedByUsers).length;
                if (!createdById) {
                    createdById = rest.userId;
                }
                if (!modifiedById) {
                    modifiedById = createdById;
                }

                let item = {
                    template,
                    ...rest,
                    createdById,
                    modifiedById,
                    ratedByUsers,
                    ratedByCount,
                    sips: template.getSips()
                };
                return item;
            });

        return templates;
    }

    static async createPresentationTemplate(presentation, fieldUpdates) {
        // duplicate the presentation
        const duplicatedPresentation = await PresentationLibraryController.duplicatePresentation({
            presentationId: presentation.id,
        });

        await this.convertToPresentationTemplate(duplicatedPresentation, fieldUpdates);

        AppController.showEditor({ presentationId: duplicatedPresentation.id });
    }

    static async convertToPresentationTemplate(presentationTemplate, fieldUpdates = {}) {
        await presentationTemplate.load();

        presentationTemplate.update({
            isTemplate: true,
            isPublished: false,
            _migrated: true,
            ...fieldUpdates,
        });

        await presentationTemplate.updatePromise;
        let allTeamFolder = TeamFoldersController.getAllTeamMembersFolder();
        await TeamFoldersController.setTemplatePermissions([presentationTemplate], allTeamFolder.id);

        trackActivity("PresentationTemplates", "PresentationTemplateAdded", null, null, {});

        return presentationTemplate;
    }

    static async publishPresentationTemplate(template) {
        template.update({ isPublished: true });
    }

    static async unpublishPresentationTemplate(template) {
        template.update({ isPublished: false });
    }

    static async deletePresentationTemplate(template) {
        let allTeamFolder = TeamFoldersController.getAllTeamMembersFolder();
        await TeamFoldersController.deleteTemplatePermissions([template], allTeamFolder.id);
    }
}
