import React, { useEffect, useRef } from "react";

export function useMountedAt() {
    const mountedAtRef = useRef<number>(Date.now());

    useEffect(() => {
        mountedAtRef.current = Date.now();
    }, []);

    return mountedAtRef.current;
}

export function OnMountedReporter({ onMounted }: { onMounted: () => void }) {
    useEffect(() => {
        onMounted();
    }, []);

    return null;
}
