import { v4 as uuid } from "uuid";

import { experiments as experimentsApi } from "apis/callables";
import getLogger, { LogGroup } from "js/core/logger";
import { _ } from "js/vendor";
import getLocalStorage from "js/core/utilities/localStorage";
import { auth } from "js/firebase/auth";

const logger = getLogger(LogGroup.EXPERIMENTS);

const experimentsCache: Record<string, Record<string, { enabled: boolean, wasInitialized: boolean }>> = {};

const localStorage = getLocalStorage();

let browserId = localStorage.getItem("experiments-browser-id");
if (!browserId) {
    browserId = uuid();
    localStorage.setItem("experiments-browser-id", browserId);
}

let getExperimentsPromiseChain = Promise.resolve();
export async function getExperiments(experimentIds: string[]): Promise<Record<string, { enabled: boolean, wasInitialized: boolean }>> {
    return new Promise((resolve, reject) => {
        // Chain to ensure we don't execute similar requests in parallel
        getExperimentsPromiseChain = getExperimentsPromiseChain
            .then(async () => {
                try {
                    const cacheId = auth().currentUser?.uid ?? browserId;

                    if (!experimentsCache[cacheId]) {
                        experimentsCache[cacheId] = {};
                    }

                    let missingExperiments = experimentIds.filter(id => !(id in experimentsCache[cacheId]));

                    //////// Migration ////////
                    const migrationStates: { [experimentId: string]: boolean } = {};
                    ////////////////////////

                    if (missingExperiments.length > 0) {
                        const overrideExperimentsJSON = localStorage.getItem("overrideExperiments");
                        if (overrideExperimentsJSON) {
                            const overrideExperiments: { [experimentId: string]: boolean } = JSON.parse(overrideExperimentsJSON);

                            missingExperiments
                                .filter(experimentId => overrideExperiments[experimentId] !== undefined)
                                .forEach(experimentId => {
                                    migrationStates[experimentId] = overrideExperiments[experimentId];
                                });
                        }

                        const response = await experimentsApi.getExperiments({ ids: missingExperiments, browserId, migrationStates });
                        _.assign(experimentsCache[cacheId], response.experiments);
                    }

                    return _.pick(experimentsCache[cacheId], experimentIds);
                } catch (err) {
                    logger.error(err, "[getExperiments] failed");
                    throw err;
                }
            })
            .then(resolve)
            .catch(reject);
    });
}

/**
 * Initializes all experiments for the current user, moving any experiments that are already initialized for the browser to the user.
 */
export async function initializeAllExperiments() {
    const uid = auth().currentUser?.uid;
    if (!uid) {
        throw new Error("User not logged in");
    }

    //////// Migration ////////
    const migrationStates: { [experimentId: string]: boolean } = {};

    const signUpGroup = localStorage.getItem("signUpGroup");
    if (signUpGroup) {
        migrationStates["sign_up_b"] = signUpGroup === "B";
        localStorage.removeItem("signUpGroup");
    }
    ////////////////////////

    const { experiments } = await experimentsApi.initializeAllExperiments({ browserId, migrationStates });

    return experiments;
}
