import { EndpointDefinition } from "../_sdk/types/common";

export const apiName = "workspaces";

export const endpoints: { [key: string]: EndpointDefinition } = {
    getWorkspacesMetadata: {
        route: "/workspaces/metadata",
        method: "GET"
    },
    getWorkspace: {
        route: "/workspaces/:workspaceId",
        method: "GET"
    },
    getWorkspaceUsers: {
        route: "/workspaces/:workspaceId/users",
        method: "GET"
    },
    updateWorkspace: {
        route: "/workspaces/:workspaceId",
        method: "PUT"
    },
    verifyWorkspaceDomain: {
        route: "/workspaces/:workspaceId/verify-domain",
        method: "POST"
    },
    parseSamlMetadata: {
        route: "/workspaces/:workspaceId/parse-saml-metadata",
        method: "POST"
    },
    disconnectWorkspaceIntegration: {
        route: "/workspaces/:workspaceId/integrations/:integrationType/disconnect",
        method: "POST"
    },
    connectWorkspaceIntegration: {
        route: "/workspaces/:workspaceId/integrations/:integrationType/connect",
        method: "POST"
    }
};
