import moment from "moment";
import React, { useMemo, useState } from "react";
import styled from "styled-components";

import {
    Button,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Icon,
    MenuItem,
    Select as SelectInput
} from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { app } from "js/namespaces";

import { dialogTheme } from "js/react/materialThemeOverrides";

import { BeautifulDialog } from "js/react/components/Dialogs/BaseDialog";
import { RenewSubscription } from "./RenewSubscription";

const Container = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1200;
    background: transparent;
    backdrop-filter: blur(5px);
`;

const AlertContainer = styled.div`
    color: red;
    font-size: 20px;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;

    >div {
        margin-left: 10px;
    }
`;

const StyledLink = styled.a`
    color: #11a9e2;
`;

const WorkspaceSelector = styled(DialogContentText)`
&&& {
    display: flex;
    gap: 5px;
    align-items: center;
}`;

const StyledDialogContent = styled(DialogContent)`
    &&& {
        padding: 0 60px 50px;
    }
`;

const StyledDialogTitle = styled(DialogTitle)`
    &&& {
        padding: 50px 34px 30px;
    }
`;

function WorkspaceLockedDialog({
    subscription,
    canManageTeam,
    contactEmail,
    organizationId,
    modifiedAt,
    onClose
}) {
    const [showPaymentForm, setShowPaymentForm] = useState(false);

    // flag to determine if the user is using legacy workspaces instead of the new workspacesMetadata
    const isLegacyWorkspaces = app.user.workspaces && !app.user.workspacesMetadata;
    const getWorkspaceName = workspace => isLegacyWorkspaces ? workspace.getName() : workspace.name;

    const workspaces = isLegacyWorkspaces ? Object.values(app.user.workspaces) : app.user.workspacesMetadata;
    const currWorkspace = isLegacyWorkspaces ? app.user.workspaces[organizationId] : workspaces.find(workspace => workspace.id === organizationId);
    const teamName = getWorkspaceName(currWorkspace);

    const canceledDate = subscription ? moment.unix(subscription.currentPeriodEnd ?? subscription.current_period_end) : moment(modifiedAt);
    const currentPeriodEnd = canceledDate.format("MMM DD, YYYY");

    const email = contactEmail ?? "support@beautiful.ai";

    const handleSwitchWorkspace = event => {
        // Have to reference appController off app because it may be legacy or non-legacy controller
        app.appController.switchWorkspace(event.target.value);
        onClose();
    };

    return (
        <Container>
            {showPaymentForm &&
                <RenewSubscription subscription={subscription} orgId={organizationId} teamName={teamName} />
            }
            {!showPaymentForm &&
                <MuiThemeProvider theme={dialogTheme}>
                    <BeautifulDialog maxWidth="xl" fullWidth={false} hideBackdrop closeDialog={() => { }}>
                        <StyledDialogTitle>
                            <AlertContainer>
                                <Icon>error</Icon>
                                <div> {teamName}'s subscription has been canceled</div>
                            </AlertContainer>
                        </StyledDialogTitle>
                        <StyledDialogContent>
                            {canManageTeam && <DialogContentText>
                                <span>The {teamName} team’s subscription ended on {currentPeriodEnd}.</span>
                                <br />
                                <Button variant="text" onClick={() => setShowPaymentForm(true)}>reactivate your subscription</Button> to unlock your workspace.
                            </DialogContentText>}
                            {!canManageTeam && <DialogContentText>
                                The {teamName} team’s subscription ended on {currentPeriodEnd}.
                                <br />
                                Please contact a team owner to reactivate your account.
                            </DialogContentText>}
                            <WorkspaceSelector component="div">
                                <b>Access your other workspaces to continue using Beautiful.ai:</b>
                                <SelectInput
                                    value={organizationId}
                                    onChange={handleSwitchWorkspace}
                                >
                                    {workspaces.map((workspace, index) => (
                                        <MenuItem key={index} value={workspace.id}>{getWorkspaceName(workspace)}</MenuItem>
                                    ))}
                                </SelectInput>
                            </WorkspaceSelector>
                            <DialogContentText>
                                If you have any questions, reach out to <StyledLink href={`mailto:${email}`}>{email}</StyledLink>.
                            </DialogContentText>
                        </StyledDialogContent>
                    </BeautifulDialog>
                </MuiThemeProvider>
            }
        </Container >
    );
}

export default WorkspaceLockedDialog;
