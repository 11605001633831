import React from "react";
import styled, { keyframes } from "styled-components";
import sanitizeHtml from "sanitize-html";
import { Icon } from "@material-ui/core";

import * as geom from "js/core/utilities/geom";
import { AdvanceSlideOnType, CANVAS_WIDTH, CANVAS_HEIGHT, AssetType } from "common/constants";
import { Key } from "js/core/utilities/keys";
import { _, Hammer, $ } from "js/vendor";
import { getStaticUrl, isOfflinePlayer } from "js/config";
import { requestWakeLock, releaseWakeLock } from "js/core/utilities/wakeLock";
import { app } from "js/namespaces";
import { RemoveSplashScreen } from "js/core/SplashScreen";
import { ds } from "js/core/models/dataService";
import { unlockAudio } from "js/core/utilities/audioUtilities";
import { FlexSpacer } from "js/react/components/Gap";
import CommentsPane from "js/react/views/CommentsPane/CommentsPane";
import { mountAuth } from "js/react/components/Dialogs/mountAuth";
import pusher from "js/core/services/pusher";
import { SlideNotes } from "js/react/views/Player/Components/SlideNotes";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import { RemoteControlDialog } from "js/react/views/Player/MeetingRoom";
import { trackActivity } from "js/core/utilities/utilities";

import PlayerControls, { ExternalTransitions, ControlStates } from "./PlayerControls";
import MobilePlayerControls from "./MobilePlayerControls";
import ThumbnailsPane from "./ThumbnailsPane";
import CanvasView from "./CanvasView";
import StartPage from "./StartPage";
import SignupButton from "./SignupButton";
import EndPage from "./EndPage";
import PresentationActionsMenu from "./PresentationActionsMenu";
import { exitFullscreen, enterFullscreen, isFullscreen } from "../helpers/fullscreen";
import { PlayerUnmountTrigger } from "../helpers/analytics";
import { delay } from "js/core/utilities/promiseHelper";
import DrawingOverlay from "./DrawingOverlay";

const PlayerContainer = styled.div.attrs(({ cursor = "default", appHeightPx = null }) => ({
    style: {
        cursor,
        height: appHeightPx ? `${appHeightPx}px` : "100%"
    }
}))`
  width: 100vw;
  background: black;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
  transition: opacity 333ms;

  div {
    box-sizing: border-box;
  }
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const CommentsContainer = styled.div`
  flex-grow: 0;
  background: #292929;
  position: relative;
  width: 300px;
  height: 100%;
  padding: 5px 0px 40px 15px;
  transition: opacity 333ms;
`;

const SlideNotesContainer = styled.div`
  flex-grow: 0;
  background: #292929;
  position: relative;
  width: 300px;
  height: 100%;
  padding: 20px;
  transition: opacity 333ms;
  color: white;
`;

const CommentCloseButton = styled(Icon)`
  position: absolute;
  top: 7px;
  right: 7px;
  padding: 5px;
  font-size: 30px;
  color: white;
  cursor: pointer;
`;

const CanvasContainerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 333ms;
`;

const RemoteControlBanner = styled.div`
  padding: 40px;
  text-align: center;
  background: orangered;
  color: white;
  font-size: 3.2em;
  width: 90%;
  font-weight: 600;
  margin-bottom: 50px;
  text-transform: uppercase;
  border-radius: 0.2em;
`;

const CanvasContainer = styled.div.attrs(({ widthPercents, heightPercents }) => ({
    style: {
        width: `${widthPercents}%`,
        height: `${heightPercents}%`
    }
}))`
  position: relative;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const BlankScreen = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1000;
  background: black;
  animation: ${fadeIn} 333ms linear;
`;

const BrandingContainer = styled.div`
  width: 100%;
  height: ${({ isMobileOrTablet }) => isMobileOrTablet ? "116px" : "58px"};
  background: #222;
  padding-right: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;

    > span {
      color: #999;
      text-transform: uppercase;
      letter-spacing: ${({ isMobileOrTablet }) => isMobileOrTablet ? "4px" : "2px"};
      font-size: ${({ isMobileOrTablet }) => isMobileOrTablet ? "26px" : "13px"};
      margin: 0 ${({ isMobileOrTablet }) => isMobileOrTablet ? "40px" : "15px"};
    }

    > a {
      cursor: pointer;

      img {
        width: ${({ isMobileOrTablet }) => isMobileOrTablet ? "280px" : "140px"};
      }
    }
  }
`;

const REMOTE_CONTROL_TIMEOUT_IN_MINUTES = 30;

export function PlayerBranding({ isLoggedIn, isMobileOrTablet }) {
    return (
        <BrandingContainer isMobileOrTablet={isMobileOrTablet}>
            <div>
                <span>made with</span>
                <a href="/?">
                    <img src={getStaticUrl("/images/beautifulai-logos/beautifulai-logo-reverse.svg")}></img>
                </a>
            </div>
            {!isLoggedIn && <FlexSpacer />}
            {!isLoggedIn && <SignupButton style={{ height: "34px" }} />}
        </BrandingContainer>
    );
}

class PlayerView extends React.Component {
    constructor(props) {
        super(props);

        // Will have to adjust start slide index with respect of skipped slides
        const {
            startSlideIndex,
            presentation
        } = props;

        this.wakeLock = null;

        const allPresentationSlideIds = presentation.getSips();

        let slideIndex = Math.clamp(startSlideIndex ?? 0, 0, allPresentationSlideIds.length - 1);
        let startSlideId = allPresentationSlideIds[slideIndex];

        // If start slide index points to a skipped slide, so will start playing from the next unskipped slide
        while (this.skippedSlideIds.includes(startSlideId)) {
            slideIndex++;
            startSlideId = allPresentationSlideIds[slideIndex % allPresentationSlideIds.length];
        }

        this.state = {
            currentSlideIndex: presentation.getSips(this.skippedSlideIds).indexOf(startSlideId),
            currentSlidePlaybackStageIndex: 0,
            hasFirstCanvasRendered: false,
            isThumbnailsPaneOpen: false,
            shouldAnimateCurrentCanvas: false,
            shouldCurrentCanvasAnimationJumpToNextWaitForClick: false,
            shouldTransitionToCurrentCanvas: false,
            isCurrentCanvasAnimating: false,
            isCurrentCanvasWaitingForClick: false,
            isWaitingToAdvanceAfterDelay: false,
            cursor: "none",
            showEndPage: false,
            showStartPage: false,
            hasApprovedStart: false,
            isFullscreen: isFullscreen(),
            canvasScale: 1,
            canvasContainerWidthPercents: 100,
            canvasContainerHeightPercents: 100,
            appHeightPx: null,
            isMobileLandscape: window.innerWidth > window.innerHeight,
            isSoundtrackPlaying: false,
            showBlankScreen: false,
            userHasInteracted: false,
            actionsMenuIsOpen: false,
            slideHasFullScreenVideo: false,
            showComments: false,
            showNotes: false,
            allowLocalControl: true,
            showContextMenu: false,
            contextMenuPositionX: 0,
            contextMenuPositionY: 0,
            isPinching: false,
            isDrawing: false,
            lastScale: 1,
            lastPosX: 0,
            lastPosY: 0,
            hasDoubleTapped: false,
            doubleClicked: false,
            showControls: true,
            controlState: ControlStates.CONTRACTED,
            playerControlTransition: null,
        };
        this.blockClickCounter = 0;

        this.advanceDelayTimeout = null;

        this.presenterWindow = null;
        this.presenterMountPromise = null;
        this.activePresenter = null;
        this.presenterWasUsed = false;

        this.canvasViewRefs = {};
        this.canvasContainerWrapperRef = React.createRef();

        this.slidesPlaybackStagesCounts = {};

        this.playbackStartedAt = null;

        this.onExitTrigger = null;

        this.slidesPlaybackTimesMs = presentation.getSips().reduce((times, slideId) => ({
            ...times,
            [slideId]: 0
        }), {});
        this.currentSlideStartedPlayingAt = null;

        this.hammer = null;

        this.animationHasFutureWaitForClick = false;
        this.continueCurrentCanvasAnimationOnClick = null;
    }

    get skippedSlideIds() {
        const { slidesMetadata } = this.props;
        return Object.entries(slidesMetadata).map(([slideId, { isSkipped }]) => isSkipped ? slideId : null).filter(slideId => !!slideId);
    }

    get currentCanvas() {
        return this.canvasViewRefs[this.state.currentSlideIndex]?.current?.canvas;
    }

    get loadedCanvases() {
        return Object.values(this.canvasViewRefs)
            .map(x => x?.current?.canvas)
            .filter(x => !!x);
    }

    async componentDidMount() {
        const {
            isMobileOrTablet,
            parentPresenter,
        } = this.props;

        if (!parentPresenter) {
            window.addEventListener("keydown", this.onKeyDown);
        }
        window.addEventListener("mousemove", this.onMouseMove);

        this.hideMouse = _.debounce(() => {
            if (!this.state.showStartPage && !this.state.showEndPage && !this.state.isThumbnailsPaneOpen) {
                this.setState({
                    cursor: "none"
                });
            }
        }, 1000);

        this.hideMouse();

        window.openPresenter = this.openPresenter;

        document.addEventListener("fullscreenchange", this.onFullscreenChange);

        window.addEventListener("blur", this.onWindowBlur);
        window.addEventListener("focus", this.onWindowFocus);

        window.addEventListener("beforeunload", this.onBeforeWindowUnload);
        window.addEventListener("popstate", this.onPopState);

        window.addEventListener("resize", this.onWindowResize);

        if (isMobileOrTablet) {
            // If the user needs to insert email to view the presentation, we need to perform an action
            // so the view can "refresh", otherwise the layout will be broken because of the keyboard being open
            // for the email input
            window.scrollTo(0, 0);

            this.hammer = new Hammer(document.body, {});
            this.hammer.get("pinch").set({ enable: true });
            this.hammer.get("pan").set({ direction: Hammer.DIRECTION_ALL });
            this.hammer.get("swipe").set({ direction: Hammer.DIRECTION_ALL });
            this.hammer.get("doubletap").set({ enable: true });

            this.hammer.on("swipe", this.onSwipe);

            let lastCenter = null;
            let posX = 0, posY = 0;

            this.hammer.on("pinchstart", event => {
                this.setState({ isPinching: true });
                lastCenter = event.center;
            });

            this.hammer.on("pinchmove", event => {
                const scale = Math.max(1, Math.min(this.state.lastScale * event.scale, 4));
                const { width, height } = this.canvasContainerWrapperRef.current.getBoundingClientRect();
                const maxTranslateX = width * (scale - 1);
                const maxTranslateY = height * (scale - 1);
                // If there's a significant center movement, adjust for panning
                if (lastCenter) {
                    const deltaX = (event.center.x - lastCenter.x) / scale;
                    const deltaY = (event.center.y - lastCenter.y) / scale;

                    posX = Math.max(-maxTranslateX, Math.min(this.state.lastPosX + deltaX, maxTranslateX));
                    posY = Math.max(-maxTranslateY, Math.min(this.state.lastPosY + deltaY, maxTranslateY));
                }

                // Apply combined transformations
                this.canvasContainerWrapperRef.current.style.transform = `scale(${scale}) translate(${posX}px, ${posY}px)`;
            });

            this.hammer.on("pinchend", event => {
                // Reset last center for the next gesture
                lastCenter = null;
                this.setState({ isPinching: false, lastScale: Math.max(1, Math.min(this.state.lastScale * event.scale, 4)), lastPosX: posX, lastPosY: posY });
            });

            this.hammer.on("panstart", event => {
                if (this.state.lastScale > 1) {
                    lastCenter = event.center;
                }
            });

            this.hammer.on("panmove", event => {
                if (this.state.lastScale > 1) {
                    const scale = this.state.lastScale;
                    const { width, height } = this.canvasContainerWrapperRef.current.getBoundingClientRect();
                    const maxTranslateX = width * (scale - 1);
                    const maxTranslateY = height * (scale - 1);
                    const deltaX = (event.center.x - lastCenter.x) / scale;
                    const deltaY = (event.center.y - lastCenter.y) / scale;

                    posX = Math.max(-maxTranslateX, Math.min(this.state.lastPosX + deltaX, maxTranslateX));
                    posY = Math.max(-maxTranslateY, Math.min(this.state.lastPosY + deltaY, maxTranslateY));

                    this.canvasContainerWrapperRef.current.style.transform = `scale(${scale}) translate(${posX}px, ${posY}px)`;
                }
            });

            this.hammer.on("panend", event => {
                if (this.state.lastScale > 1) {
                    lastCenter = null;
                    this.setState({ lastPosX: posX, lastPosY: posY });
                }
            });

            this.hammer.on("doubletap", event => {
                clearTimeout(this.clickTimeout);
                let scale, posX, posY;
                if (this.state.hasDoubleTapped) {
                    // If the user has double-tapped before, reset the scale and position
                    scale = 1;
                    posX = 0;
                    posY = 0;
                    this.setState({ hasDoubleTapped: false });
                } else {
                    // If the user hasn't double-tapped before, double the current scale
                    scale = Math.max(1, Math.min(this.state.lastScale * 2, 4));
                    posX = this.state.lastPosX;
                    posY = this.state.lastPosY;
                    this.setState({ hasDoubleTapped: true });
                }
                this.canvasContainerWrapperRef.current.style.transform = `scale(${scale}) translate(${posX}px, ${posY}px)`;
                this.setState({ lastScale: scale, lastPosX: posX, lastPosY: posY, doubleClicked: true });
            });

            let lastWidth = window.innerWidth;
            let lastHeight = window.innerHeight;
            this.rotationInterval = setInterval(() => {
                if (lastWidth !== window.innerWidth || lastHeight !== window.innerHeight) {
                    lastWidth = window.innerWidth;
                    lastHeight = window.innerHeight;

                    this.onWindowResize();
                }
            }, 500);
        }

        // Calling on resize to adjust sizes including canvas container
        this.onWindowResize();

        if (window.roomID) {
            this.setupRemoteControl();
        }

        // Notify of the starting slide
        {
            const slide = this.getSlideAtIndex(this.state.currentSlideIndex);
            const event = new CustomEvent("player:change:slide", { detail: { slide } });
            document.dispatchEvent(event);
            ds.getObservables().setCommentsAreByViewer(true);
        }
    }

    async setupRemoteControl() {
        const { presentation } = this.props;

        if (window.roomID) {
            // trigger a client-registerLeader event so that any meeting rooms are notified that a presentation has started
            this.remoteChannel = await pusher.subscribe(`presence-remote-control-${window.roomID}`);

            if (window.remoteRole == "leader") {
                // register ourselves as leader
                this.remoteChannel.trigger("client-registerLeader", { presentationId: presentation.id });

                // listen for followers after we've loaded and send a client-go-to-slide so they can get up to datre
                this.remoteChannel.bind("pusher:member_added", () => {
                    this.remoteChannel.trigger("client-go-to-slide", this.state.currentSlideIndex.toString());
                });

                this.setState({ isRemoteControlLeader: true });
            } else {
                // listen to go-to-slide events and go to the new slide
                this.remoteChannel.bind("client-go-to-slide", props => {
                    switch (props.type) {
                        case "slide":
                            this.onRemoteSlideChange(props);
                            break;
                        case "build":
                            if (this.state.isCurrentCanvasWaitingForClick) {
                                this.setState({ isCurrentCanvasWaitingForClick: false }, () => {
                                    this.continueCurrentCanvasAnimationOnClick();
                                    this.continueCurrentCanvasAnimationOnClick = null;
                                });
                            }
                            break;
                    }

                    this.setRemoteControlTimeout();
                });

                // listen for endPresentation event and return to meetingRoom url
                this.remoteChannel.bind("client-endPresentation", () => {
                    window.location.href = `${window.location.origin}/meetingRoom?roomID=${window.roomID}`;
                });

                this.setState({
                    isRemoteControlFollower: true,
                    allowLocalControl: false   // disable local control for meeting followers
                });

                this.setRemoteControlTimeout();
            }
        }
    }

    setRemoteControlTimeout() {
        if (this.remoteControlTimeout) {
            clearTimeout(this.remoteControlTimeout);
        }
        this.remoteControlTimeout = setTimeout(() => {
            window.location.href = `${window.location.origin}/meetingRoom?roomID=${window.roomID}`;
        }, REMOTE_CONTROL_TIMEOUT_IN_MINUTES * 60 * 1000);
    }

    onRemoteSlideChange = ({ slideIndex, shouldAnimate = true, shouldTransition = true, playbackStageIndex = 0 }) => {
        this.goToSlide(parseInt(slideIndex), shouldAnimate, parseInt(playbackStageIndex), shouldTransition);
    }

    handleEndRemotePlay = () => {
        // trigger the endPresentation event so any followers return tot he meeting room url
        this.remoteChannel.trigger("client-endPresentation", "");

        // return us to the presentation library
        window.location.href = `${window.location.origin}?roomID=${window.roomID}`;
    }

    // handles clicking the Control From Phone button in PlayerControls
    handleControlRemotely = () => {
        let { presentation } = this.props;
        let { currentSlideIndex } = this.state;

        ShowDialog(RemoteControlDialog, {
            presentationId: presentation.id,
            currentSlideIndex,
            onRemoteControlLeaderRegistered: roomID => {
                window.roomID = roomID;
                let url = `${window.location.origin}/${presentation.id}?roomID=${roomID}`;
                history.pushState({}, window.location.title, url);
                this.setupRemoteControl();
            }
        });
    }

    componentWillUnmount() {
        const {
            analytics,
            isMobileOrTablet,
            username,
        } = this.props;
        const {
            currentSlideIndex
        } = this.state;

        if (this.remoteChannel) {
            pusher.unsubscribe(this.remoteChannel.name);
        }

        if (this.wakeLock) {
            releaseWakeLock(this.wakeLock).then(() => {
                this.wakeLock = null;
            });
        }

        window.removeEventListener("keydown", this.onKeyDown);
        window.removeEventListener("mousemove", this.onMouseMove);

        if (this.presenterWindow) {
            this.presenterWindow.close();
        }

        this.hideMouse.cancel();

        window.openPresenter = null;

        document.removeEventListener("fullscreenchange", this.onFullscreenChange);

        window.removeEventListener("blur", this.onWindowBlur);
        window.removeEventListener("focus", this.onWindowFocus);

        window.removeEventListener("beforeunload", this.onBeforeWindowUnload);
        window.removeEventListener("popstate", this.onPopState);

        window.removeEventListener("resize", this.onWindowResize);

        this.stopSoundtrack();

        if (isMobileOrTablet) {
            this.hammer.off("swipe", this.onSwipe);
            this.hammer.destroy();
            clearInterval(this.rotationInterval);
        }

        analytics.trackPlayerUnmount({
            playerUnmountTrigger: this.onExitTrigger,
            slidesPlaybackTimesMs: this.slidesPlaybackTimesMs,
            slideId: this.getSlideAtIndex(currentSlideIndex).id,
            username,
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // if (prevState.currentSlideIndex !== this.state.currentSlideIndex) {
        const slide = this.getSlideAtIndex(this.state.currentSlideIndex);
        const event = new CustomEvent("player:change:slide", { detail: { slide } });
        document.dispatchEvent(event);
        // }
    }

    trackSlideNavigation = ({ method, deviceType }) => {
        const { analytics, username } = this.props;
        return analytics.trackPlaybackNavigated({ method, deviceType, username });
    }

    onSwipe = ({ direction }) => {
        const { showStartPage, showEndPage, hasFirstCanvasRendered, isThumbnailsPaneOpen, isPinching, lastScale } = this.state;

        if (lastScale > 1) {
            return;
        }

        if (isPinching) {
            return;
        }

        if (!hasFirstCanvasRendered) {
            return;
        }

        if ([Hammer.DIRECTION_LEFT, Hammer.DIRECTION_RIGHT].includes(direction)) {
            if (showStartPage || showEndPage || isThumbnailsPaneOpen) {
                return;
            }

            this.rootAdvanceToSlide(direction === Hammer.DIRECTION_LEFT ? 1 : -1);
            this.trackSlideNavigation({ method: "swipe", deviceType: "mobile" });
        } else if (direction === Hammer.DIRECTION_UP && !isThumbnailsPaneOpen) {
            this.showThumbnailsPane();
        } else if (direction === Hammer.DIRECTION_DOWN && isThumbnailsPaneOpen) {
            this.hideThumbnailsPane();
        }
    }

    onWindowResize = async () => {
        const {
            isMobileOrTablet
        } = this.props;

        if (isMobileOrTablet) {
            // On mobile devices resize is mostly called when the orientation has changed
            // and in some browsers (i.e. Chrome on iOS) there is a slight intermittent delay
            // between the window resize event is fired and the actual window.innerHeight
            // value is set so we have to wait (50ms is an empirically derived value)
            await new Promise(resolve => setTimeout(resolve, 50));
            this.setState({ lastScale: 1, lastPosX: 0, lastPosY: 0 });
            this.canvasContainerWrapperRef.current.style.transform = `scale(${this.state.lastScale}) translate(${this.state.lastPosX}px, ${this.state.lastPosY}px)`;
            $(window).scrollTop(-1);
            // Adjust app height and let the component refresh to allow the canvas wrapper
            // to get its new size
            await this.setStateAsync({
                appHeightPx: window.innerHeight,
                isMobileLandscape: window.innerWidth > window.innerHeight
            });
        }

        // Adjusting canvas container
        let canvasScale = 1;
        let canvasContainerWidthPercents = 100;
        let canvasContainerHeightPercents = 100;

        // Since this callback is called upon window.resize, there's a chance canvas container
        // wrapper hasn't rendered yet, so we're accounting for that
        if (this.canvasContainerWrapperRef.current) {
            const { width, height } = this.canvasContainerWrapperRef.current.getBoundingClientRect();
            const targetAspectRatio = CANVAS_WIDTH / CANVAS_HEIGHT;
            const wrapperAspectRatio = width / height;
            // Adjusting canvas container size to enforce canvas aspect ratio
            if (wrapperAspectRatio > targetAspectRatio) {
                canvasContainerWidthPercents *= targetAspectRatio / wrapperAspectRatio;
            } else if (wrapperAspectRatio < targetAspectRatio) {
                canvasContainerHeightPercents *= wrapperAspectRatio / targetAspectRatio;
            }

            // Fit in canvas into the wrapper (will eventually match the container size)
            canvasScale = Math.min(width / CANVAS_WIDTH, height / CANVAS_HEIGHT);
        }

        this.setState({
            canvasScale,
            canvasContainerWidthPercents,
            canvasContainerHeightPercents
        });
    }

    onWindowBlur = () => {
        // Record current slide playback time and pause counting further
        this.recordCurrentSlidePlaybackTime();
    }

    onWindowFocus = () => {
        const { hasFirstCanvasRendered } = this.state;

        if (
            hasFirstCanvasRendered &&
            !this.currentSlideStartedPlayingAt
        ) {
            // Resume counting current slide playback time
            this.currentSlideStartedPlayingAt = Date.now();
        }
    }

    onBeforeWindowUnload = () => {
        const {
            analytics,
            username,
        } = this.props;
        const { currentSlideIndex } = this.state;

        analytics.trackPlayerUnmount({
            playerUnmountTrigger: PlayerUnmountTrigger.WINDOW_UNLOAD,
            slidesPlaybackTimesMs: this.slidesPlaybackTimesMs,
            slideId: this.getSlideAtIndex(currentSlideIndex).id,
            username,
        });
    }

    onPopState = () => {
        const { onExit } = this.props;
        if (app?.router && onExit) {
            this.onExit(PlayerUnmountTrigger.BROWSER_BACK_BUTTON);
        }
    }

    /**
     * NOTE: this callback won't be called on resize in electron
     */
    onFullscreenChange = async () => {
        await this.setStateAsync({ isFullscreen: !!document.fullscreenElement });
        // Triggering on resize callback once more in order to recalc canvas scale if there were
        // changes in the UI (e.g. deck view got enabled)
        await this.onWindowResize();
    }

    setStateAsync(stateUpdates) {
        return new Promise(resolve => this.setState(stateUpdates, () => resolve()));
    }

    // Due to Backbone, Canvas has it's own React tree separate from other React trees.
    //   When we need to stopPropagation across React trees, we need to use this hack.
    blockClickUntilNextFrame = async () => {
        ++this.blockClickCounter;

        await delay(0);

        --this.blockClickCounter;
    }

    onClick = event => {
        if (this.blockClickCounter) {
            return;
        }
        if (this.state.isDrawing) {
            return;
        }

        const {
            presentation,
            isMobileOrTablet
        } = this.props;
        const {
            userHasInteracted,
            controlState,
            allowLocalControl,
            isPinching,
            lastScale,
            doubleClicked
        } = this.state;

        if (doubleClicked) {
            this.setState({ doubleClicked: false });
            return;
        }

        if (lastScale > 1 || isPinching || !allowLocalControl) {
            return;
        }

        if (!userHasInteracted) {
            this.setState({
                userHasInteracted: true,
                playerControlTransition: ExternalTransitions.MOUSE_CLICK_ON_CANVAS
            });
        }

        if (controlState === ControlStates.EXPANDED) {
            // Collapse the controls if expanded and do nothing else
            this.setState({ playerControlTransition: ExternalTransitions.MOUSE_CLICK_ON_CANVAS });
            event.stopPropagation();
            return;
        }

        // closest() will also return target if it is a link
        if (event.target && $(event.target).closest("a").length > 0) {
            // Pass through link clicks
            return;
        }

        // Extract because we want to access these field after the event has terminated
        const {
            pageX,
            pageY,
        } = event;

        if (this.currentCanvas) {
            const interactiveElement = this.currentCanvas.findInteractiveElementsAtPoint(event.pageX, event.pageY)?.[0];
            if (interactiveElement) {
                let action;
                if (this.currentCanvas.model.version > 9) {
                    if (interactiveElement.isInstanceOf("TextElement")) {
                        let block = this.currentCanvas.findTextBlockAtPoint(interactiveElement, event.pageX, event.pageY);
                        if (block) {
                            action = {
                                type: "navigate",
                                id: block.model.linkToSlide
                            };
                        }
                    } else if (interactiveElement.isInstanceOf("Table")) {
                        let cell = interactiveElement.findCellAtPoint(event.pageX, event.pageY);
                        if (cell) {
                            if (cell.model.link) {
                                action = {
                                    type: "link",
                                    href: cell.model.link
                                };
                            } else if (cell.model.linkToSlide) {
                                action = {
                                    type: "navigate",
                                    id: cell.model.linkToSlide
                                };
                            }
                        }
                    } else {
                        action = interactiveElement.interactiveAction;
                    }
                }

                if (action) {
                    if (action.type === "link") {
                        presentation.openExternalUrl(action.href);
                        return;
                    } else if (action.type === "navigate") {
                        const targetSlideId = action.id;
                        const slideIds = presentation.getSips(this.skippedSlideIds);
                        const slideIndex = slideIds.indexOf(targetSlideId);
                        if (slideIndex > -1) {
                            this.rootGoToSlide(slideIndex, true);
                            const deviceType = isMobileOrTablet ? "mobile" : "desktop";
                            this.trackSlideNavigation({ method: "tap", deviceType });
                            return;
                        }
                    } else if (action.type === "video") {
                        // Do nothing
                        return;
                    }
                }
            }
        }

        event.stopPropagation();
        event.persist();

        this.clickTimeout = setTimeout(() => {
            if (this.currentCanvas) {
                const interactiveElement = this.currentCanvas.findInteractiveElementsAtPoint(event.pageX, event.pageY)?.[0];
                if (interactiveElement) {
                    let action;
                    if (this.currentCanvas.model.version > 9) {
                        if (interactiveElement.isInstanceOf("TextElement")) {
                            let block = this.currentCanvas.findTextBlockAtPoint(interactiveElement, pageX, pageY);
                            if (block) {
                                action = {
                                    type: "navigate",
                                    id: block.model.linkToSlide
                                };
                            }
                        } else if (interactiveElement.isInstanceOf("Table")) {
                            let cell = interactiveElement.findCellAtPoint(pageX, pageY);
                            if (cell) {
                                if (cell.model.link) {
                                    action = {
                                        type: "link",
                                        href: cell.model.link
                                    };
                                } else if (cell.model.linkToSlide) {
                                    action = {
                                        type: "navigate",
                                        id: cell.model.linkToSlide
                                    };
                                }
                            }
                        } else {
                            action = interactiveElement.interactiveAction;
                        }
                    }

                    if (action) {
                        if (action.type === "link") {
                            presentation.openExternalUrl(action.href);
                            return;
                        } else if (action.type === "navigate") {
                            const targetSlideId = action.id;
                            const slideIds = presentation.getSips(this.skippedSlideIds);
                            const slideIndex = slideIds.indexOf(targetSlideId);
                            if (slideIndex > -1) {
                                this.rootGoToSlide(slideIndex, true);
                                const deviceType = isMobileOrTablet ? "mobile" : "desktop";
                                this.trackSlideNavigation({ method: "tap", deviceType });
                                return;
                            }
                        } else if (action.type === "video") {
                            // Do nothing
                            return;
                        }
                    }
                }
            }

            this.rootAdvanceToSlide(1);
        }, 250);
    }

    onKeyDown = event => {
        const {
            onExit,
            presentation,
            skippedSlideIds
        } = this.props;
        const {
            hasFirstCanvasRendered,
            isDrawing,
            isWaitingToAdvanceAfterDelay,
            isCurrentCanvasAnimating,
            isThumbnailsPaneOpen,
            userHasInteracted,
            showStartPage,
            showEndPage,
            showBlankScreen,
            allowLocalControl,
        } = this.state;

        if (!hasFirstCanvasRendered) {
            return;
        }

        if (!allowLocalControl) {
            return;
        }

        // If we have a start page overlay prevent users from using the arrow keys to navigate
        // until they have clicked the start button
        if (showStartPage) {
            return;
        }

        if (showBlankScreen) {
            this.setState({ showBlankScreen: false });
            return;
        }

        // Ignore hotkeys if we're inputing text (like for a comment)
        const textInputHasFocus = document.activeElement?.isContentEditable;
        if (textInputHasFocus) {
            return;
        }

        this.handleToggleActionsMenu(false);

        const key = event.which;
        if (key === Key.KEY_B || key === Key.PERIOD) {
            if (isThumbnailsPaneOpen || showEndPage || showStartPage) return;
            this.setState({ showBlankScreen: true });
            return;
        }

        if (key === Key.SPACE) {
            if (isThumbnailsPaneOpen) {
                this.hideThumbnailsPane();
            } else {
                this.showThumbnailsPane();
            }
            return;
        }

        if (key === Key.ESCAPE) {
            event.stopPropagation();

            if (isDrawing) {
                this.setState({ isDrawing: false });
            }

            if (isWaitingToAdvanceAfterDelay) {
                // Cancelling the auto advance countdown
                clearTimeout(this.advanceDelayTimeout);
                this.setState({ isWaitingToAdvanceAfterDelay: false });
            } else if (isCurrentCanvasAnimating) {
                // Requesting animation stop
                this.setState({ shouldAnimateCurrentCanvas: false, isCurrentCanvasWaitingForClick: false }, () => {
                    this.continueCurrentCanvasAnimationOnClick = null;
                });
            } else if (isFullscreen()) {
                exitFullscreen();
            } else if (onExit) {
                this.onExit(PlayerUnmountTrigger.ESC_KEY);
            }
            return;
        }

        if ([Key.LEFT_ARROW, Key.RIGHT_ARROW, Key.PAGE_UP, Key.PAGE_DOWN, Key.UP_ARROW, Key.DOWN_ARROW, Key.RIGHT_ARROW].includes(key)) {
            event.stopPropagation();

            if (!userHasInteracted) {
                this.setState({
                    userHasInteracted: true
                });
            }

            if ([Key.LEFT_ARROW, Key.PAGE_UP, Key.UP_ARROW].includes(key) && showEndPage) {
                this.setState({ showEndPage: false });
                return;
            }

            this.rootAdvanceToSlide([Key.LEFT_ARROW, Key.PAGE_UP, Key.UP_ARROW].includes(key) ? -1 : 1);
            this.trackSlideNavigation({ method: "tab", deviceType: "desktop" });
            return;
        }

        if (key === Key.HOME) {
            this.rootGoToSlide(0);
            return;
        }

        if (key === Key.END) {
            const slidesCount = presentation.getSips(skippedSlideIds).length;
            this.rootGoToSlide(slidesCount - 1);
        }
    }

    onMouseMove = event => {
        if (!this.currentCanvas) return;

        const {
            showStartPage,
            showEndPage,
            isThumbnailsPaneOpen,
            isCurrentCanvasWaitingForClick,
            slideHasFullScreenVideo,
            playerControlTransition,
            userHasInteracted,
            actionsMenuIsOpen,
            isDrawing
        } = this.state;

        const canvasBounds = geom.Rect.FromBoundingClientRect(this.currentCanvas.$el[0].getBoundingClientRect());

        if (Math.abs(event.screenX - (this.lastMouseX ?? 0)) > 5 || Math.abs(event.screenY - (this.lastMouseY ?? 0)) > 5) {
            if (showStartPage || showEndPage || isThumbnailsPaneOpen) {
                this.setState({
                    cursor: "default"
                });
            } else if (isCurrentCanvasWaitingForClick) {
                this.setState({
                    cursor: "pointer"
                });
            } else {
                this.setState({
                    cursor: "default"
                });
            }

            this.lastMouseX = event.screenX;
            this.lastMouseY = event.screenY;
            this.hideMouse();
        }

        const inControlBounds = (event.pageY > canvasBounds.bottom - 100) && (event.pageY < canvasBounds.bottom);

        if (!showStartPage && !showEndPage && !isThumbnailsPaneOpen && !slideHasFullScreenVideo && !isDrawing) {
            if (inControlBounds) {
                this.setState({ showControls: true,
                    playerControlTransition: ExternalTransitions.MOUSE_IN_BOUNDS });
            } else if (!actionsMenuIsOpen && userHasInteracted) {
                this.setState({ showControls: true,
                    playerControlTransition: ExternalTransitions.MOUSE_OUT_OF_BOUNDS });
            }
        } else if (!isDrawing) {
            this.setState({ showControls: false,
                playerControlTransition: ExternalTransitions.MOUSE_OUT_OF_BOUNDS });
        }
    }

    onContextMenu = event => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            showContextMenu: true,
            contextMenuPositionX: event.pageX,
            contextMenuPositionY: event.pageY
        });
    }

    onCanvasRendered = (slideIndex, playbackStagesCount) => {
        const { onFirstCanvasRendered = () => {} } = this.props;
        const { hasFirstCanvasRendered, currentSlideIndex } = this.state;

        this.slidesPlaybackStagesCounts[slideIndex] = playbackStagesCount;

        if (!hasFirstCanvasRendered && slideIndex === currentSlideIndex) {
            this.currentSlideStartedPlayingAt = Date.now();

            this.setState({ hasFirstCanvasRendered: true }, () => {
                onFirstCanvasRendered();
                this.startPresenting(currentSlideIndex);
            });
        }
    }

    onCanvasAnimationProgress = (slideIndex, animationProgress, animationHasFutureWaitForClick) => {
        const { currentSlideIndex } = this.state;
        if (slideIndex !== currentSlideIndex) {
            return;
        }

        this.setState({ shouldCurrentCanvasAnimationJumpToNextWaitForClick: false }, () => {
            this.animationHasFutureWaitForClick = animationHasFutureWaitForClick;
        });
    };

    onCanvasAnimationFinished = (slideIndex, hasBeenCancelled) => {
        const { presentation } = this.props;
        const { currentSlideIndex, isWaitingToAdvanceAfterDelay } = this.state;
        if (slideIndex !== currentSlideIndex) {
            return;
        }

        this.setState({
            shouldAnimateCurrentCanvas: false,
            isCurrentCanvasAnimating: false
        });

        if (hasBeenCancelled || isWaitingToAdvanceAfterDelay) {
            return;
        }

        const slide = this.getSlideAtIndex(slideIndex);
        const advanceOn = slide.get("advanceOn");

        if ((advanceOn === AdvanceSlideOnType.DELAY || presentation.get("autoPlay") === true) && slide.has("audioAsset")) {
            this.rootAdvanceToSlide(1);
        } else if (advanceOn === AdvanceSlideOnType.VIDEO || advanceOn === AdvanceSlideOnType.AUDIO) {
            this.rootAdvanceToSlide(1);
        }
    }

    triggerCurrentSlideAutoAdvance = () => {
        const { presentation } = this.props;
        const { currentSlideIndex } = this.state;

        const slide = this.getSlideAtIndex(currentSlideIndex);
        const advanceOn = slide.get("advanceOn");

        if (advanceOn === AdvanceSlideOnType.DELAY || presentation.get("autoPlay") === true) {
            this.setState({ isWaitingToAdvanceAfterDelay: true });

            const delaySeconds = (advanceOn === AdvanceSlideOnType.DELAY ? slide.get("advanceDelay") : presentation.get("autoPlayDuration")) ?? 5;
            this.advanceDelayTimeout = setTimeout(() => {
                this.setState({ isWaitingToAdvanceAfterDelay: false }, () => {
                    if (this.state.isCurrentCanvasAnimating) return;
                    this.rootAdvanceToSlide(1);
                });
            }, delaySeconds * 1000);
        }
    }

    /**
     * This won't get called if the animation was cancelled
     */
    onCanvasBuildAnimationFinished = slideIndex => {
        const { currentSlideIndex } = this.state;

        if (slideIndex !== currentSlideIndex) {
            return;
        }

        this.triggerCurrentSlideAutoAdvance();
    }

    /**
     * This only gets called if we explicitly requested playback stage change
     * for the current slide
     */
    onCanvasPlaybackStageChanged = async (slideIndex, playbackStageIndex, prevPlaybackStageIndex) => {
        const { currentSlideIndex, currentSlidePlaybackStageIndex } = this.state;

        if (slideIndex !== currentSlideIndex) {
            return;
        }

        if (slideIndex !== currentSlidePlaybackStageIndex) {
            await this.setStateAsync({ currentSlidePlaybackStageIndex: playbackStageIndex });
        }

        if (prevPlaybackStageIndex + 1 === playbackStageIndex) {
            // Trigger auto advance only if advanced to next stage (not prev)
            this.triggerCurrentSlideAutoAdvance();
        }
    }

    onCanvasAnimationWaitForClick = async slideIndex => {
        const { currentSlideIndex } = this.state;

        if (slideIndex !== currentSlideIndex) {
            return;
        }

        return new Promise(resolve => {
            this.continueCurrentCanvasAnimationOnClick = resolve;
            this.setState({ isCurrentCanvasWaitingForClick: true });
        });
    }

    onExit = trigger => {
        const { onExit } = this.props;

        this.onExitTrigger = trigger;

        if (isFullscreen()) {
            exitFullscreen();
        }

        onExit(trigger);
    }

    startPresenting = async (atSlideIndex = 0) => {
        const {
            openPresenterOnPlaybackStarted,
            enterFullscreenOnPlaybackStarted,
            analytics,
            presentation,
            isPreviewFromEditor,
            username,
        } = this.props;

        const {
            hasApprovedStart,
        } = this.state;

        if (Object.values(this.slidesPlaybackTimesMs).some(playbackTime => playbackTime > 0)) {
            analytics.trackPlaybackReplayed({ username });
        } else {
            analytics.trackPlaybackStarted({ username });
        }

        // Reset the playback time to 0 for all slides
        for (const slideId in this.slidesPlaybackTimesMs) {
            this.slidesPlaybackTimesMs[slideId] = 0;
        }

        RemoveSplashScreen(true);

        await this.goToSlide(atSlideIndex, false, 0, false, false);

        this.setState({
            showEndPage: false
        });

        if (openPresenterOnPlaybackStarted) {
            await this.openPresenter();
        }

        if (!this.wakeLock || this.wakeLock.released) {
            this.wakeLock = await requestWakeLock();
        }

        if (!isFullscreen() && enterFullscreenOnPlaybackStarted) {
            enterFullscreen();
            // Wait for window to maximize
            await new Promise(resolve => setTimeout(resolve, 1000));
        }

        const slide = this.getSlideAtIndex(atSlideIndex);

        if (presentation.has("audioAsset") && !slide.hasAudibleVideoAsset()) {
            await this.playSoundtrack();
        }

        if (
            !isPreviewFromEditor &&
            (
                (
                    !hasApprovedStart &&
                    (
                        presentation.get("autoPlay") ||
                        slide.has("audioAsset") ||
                        slide.hasAudibleVideoAsset() ||
                        slide.get("advanceOn") === "delay"
                    )
                )
            )
        ) {
            this.setState({
                showStartPage: true,
            });
        } else {
            this.setState({
                showStartPage: false,
            });
            this.playCurrentSlide();
        }
    }

    playSoundtrack = async () => {
        const { presentation } = this.props;

        const audioAssetId = presentation.get("audioAsset");
        if (!audioAssetId) {
            return;
        }

        const asset = await ds.assets.getAssetById(audioAssetId, AssetType.AUDIO);
        const audioUrl = await asset.getBaseUrl();

        this.soundtrack = new Audio(audioUrl);
        this.soundtrack.play();

        this.setState({ isSoundtrackPlaying: true });
    }

    stopSoundtrack = () => {
        this.soundtrack && this.soundtrack.pause();
        this.setState({ isSoundtrackPlaying: false });
    }

    handleToggleSoundtrack = value => {
        if (value) {
            this.playSoundtrack();
        } else {
            this.stopSoundtrack();
        }
    }

    handlePlayFromStartPage = delta => {
        unlockAudio();
        this.playCurrentSlide(delta);
    }

    playCurrentSlide = (delta = {}) => {
        this.setState({
            shouldAnimateCurrentCanvas: true,
            isCurrentCanvasAnimating: true,
            ...delta,
        });
    }

    onFinishedPresenting = () => {
        const {
            presentation,
            analytics,
            username,
        } = this.props;

        const playbackTimeMs = Object.values(this.slidesPlaybackTimesMs).reduce((totalTime, slideTime) => totalTime + slideTime, 0);

        analytics.trackPlaybackCompleted({
            slidesPlaybackTimesMs: this.slidesPlaybackTimesMs,
            playbackTimeMs,
            presenterWasUsed: this.presenterWasUsed,
            username,
        });

        this.recordCurrentSlidePlaybackTime();

        if (presentation.get("autoLoop") === true) {
            this.startPresenting(0);
            return;
        }

        this.currentCanvas?.stopElements();

        this.closePresenter();

        this.setState({
            showEndPage: true,
            shouldAnimateCurrentCanvas: false,
            showControls: false
        });
    }

    openPresenter = () => {
        const {
            contextType,
            isPresenterAvailable,
            presenterUrl,
            presenterWindowTarget,
            presentation,
            slides,
            slidesMetadata,
            analytics,
            username
        } = this.props;
        const {
            currentSlideIndex,
            currentSlidePlaybackStageIndex
        } = this.state;

        if (!isPresenterAvailable) {
            // Not available
            return;
        }

        if (this.presenterWindow) {
            // Already opened
            return;
        }

        if (!isOfflinePlayer) {
            trackActivity("Player", "PresenterViewOpen", null, null, {}, { audit: false });
        }

        this.presenterWindow = window.open(
            presenterUrl,
            presenterWindowTarget,
            "toolbar=no,scrollbars=no,resizable=yes"
        );

        this.presenterMountPromise = new Promise(resolve => {
            this.presenterWindow.addEventListener("presenterDidMount", ({
                detail: {
                    presenter,
                }
            }) => {
                this.activePresenter = presenter;
                resolve();
            });
        });

        this.presenterWindow.addEventListener("unload", async event => {
            if (event.target.URL !== "about:blank") {
                this.presenterWindow = null;
                this.presenterMountPromise = null;
                this.activePresenter = null;
                await Promise.all(this.loadedCanvases.map(canvas =>
                    canvas.reloadElementsOnPresenterToggle()
                ));
            }
        });

        this.presenterMountPromise = this.presenterMountPromise
            .then(() => Promise.all(this.loadedCanvases.map(canvas =>
                canvas.reloadElementsOnPresenterToggle()
            )))
            .then(() => this.activePresenter.setPresenterProps({
                contextType,
                currentSlideIndex,
                currentSlidePlaybackStageIndex,
                presentation,
                slides,
                slidesMetadata,
                rootPlayerView: this,
                analytics,
                username
            }));

        this.presenterWasUsed = true;

        return this.presenterMountPromise;
    }

    closePresenter = () => {
        if (this.presenterWindow) {
            this.presenterMountPromise
                .then(() => this.presenterWindow.close());
        }
    }

    togglePresenter = () => {
        if (this.presenterWindow) {
            this.closePresenter();
        } else {
            this.openPresenter();
        }
    }

    toggleDrawing = () => {
        this.setState({ isDrawing: !this.state.isDrawing });
    }

    toggleComments = (showComments = null) => {
        if (showComments === null) {
            showComments = !this.state.showComments;
        }

        const toggle = () => {
            this.setState({ showComments });
            window.requestAnimationFrame(this.onWindowResize);
        };

        // If we're trying to show the comments and we don't have a
        //   logged in user, show the auth mount first.
        if (showComments && !app.user) {
            mountAuth({
                onSuccess: () => {
                    toggle();
                }
            });
        } else {
            // Otherwise, just toggle the comments
            toggle();
        }
    }

    toggleNotes = () => {
        let showNotes = !this.state.showNotes;
        this.setState({ showNotes });
        window.requestAnimationFrame(this.onWindowResize);
    };

    getSlideIndex = offsetFromCurrentSlide => {
        const { presentation } = this.props;
        let { currentSlideIndex: slideIndex } = this.state;

        const slidesCount = presentation.getSips(this.skippedSlideIds).length;

        slideIndex = slideIndex + offsetFromCurrentSlide;
        slideIndex = slideIndex % slidesCount;
        if (slideIndex < 0) {
            slideIndex = slidesCount + slideIndex;
        }

        return slideIndex;
    }
    hasOutroAnimation;

    advanceToSlide = async offsetFromCurrentSlide => {
        const { presentation } = this.props;
        const {
            currentSlideIndex,
            currentSlidePlaybackStageIndex,
            isCurrentCanvasAnimating,
            isWaitingToAdvanceAfterDelay,
            isCurrentCanvasWaitingForClick,
            isRemoteControlLeader
        } = this.state;

        this.activePresenter?.advancePresenter(offsetFromCurrentSlide);

        if (!window.isAudioUnlocked) {
            unlockAudio();
        }

        if (isCurrentCanvasWaitingForClick && offsetFromCurrentSlide === 1) {
            this.setState({ isCurrentCanvasWaitingForClick: false }, () => {
                this.continueCurrentCanvasAnimationOnClick();
                this.continueCurrentCanvasAnimationOnClick = null;
            });
            if (this.remoteChannel) {
                this.remoteChannel.trigger("client-go-to-slide", {
                    type: "build"
                });
            }
            return;
        }

        if (this.currentCanvas.hasOutroAnimations() && offsetFromCurrentSlide === 1) {
            await this.currentCanvas.playOutroAnimations();
            this.advanceToSlide(offsetFromCurrentSlide);
        }

        if (isCurrentCanvasAnimating && offsetFromCurrentSlide === 1) {
            if (this.animationHasFutureWaitForClick) {
                // Will jump to next wait for click
                this.setState({ shouldCurrentCanvasAnimationJumpToNextWaitForClick: true });
                return;
            }

            // Will just request animation stop
            this.setState({ shouldAnimateCurrentCanvas: false });
            return;
        }

        if (isWaitingToAdvanceAfterDelay && offsetFromCurrentSlide === 1) {
            // Cancelling the auto advance countdown
            clearTimeout(this.advanceDelayTimeout);
            this.setState({ isWaitingToAdvanceAfterDelay: false });
            return;
        }

        // Imitating slide advance by advancing through playback stages (when > 1)
        const currentSlidePlaybackStagesCount = this.slidesPlaybackStagesCounts[currentSlideIndex];
        if (offsetFromCurrentSlide === 1 && currentSlidePlaybackStageIndex < currentSlidePlaybackStagesCount - 1) {
            this.goToSlide(
                currentSlideIndex,
                false,
                currentSlidePlaybackStageIndex + 1
            );
            return;
        }
        if (offsetFromCurrentSlide === -1 && currentSlidePlaybackStageIndex > 0) {
            this.goToSlide(
                currentSlideIndex,
                false,
                currentSlidePlaybackStageIndex - 1
            );
            return;
        }

        if (!isRemoteControlLeader && currentSlideIndex === presentation.getSips(this.skippedSlideIds).length - 1 && offsetFromCurrentSlide === 1) {
            // show the endPage when navigating past the last slide (unless this is a meeting)
            this.onFinishedPresenting();
            return;
        }

        const advanceToSlideIndex = this.getSlideIndex(offsetFromCurrentSlide);
        if (advanceToSlideIndex === currentSlideIndex + offsetFromCurrentSlide) {
            const targetSlidePlaybackStagesCount = this.slidesPlaybackStagesCounts[advanceToSlideIndex] ?? 1;
            let targetSlidePlaybackStageIndex = 0;
            if (targetSlidePlaybackStagesCount > 1 && offsetFromCurrentSlide < 0) {
                targetSlidePlaybackStageIndex = targetSlidePlaybackStagesCount - 1;
            }
            this.goToSlide(
                advanceToSlideIndex,
                // Animate when advancing to next slide by default
                advanceToSlideIndex === currentSlideIndex + 1,
                targetSlidePlaybackStageIndex
            );
        }
    }

    get rootPlayerView() {
        return this.props.parentPresenter?.props.rootPlayerView || this;
    }

    // Causes the root player to advance to slide, which will then
    //   cascade to all sub players to keep things in sync
    rootAdvanceToSlide = offsetFromCurrentSlide => {
        this.rootPlayerView.advanceToSlide(offsetFromCurrentSlide);
    }

    recordCurrentSlidePlaybackTime = () => {
        const {
            analytics,
            username,
        } = this.props;
        const { currentSlideIndex } = this.state;

        if (this.currentSlideStartedPlayingAt) {
            const playbackTimeMs = Date.now() - this.currentSlideStartedPlayingAt;

            // Calculate and save current slide playback time
            this.slidesPlaybackTimesMs[this.getSlideAtIndex(currentSlideIndex).id] += playbackTimeMs;
            this.currentSlideStartedPlayingAt = null;

            // Reporting current slide viewed
            analytics.trackSlideViewed({
                slideId: this.getSlideAtIndex(currentSlideIndex).id,
                slidesPlaybackTimesMs: this.slidesPlaybackTimesMs,
                playbackTimeMs,
                username,
            });
        }
    }

    goToSlide = async (
        slideIndex,
        shouldAnimate = false,
        playbackStageIndex = 0,
        shouldTransition = true,
    ) => {
        const { currentSlideIndex } = this.state;

        clearTimeout(this.advanceDelayTimeout);

        if (slideIndex !== currentSlideIndex) {
            // Tracking slide playback times
            this.recordCurrentSlidePlaybackTime();
        }

        const slide = this.getSlideAtIndex(slideIndex);
        let slideHasFullScreenVideo = slide.dataState?.template_id == "video" && slide.dataState?.elements?.primary?.fullBleed;

        // Force reset continue animation on click callback
        this.continueCurrentCanvasAnimationOnClick = null;

        const stateUpdate = {
            currentSlideIndex: slideIndex,
            // Force reset playback stage
            currentSlidePlaybackStageIndex: playbackStageIndex,
            // Will animate only when advanced to next slide
            shouldAnimateCurrentCanvas: shouldAnimate,
            isCurrentCanvasAnimating: shouldAnimate,
            // Force reset jump to next wait for click
            shouldCurrentCanvasAnimationJumpToNextWaitForClick: false,
            // Force reset isWaitingToAdvanceAfterDelay...
            isWaitingToAdvanceAfterDelay: false,
            // Force reset waiting for click
            isCurrentCanvasWaitingForClick: false,
            // Force reset show end page
            isDrawing: false,
            showEndPage: false,
            shouldTransitionToCurrentCanvas: shouldTransition,
            slideHasFullScreenVideo
        };
        await this.setStateAsync(stateUpdate);

        // Start counting current slide playback time
        this.currentSlideStartedPlayingAt = Date.now();

        if (this.remoteChannel) {
            // trigger event to push to clients in this meeting room
            this.remoteChannel.trigger("client-go-to-slide", {
                type: "slide",
                slideIndex: slideIndex.toString(),
                shouldAnimate: shouldAnimate,
                shouldTransition: shouldTransition,
                playbackStageIndex: playbackStageIndex.toString()
            });
        }

        if (this.presenterWindow) {
            this.presenterMountPromise = this.presenterMountPromise.then(() =>
                this.activePresenter.setPresenterProps({
                    currentSlideIndex: slideIndex,
                    currentSlidePlaybackStageIndex: playbackStageIndex
                })
            );

            await this.presenterMountPromise;
        }
    }

    // Causes the root player to go to slide, which will then
    //   cascade to all sub players to keep things in sync
    rootGoToSlide = (
        slideIndex,
        shouldAnimate = false,
        playbackStageIndex = 0,
        shouldTransition = true,
    ) => {
        this.rootPlayerView.goToSlide(
            slideIndex,
            shouldAnimate,
            playbackStageIndex,
            shouldTransition,
        );
    }

    getSlideAtIndex = index => {
        const { presentation, slides } = this.props;
        const slideId = presentation.getSips(this.skippedSlideIds)[index];
        return slides[slideId];
    }

    showThumbnailsPane = () => {
        if (!window.isAudioUnlocked) {
            unlockAudio();
        }
        this.setState({
            isThumbnailsPaneOpen: true,
            cursor: "default",
            userHasInteracted: true
        });

        if (!isOfflinePlayer) {
            trackActivity("Player", "ShowGrid", null, null, {}, { audit: false });
        }
    }

    hideThumbnailsPane = () => {
        this.setState({ isThumbnailsPaneOpen: false });

        if (!isOfflinePlayer) {
            trackActivity("Player", "HideGrid", null, null, {}, { audit: false });
        }
    }

    handleToggleActionsMenu = value => {
        this.setState({ actionsMenuIsOpen: value });
    }

    handleUserInteractionWithControls = () => {
        const { userHasInteracted } = this.state;

        if (!userHasInteracted) {
            this.setState({
                userHasInteracted: true,
            });
        }
    }

    render() {
        const {
            isLoggedIn,
            presentation,
            slidesMetadata,
            showBranding,
            isCreatorOldBasicUser,
            slides,
            creator,
            isMobileOrTablet,
            onExit,
            allowSocialSharing,
            onCopyDeck,
            allowCopyLink,
            onDownloadPdf,
            onEditPresentation,
            onSwitchPresentation,
            username,
            parentPresenter
        } = this.props;

        const {
            currentSlideIndex,
            currentSlidePlaybackStageIndex,
            isThumbnailsPaneOpen,
            hasFirstCanvasRendered,
            shouldAnimateCurrentCanvas,
            shouldCurrentCanvasAnimationJumpToNextWaitForClick,
            shouldTransitionToCurrentCanvas,
            cursor,
            showStartPage,
            showEndPage,
            canvasScale,
            canvasContainerHeightPercents,
            canvasContainerWidthPercents,
            appHeightPx,
            isMobileLandscape,
            isSoundtrackPlaying,
            showBlankScreen,
            isDrawing,
            showControls,
            controlState,
            actionsMenuIsOpen,
            slideHasFullScreenVideo,
            showComments,
            showNotes,
            isRemoteControlLeader,
            allowLocalControl,
            showContextMenu,
            contextMenuPositionX,
            contextMenuPositionY,
            playerControlTransition
        } = this.state;

        const indexesToRender = [...new Set(
            // First will render only current canvas,
            // then will be rendering 5 canvases: current and 2 on the left and on the right
            (hasFirstCanvasRendered ? [-2, -1, 0, 1, 2] : [0])
                .map(offset => this.getSlideIndex(offset))
        )].sort();
        const slidesToRender = indexesToRender.map(slideIndex => ({
            slide: this.getSlideAtIndex(slideIndex),
            slideIndex
        }));

        const allowCommenting = !!presentation.attributes.link?.allowCommenting;

        let contentContainerStyles = {};
        let canvasWrapperStyles = {};
        if (isRemoteControlLeader && isMobileOrTablet) {
            contentContainerStyles = {
                paddingTop: "2em",
                justifyContent: "flex-start",
            };

            canvasWrapperStyles = {
                height: "calc(100vw * .5625)"
            };
        }

        const sanitizedSlideNotes = sanitizeHtml(this.getSlideAtIndex(currentSlideIndex).get("slide_notes"), {
            allowedTags: sanitizeHtml.defaults.allowedTags.concat(["strike"])
        });

        const showAuthor = presentation.get("showAuthor");
        const showTitle = presentation.get("showTitle");
        const presentationName = presentation.get("name");

        const controlProps = {
            ...this.props,
            slidesCount: presentation.getSips(this.skippedSlideIds).length,
            currentSlideIndex: currentSlideIndex,
            advanceToSlide: this.rootAdvanceToSlide,
            onExit: onExit ? () => this.onExit(PlayerUnmountTrigger.UI_EXIT_BUTTON) : false,
            showThumbnailsPane: this.showThumbnailsPane,
            togglePresenter: this.togglePresenter,
            toggleDrawing: this.toggleDrawing,
            hasViewerComments: allowCommenting,
            toggleComments: this.toggleComments,
            toggleNotes: this.toggleNotes,
            isSoundtrackPlaying: isSoundtrackPlaying,
            onToggleSoundtrack: this.handleToggleSoundtrack,
            onControlRemotely: this.handleControlRemotely,
            showAuthor: showAuthor,
            showTitle: showTitle,
            presentationName: presentationName,
            creator: creator,
            username: username,
            externalTransition: playerControlTransition,
            isRemoteControlLeader
        };

        return (
            <PlayerContainer
                id="player-container"
                className="PlayerContainer"
                cursor={cursor}
                appHeightPx={appHeightPx}
                style={{ opacity: hasFirstCanvasRendered ? 1 : 0 }}
            >
                <ContentContainer style={contentContainerStyles}>
                    {
                        showBranding &&
                        <PlayerBranding isLoggedIn={isLoggedIn} />
                    }
                    <CanvasContainerWrapper style={canvasWrapperStyles}
                        ref={this.canvasContainerWrapperRef}
                    >
                        <CanvasContainer
                            onClick={this.onClick}
                            widthPercents={canvasContainerWidthPercents}
                            heightPercents={canvasContainerHeightPercents}
                            onContextMenu={this.onContextMenu}
                        >
                            {slidesToRender.map(({ slide, slideIndex }) => {
                                // Retrieve or create the ref for the given slide index
                                const ref = this.canvasViewRefs[slideIndex] || (this.canvasViewRefs[slideIndex] = React.createRef());
                                return (
                                    <CanvasView
                                        key={slide.id}
                                        ref={ref}
                                        canvasScale={canvasScale}
                                        slide={slide}
                                        slideIndex={slideIndex}
                                        playerView={this}
                                        showBranding={showBranding}
                                        isCreatorOldBasicUser={isCreatorOldBasicUser}
                                        advanceToSlide={this.rootAdvanceToSlide}
                                        playTransition={shouldTransitionToCurrentCanvas}
                                        isCurrent={currentSlideIndex === slideIndex}
                                        shouldAnimate={currentSlideIndex === slideIndex && shouldAnimateCurrentCanvas}
                                        shouldJumpToNextWaitForClick={currentSlideIndex === slideIndex && shouldCurrentCanvasAnimationJumpToNextWaitForClick}
                                        playbackStage={currentSlideIndex === slideIndex ? currentSlidePlaybackStageIndex : 0}
                                        ignorePlaybackStage={currentSlideIndex !== slideIndex}
                                        renderClickShieldWhenAnimating={false}
                                        onRendered={playbackStages => this.onCanvasRendered(slideIndex, playbackStages)}
                                        onAnimationStarted={() => { }}
                                        onAnimationProgress={(animationProgress, animationHasFutureWaitForClick) => this.onCanvasAnimationProgress(slideIndex, animationProgress, animationHasFutureWaitForClick)}
                                        onAnimationFinished={hasBeenCancelled => this.onCanvasAnimationFinished(slideIndex, hasBeenCancelled)}
                                        onBuildAnimationFinished={() => this.onCanvasBuildAnimationFinished(slideIndex)}
                                        onPlaybackStageChanged={(playbackStageIndex, prevPlaybackStageIndex) => this.onCanvasPlaybackStageChanged(slideIndex, playbackStageIndex, prevPlaybackStageIndex)}
                                        onWaitForClick={() => this.onCanvasAnimationWaitForClick(slideIndex)}
                                    />
                                );
                            })}
                            {
                                showStartPage &&
                                allowLocalControl &&
                                !parentPresenter &&
                                <StartPage
                                    onPlayButtonClick={() => {
                                        this.handlePlayFromStartPage({
                                            showStartPage: false,
                                            hasApprovedStart: true,
                                        });
                                    }}
                                    showPlayButton
                                    isMobileOrTablet={isMobileOrTablet}
                                />
                            }
                            {isDrawing &&
                                <DrawingOverlay/>
                            }
                            {
                                !isMobileOrTablet &&
                                !slideHasFullScreenVideo &&
                                allowLocalControl &&
                                !parentPresenter &&
                                <PlayerControls
                                    {...controlProps}
                                    registerUserInteraction={this.handleUserInteractionWithControls}
                                    actionsMenuIsOpen={actionsMenuIsOpen}
                                    onToggleActionsMenu={this.handleToggleActionsMenu}
                                    visible={showControls}
                                    controlState={controlState}
                                    setControlState = {newState=>{
                                        this.setState({ controlState: newState });
                                    }}
                                    trackSlideNavigation={this.trackSlideNavigation}
                                    isDrawing = {isDrawing}
                                />
                            }
                            {
                                !parentPresenter &&
                                <PresentationActionsMenu
                                    {...controlProps}
                                    open={showContextMenu}
                                    onClose={() => {
                                        this.setState({ showContextMenu: false });
                                    }}
                                    anchorPosition={{
                                        left: contextMenuPositionX,
                                        top: contextMenuPositionY
                                    }}
                                />
                            }
                        </CanvasContainer>
                    </CanvasContainerWrapper>
                    {
                        isRemoteControlLeader &&
                        isMobileOrTablet &&
                        !isMobileLandscape &&
                        !parentPresenter &&
                        <MobilePlayerControls
                            slidesCount={presentation.getSips(this.skippedSlideIds).length}
                            advanceToSlide={this.rootAdvanceToSlide}
                            showThumbnailsPane={this.showThumbnailsPane}
                            currentSlideIndex={currentSlideIndex}
                            trackSlideNavigation={this.trackSlideNavigation}
                        />
                    }
                    {
                        isRemoteControlLeader &&
                        (
                            showNotes ||
                            isMobileOrTablet
                        ) &&
                        !isMobileLandscape &&
                        !parentPresenter &&
                        <SlideNotes isMobileOrTablet={isMobileOrTablet}
                            notes={sanitizedSlideNotes} />
                    }
                    {
                        isRemoteControlLeader &&
                        isMobileOrTablet &&
                        !isMobileLandscape &&
                        !parentPresenter &&
                        <RemoteControlBanner onClick={this.handleEndRemotePlay}>
                            End Remote Presentation
                        </RemoteControlBanner>
                    }
                </ContentContainer>
                {
                    showComments &&
                    !parentPresenter &&
                    <CommentsContainer className="CommentsContainer">
                        <CommentsPane
                            goToSlide={slideIndex => this.rootGoToSlide(slideIndex)}
                            close={() => this.toggleComments(false)}
                            // CommentsPane allows entering comments only when the transition is entered, but because we don't have access
                            // in the player to PresentationEditorController we need to pass it manually
                            transitionState={allowCommenting ? "entered" : ""}
                            currentSlide={this.getSlideAtIndex(currentSlideIndex)}
                        />
                        <CommentCloseButton
                            onClick={() => this.toggleComments(false)}
                        >close</CommentCloseButton>
                    </CommentsContainer>
                }
                {
                    showBlankScreen &&
                    !parentPresenter &&
                    <BlankScreen onClick={() => this.setState({ showBlankScreen: false })} />
                }
                {
                    !parentPresenter &&
                    <ThumbnailsPane
                        isOpen={isThumbnailsPaneOpen}
                        presentation={presentation}
                        slides={slides}
                        slidesMetadata={slidesMetadata}
                        currentSlideIndex={currentSlideIndex}
                        currentSlidePlaybackStageIndex={currentSlidePlaybackStageIndex}
                        goToSlide={this.rootGoToSlide}
                        onClose={this.hideThumbnailsPane}
                    />
                }
                {
                    showEndPage &&
                    <EndPage
                        showAuthor={showAuthor}
                        showTitle={showTitle}
                        presentationName={presentationName}
                        isLoggedIn={isLoggedIn}
                        creator={creator}
                        allowSocialSharing={allowSocialSharing}
                        onDownloadPdf={onDownloadPdf}
                        onCopyDeck={onCopyDeck}
                        allowCopyLink={allowCopyLink}
                        onEditPresentation={onEditPresentation}
                        onSwitchPresentation={onSwitchPresentation}
                        showBranding={showBranding}
                        showThumbnailsPane={this.showThumbnailsPane}
                        onReplayButtonClick={this.startPresenting}
                        onExit={onExit ? () => this.onExit(PlayerUnmountTrigger.END_PAGE) : false}
                        username={username}
                    />
                }
            </PlayerContainer>
        );
    }
}

export default PlayerView;
