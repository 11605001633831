import { LogGroup, LogLevel, LoggerTransportEntryData, LogEntryContext } from "js/core/logger/types";
import LoggerTransport from "js/core/logger/transports/LoggerTransport";
import LocationObserver, { ReadOnlyLocation } from "js/core/logger/LocationObserver";
import { MetricName, IMetricData } from "common/interfaces";

class Logger {
    private _transports: Array<LoggerTransport>
    private _group: LogGroup
    private _locationObserver?: LocationObserver;
    private _getLogEntryContext: () => LogEntryContext;

    constructor(transports: Array<LoggerTransport>, getLogEntryContext: () => LogEntryContext, group: LogGroup) {
        this._transports = transports;
        this._getLogEntryContext = getLogEntryContext;
        this._group = group;

        if (this._group === LogGroup.WINDOW) {
            window.addEventListener("error", this._onWindowError);
            window.addEventListener("unhandledrejection", this._onWindowUnhandledRejection);
            this._locationObserver = new LocationObserver(["pathname"], this._onPathnameChange);
            // Report initial state
            this._onPathnameChange(this._locationObserver.currentLocation);
        }
    }

    private _onWindowError = (err: ErrorEvent) => {
        // Get the error properties from the error event object
        const { message, filename, lineno, colno, error } = err;
        this.error(error, `window error${message ? `, message: ${message}` : ""}`, { filename, lineno, colno });
    }

    private _onWindowUnhandledRejection = (err: PromiseRejectionEvent) => {
        const message = err.reason?.message;
        this.error(err.reason, `unhandled promise rejection${message ? `, error message: ${message}` : ""}`);
    }

    private _onPathnameChange = (newLocation: ReadOnlyLocation) => {
        this.info(`navigated to "${newLocation.pathname}"`, { pathname: newLocation.pathname });
    }

    private _buildLogEntryDataFromArgs(args: any[]): LoggerTransportEntryData {
        if (args.length === 0) {
            return null;
        }

        if (args.length === 1 && args[0] && typeof args[0] === "object") {
            return args[0] as Object;
        }

        return args as any[];
    }

    public dispose() {
        this._transports.forEach(transport => transport.dispose());

        if (this._group === LogGroup.WINDOW) {
            window.removeEventListener("error", this._onWindowError);
            window.removeEventListener("unhandledrejection", this._onWindowUnhandledRejection);
            this._locationObserver.dispose();
        }
    }

    public info(message: string, ...args: any[]) {
        this._transports.forEach(transport => transport.log(this._getLogEntryContext(), this._group, LogLevel.INFO, message, this._buildLogEntryDataFromArgs(args)));
    }

    public warn(message: string, ...args: any[]) {
        this._transports.forEach(transport => transport.log(this._getLogEntryContext(), this._group, LogLevel.WARNING, message, this._buildLogEntryDataFromArgs(args)));
    }

    public error(error: Error, message: string, ...args: any[]) {
        this._transports.forEach(transport => transport.log(this._getLogEntryContext(), this._group, LogLevel.ERROR, message, this._buildLogEntryDataFromArgs(args), error));
    }

    public metric(metricName: MetricName, metricData: IMetricData) {
        this._transports.forEach(transport => transport.log(this._getLogEntryContext(), LogGroup.METRIC, LogLevel.INFO, metricName, { ...metricData, metric: metricName }));
    }
}

export default Logger;
