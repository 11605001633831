import { GlobalStateController } from "bai-react-global-state";
import _ from "lodash";
import React from "react";

import { workspaces as workspacesApi } from "apis/callables";
import {
    BEAUTIFUL_WORKSPACE_ID,
    TEAM_USER_ROLES as LEGACY_TEAM_USER_ROLES,
    PusherEventType
} from "common/constants";
import {
    IStorageModel,
    IUser,
    IWorkspace,
    IWorkspaceDocumentChangedEvent,
    IWorkspaceMetadata,
    IWorkspaceUser,
    LegacyWorkspaceGroupIds,
    WorkspaceUserRole,
    Optional,
    WorkspaceIntegrationType,
    IWorkspaceIntegrationConfig
} from "common/interfaces";
import type { User } from "firebase/auth";
import * as analytics from "js/analytics";
import PresentationLibraryController from "js/controllers/PresentationLibraryController";
import checkPastDue from "js/core/billing/checkPastDue";
import getLogger, { LogGroup } from "js/core/logger";
import pusher, { ExtendedChannel } from "js/core/services/pusher";
import { app } from "js/namespaces";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import MandatoryUpdateDialog from "js/react/components/Dialogs/MandatoryUpdateDialog";
import Spinner from "js/react/components/Spinner";

const logger = getLogger(LogGroup.WORKSPACES);

export interface WorkspaceControllerState {
    initialized: boolean;
    initializeError: Error | null;
    workspace: IWorkspace;
    users: IWorkspaceUser[];
    isPastDue: boolean;
}

const initialState: WorkspaceControllerState = {
    initialized: false,
    initializeError: null,
    workspace: null,
    users: [],
    isPastDue: false
};

class WorkspaceController extends GlobalStateController<WorkspaceControllerState> {
    private _asyncActionsPromiseChain: Promise<void> = Promise.resolve();
    private _pusherChannel: ExtendedChannel;
    private _pusherChannelUnbind: () => void;
    private _firebaseUser: User;
    private _userModel: IStorageModel<IUser>;

    constructor() {
        super(_.cloneDeep(initialState));
    }

    public get workspaceId() {
        return this._state.workspace?.id;
    }

    public get workspaceUser() {
        return this._state.users.find(user => user.uid === this._firebaseUser.uid);
    }

    public get role() {
        return this.workspaceUser?.role;
    }

    public get legacyRole() {
        return getUserLegacyRole(this.workspaceUser);
    }

    public get isLibrarian() {
        return this.workspaceUser?.groupIds.some(id => id === LegacyWorkspaceGroupIds.LIBRARIANS);
    }

    public get isPastDue() {
        return this._state.isPastDue;
    }

    private _initialize(workspaceId: string, firebaseUser: User, userModel: IStorageModel<IUser>) {
        return this._runSequentially(async () => {
            await this.reset(false);

            logger.info(`[WorkspaceController] initialize()`, { workspaceId });

            try {
                const [workspace, users, isPastDue] = await Promise.all([
                    workspacesApi.getWorkspace({ workspaceId }),
                    workspacesApi.getWorkspaceUsers({ workspaceId }),
                    checkPastDue(workspaceId)
                ]);

                analytics.trackWorkspaceChange(firebaseUser, workspaceId);
                analytics.trackState(getAnalyticsProps(workspace, users.find(user => user.uid === firebaseUser.uid)));

                try {
                    localStorage.setItem(BEAUTIFUL_WORKSPACE_ID, workspaceId);
                } catch (err) {
                    logger.error(err, "[WorkspaceController] initialize() failed to set workspaceId in localStorage");
                }

                this._pusherChannel = await pusher.subscribe(`private-legacy-workspace-${workspaceId === "personal" ? firebaseUser.uid : workspaceId}`);
                this._pusherChannelUnbind = this._pusherChannel.bindChunked(PusherEventType.DATA_RECORD_UPDATED, this._onPusherEvent);

                this._firebaseUser = firebaseUser;
                this._userModel = userModel;

                await this._updateState({
                    workspace,
                    initialized: true,
                    initializeError: null,
                    isPastDue: isPastDue === true,
                    users
                });

                this._postInitialize();
            } catch (err) {
                logger.error(err, "[WorkspaceController] initialize() failed to initialize");

                await this._updateState({
                    initialized: false,
                    initializeError: err
                });
            }
        });
    }

    private _postInitialize() {
        // Show dialog if the user's role has changed while offline
        // TODO: this should be handled via a notification
        if (this._userModel.has("workspaceRoleChange")) {
            const roleChangedForWorkspaceId = this._userModel.get("workspaceRoleChange");
            if (roleChangedForWorkspaceId === this.workspaceId) {
                this._onWorkspaceRoleChanged();
            }
        }
    }

    private _reset(runSequentially = true) {
        const reset = async () => {
            logger.info("[WorkspaceController] reset()");

            if (this._pusherChannel) {
                this._pusherChannelUnbind();
                if (!this._pusherChannel.isInUse) {
                    pusher.unsubscribe(this._pusherChannel.name);
                }
                this._pusherChannel = null;
                this._pusherChannelUnbind = null;
            }

            await this._updateState(_.cloneDeep(initialState));
        };

        if (runSequentially) {
            return this._runSequentially(reset);
        }

        return reset();
    }

    protected _stateDidUpdate(prevState: WorkspaceControllerState) {
        const { initialized, users, workspace } = this._state;

        if (!initialized) {
            return;
        }

        const handleUsersUpdate = () => {
            if (_.isEqual(prevState.users, users)) {
                return;
            }

            const uid = this._firebaseUser.uid;

            const currentUserBefore = prevState.users.find(user => user.uid === uid);
            const currentUserAfter = this.workspaceUser;

            if (!currentUserBefore || !currentUserAfter) {
                return;
            }

            const roleBefore = getUserLegacyRole(currentUserBefore);
            const roleAfter = this.legacyRole;
            if (roleBefore !== roleAfter) {
                this._onWorkspaceRoleChanged();
            }
        };

        const handleWorkspaceUpdate = () => {
            if (prevState.workspace?.name !== workspace.name) {
                // Force metadata reload to update the name
                this._userModel.loadWorkspacesMetadata()
                    .catch(err => {
                        logger.error(err, "[WorkspaceController] _stateDidUpdate() failed to reload user workspaces metadata");
                    });
            }
        };

        handleUsersUpdate();
        handleWorkspaceUpdate();
    }

    private _onPusherEvent = (documentChangeEvents: IWorkspaceDocumentChangedEvent[]) => {
        this._runSequentially(async () => {
            for (const event of documentChangeEvents) {
                if (event.documentType === "Workspace") {
                    const workspace = event.newDocument as IWorkspace;
                    if (workspace.modifiedAt > this._state.workspace.modifiedAt) {
                        this._updateState({ workspace });
                    }
                }

                if (event.documentType === "WorkspaceUser") {
                    const workspaceUser = event.newDocument as IWorkspaceUser;
                    const uid = event.documentId;

                    if (event.operationType === "create" || event.operationType === "update") {
                        const userBeforeUpdate = this._state.users.find(user => user.uid === uid);
                        if ((userBeforeUpdate?.modifiedAt ?? 0) < workspaceUser.modifiedAt) {
                            this._updateState(state => ({
                                ...state,
                                users: _.cloneDeep([...state.users.filter(user => user.uid !== uid), workspaceUser])
                            }));
                        }
                    } else if (event.operationType === "delete") {
                        const userBeforeDelete = this._state.users.find(user => user.uid === uid);
                        if ((userBeforeDelete?.modifiedAt ?? 0) < event.timestamp) {
                            this._updateState(state => ({
                                ...state,
                                users: _.cloneDeep(state.users.filter(user => user.uid !== uid))
                            }));
                        }
                    }
                }
            }
        });
    }

    private _runSequentially<T = void>(action: () => Promise<T>) {
        return new Promise<T>((resolve, reject) => {
            this._asyncActionsPromiseChain = this._asyncActionsPromiseChain
                .then(action)
                .then(resolve)
                .catch(reject);
        });
    }

    private async _onWorkspaceRoleChanged() {
        this._userModel.update({ workspaceRoleChange: null });
        await this._userModel.updatePromise.catch(err => {
            logger.error(err, "[WorkspaceController] _onWorkspaceRoleChanged() failed to clear workspaceRoleChange on user");
        });

        const role = this.legacyRole;
        const workspaceName = this._state.workspace.name;

        let message: string;
        switch (role) {
            case LEGACY_TEAM_USER_ROLES.OWNER:
                message = `Your role was changed to Owner in ${workspaceName}. Owners can manage members, Team Slides, and the Team Theme. Please refresh the browser to reflect the latest changes.`;
                break;
            case LEGACY_TEAM_USER_ROLES.LIBRARIAN:
                message = `Your role was changed to Librarian in ${workspaceName}. Librarians can manage Team Slides and the Team Theme. Please refresh the browser to reflect the latest changes.`;
                break;
            case LEGACY_TEAM_USER_ROLES.MEMBER_LICENSED:
                message = `Your role was changed to Pro Member in ${workspaceName}. Pro Members have access to Beautiful.ai Pro features. Please refresh the browser to reflect the latest changes.`;
                break;
            case LEGACY_TEAM_USER_ROLES.MEMBER:
                message = `Your role was changed to Free Team Member in ${workspaceName}. Free Team Members are limited to basic features. Please refresh the browser to reflect the latest changes.`;
                break;
        }

        ShowDialog(MandatoryUpdateDialog, { message });
    }

    public initialize(workspaceId: string, firebaseUser: User, userModel: IStorageModel<IUser>) {
        return this._initialize(workspaceId, firebaseUser, userModel);
    }

    public reset(runSequentially = true) {
        return this._reset(runSequentially);
    }

    public withInitializedState<T extends React.ComponentType<any>>(Component: T, PreloadComponent: React.ComponentType<any> = Spinner) {
        function Wrapper(props: React.ComponentProps<T>) {
            const { initialized } = props;

            if (!initialized) {
                return <PreloadComponent />;
            }

            return <Component {...props} />;
        }

        return this.withState(Wrapper);
    }

    public async forceRefreshWorkspace() {
        return this._runSequentially(async () => {
            const workspace = await workspacesApi.getWorkspace({ workspaceId: this.workspaceId });
            await this._updateState({ workspace });
        });
    }

    public async updateWorkspace({ name, sso }: { name?: string, sso?: Optional<IWorkspace["sso"], keyof IWorkspace["sso"]> }) {
        return this._runSequentially(async () => {
            const workspace = await workspacesApi.updateWorkspace({ workspaceId: this.workspaceId, update: { name, sso } });
            await this._updateState({ workspace });
        });
    }

    public async verifyDomain() {
        return this._runSequentially(async () => {
            const workspace = await workspacesApi.verifyWorkspaceDomain({ workspaceId: this.workspaceId });
            await this._updateState({ workspace });
        });
    }

    public async parseSamlMetadata({ metadataUrl }: { metadataUrl: string }) {
        return this._runSequentially(async () => {
            const workspace = await workspacesApi.parseSamlMetadata({ workspaceId: this.workspaceId, metadataUrl });
            await this._updateState({ workspace });
        });
    }

    public async disconnectWorkspaceIntegration({ integrationType }: { integrationType: WorkspaceIntegrationType }) {
        return this._runSequentially(async () => {
            const workspace = await workspacesApi.disconnectWorkspaceIntegration({ workspaceId: this.workspaceId, integrationType });
            await this._updateState({ workspace });
        });
    }

    public async connectWorkspaceIntegration({ integrationType, config }: { integrationType: WorkspaceIntegrationType, config: IWorkspaceIntegrationConfig }) {
        return this._runSequentially(async () => {
            const workspace = await workspacesApi.connectWorkspaceIntegration({ workspaceId: this.workspaceId, integrationType, config });
            await this._updateState({ workspace });
        });
    }
}

export async function getAnalyticsPropsForWorkspace(workspaceId: string, uid: string) {
    const [workspace, users] = await Promise.all([
        workspacesApi.getWorkspace({ workspaceId }),
        workspacesApi.getWorkspaceUsers({ workspaceId, uids: [uid] })
    ]);

    return getAnalyticsProps(workspace, users[0]);
}

export function getUserLegacyRole(user: IWorkspaceUser) {
    if (user.role === WorkspaceUserRole.OWNER) {
        return LEGACY_TEAM_USER_ROLES.OWNER;
    } else if (user.role === WorkspaceUserRole.GUEST) {
        return LEGACY_TEAM_USER_ROLES.MEMBER;
    } else {
        if (user.groupIds.some(id => id === LegacyWorkspaceGroupIds.LIBRARIANS)) {
            return LEGACY_TEAM_USER_ROLES.LIBRARIAN;
        }

        return LEGACY_TEAM_USER_ROLES.MEMBER_LICENSED;
    }
}

export function getAnalyticsProps(workspace: IWorkspace, user: IWorkspaceUser) {
    const workspaceId = workspace.id;

    let legacyRole = "none";
    if (workspaceId !== "personal") {
        legacyRole = getUserLegacyRole(user);
    }

    return {
        "workspace_id": workspaceId,
        "workspace_type": workspaceId === "personal" ? "personal" : "organization",
        "organization_name": workspace.name,
        "current_plan": app.user?.getAnalyticsPlan(workspaceId),
        // Cheap, but inaccurate as PresentationLibraryController may be not initialized in which case
        // it will return zero
        "current_slide_count": PresentationLibraryController.getSlideCount(workspaceId),
        "role": legacyRole
    };
}

export function getInitialWorkspaceId(workspacesMetadata: IWorkspaceMetadata[]) {
    const requestedWorkspaceId = localStorage.getItem(BEAUTIFUL_WORKSPACE_ID);
    if (requestedWorkspaceId && workspacesMetadata.some(workspace => workspace.id === requestedWorkspaceId)) {
        // Show requested workspace
        return requestedWorkspaceId;
    }

    if (workspacesMetadata.length > 1) {
        // If in multiple teams, then show the first team
        return workspacesMetadata.find(workspace => workspace.id !== "personal").id;
    }

    // Otherwise, show personal
    return "personal";
}

const workspaceController = new WorkspaceController();

// for lazy import and debug
app.workspaceController = workspaceController;

export default workspaceController;
