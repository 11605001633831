import styled from "styled-components";
import { themeColors } from "js/react/sharedStyles";
import React, { useRef } from "react";
import { FlexSpacer, Gap, Gap10, Gap5 } from "js/react/components/Gap";
import { ToolTipBadge } from "./ToolTip";
import { FlexBox } from "../react/components/LayoutGrid";
import { Icon } from "@material-ui/core";
import { uuid } from "../core/utilities/utilities";

// Note this needs to be a label, not a div, so that the for attribute works
const WithLabelContainer = styled.label`
    position: relative;
    display: flex;
    align-items: flex-start;
    font-size: 11px;
    flex-shrink: 0;

    .label-container {
        height: 12px;
        flex-shrink: inherit;
        label {
            color: #555;
            font-weight: 600;
            text-transform: uppercase;
            flex-shrink: 0;
            position: relative;
        }

        & > .MuiIcon-root {
            color: #555;
            margin-right: 7px;
        }
    }
`;

const ToolTipBadgeContainer = styled.div`
    position: absolute;
    right: -20px;
    top: -2px;
`;

export const WithLabel = React.forwardRef(function WithLabel(props, ref) {
    const {
        label,
        labelColor,
        icon,
        labelWidth,
        style: overrideStyle,
        onClick,
        onDark,
        selected,
        large,
        top,
        bottom,
        above,
        below,
        left,
        center,
        right,
        small,
        gap,
        fillWidth = false,
        tight = false,
        noWrap,
        toolTip,
        width,
        bold
    } = props;

    const forIdRef = useRef(uuid());

    const style = {
        gap
    };

    const labelStyle = {};

    let className = `${props.className ?? ""} withlabel`;

    if (above || below || top || bottom) {
        style.flexDirection = (above || top) ? "column" : "column-reverse";
        if (left) {
            style.alignItems = "flex-start";
            className += " label-left";
        }
        if (center) {
            style.alignItems = "center";
            labelStyle.textAlign = "center";
            className += " label-center";
        }
        if (below || bottom) {
            // style.gap = 5;
            style.gap = 2;
            className += " label-below";
        } else {
            style.gap = 8;
            className += " label-above";
        }
    } else {
        style.flexDirection = "row";
        style.justifyContent = "flex-start";
        style.alignItems = "center";
        style.gap = 10;
        if (right) {
            style.flexDirection = "row-reverse";
            style.justifyContent = "flex-end";
            className += " label-right";
        } else {
            style.flexDirection = "row";
            className += " label-left";
        }
    }

    labelStyle.display = "flex";
    labelStyle.alignItems = "center";
    labelStyle.fontSize = 11;
    if (labelColor) {
        labelStyle.color = labelColor;
    }

    if (small) {
        labelStyle.fontSize = 9;
    }
    if (large) {
        labelStyle.fontSize = 16;
        labelStyle.fontWeight = 600;
        labelStyle.textTransform = "none";
    }
    if (bold) {
        labelStyle.fontSize = 12;
        labelStyle.color = "#111";
        labelStyle.fontWeight = 600;
    }

    if (gap !== undefined) {
        style.gap = gap;
    }

    if (selected) {
        labelStyle.color = themeColors.ui_blue;
    }

    if (noWrap) {
        labelStyle.whiteSpace = "nowrap";
    }

    if (fillWidth) {
        style.width = "100%";
        labelStyle.width = "100%";
    }

    if (labelWidth) {
        labelStyle.width = labelWidth;
    }

    if (width) {
        style.width = width;
    }

    if (onDark) {
        labelStyle.color = "#ccc";
    }

    if (overrideStyle) {
        Object.assign(style, overrideStyle);
    }

    // When WithLabel has a single child, and that child is a ToggleSwitch, we want to generate a unique id for the label to point to the toggle switch using the for attribute
    const forId = forIdRef.current;
    const children = React.Children.toArray(props.children);
    if (children.length === 1) {
        if (children[0].type.name === "ToggleSwitch") {
            children[0] = React.cloneElement(children[0], { id: forId });
        }
    }

    return (
        <WithLabelContainer style={style} onClick={onClick} htmlFor={forId} className={className} ref={ref}>
            {label && (
                <FlexBox className="label-container">
                    {icon && typeof (icon) == "string" && <Icon>{icon}</Icon>}
                    {icon && typeof (icon) == "object" && icon}
                    {icon && <Gap10 />}
                    <label style={labelStyle} htmlFor={forId}>{label} {toolTip && <ToolTipBadgeContainer className="tooltip-badge-container"><ToolTipBadge>{toolTip}</ToolTipBadge></ToolTipBadgeContainer>}</label>
                    {toolTip && <Gap size={40} />}
                </FlexBox>
            )}
            {!tight && !fillWidth && <FlexSpacer />}
            {children}
        </WithLabelContainer>
    );
});
